import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmailParamsFormComponent } from '../email-params-form/email-params-form.component';

@Component({
  selector: 'app-email-cancel-order-params-form',
  templateUrl: './email-cancel-order-params-form.component.html',
  styleUrls: ['./email-cancel-order-params-form.component.css'],
})
export class EmailCancelOrderParamsFormComponent extends EmailParamsFormComponent {
  @Input() cancellationRouteActive = null;
  @Output() newCancellationRouteStatus = new EventEmitter<boolean>();

  setCancellationRouteStatus(value: boolean) {
    this.cancellationRouteActive = !value;
    this.newCancellationRouteStatus.emit(this.cancellationRouteActive);
  }
}
