import { JsonObject, JsonProperty } from 'json2typescript';
import { isNil, isNilty } from '@core/utils.service';
import { BaseObject } from './base-object-model';
import { DateConverter, fromDateToFiltersString } from './date-converter';
import { MatChipsData } from './mat-chips-data-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { Status } from './status-mode';

@JsonObject('Shipment')
export class Shipment extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('createdAt', DateConverter, false) createdAt: string = undefined;
  @JsonProperty('shipmentId', String, false) shipmentId: string = undefined;
  @JsonProperty('carrier', String, false) carrier: string = undefined;
  @JsonProperty('status', Status, false) status: Status = undefined;
  @JsonProperty('packageCount', Number, false) packageCount: number = undefined;
  @JsonProperty('trackingCode', String, false) trackingCode: string = undefined;
  @JsonProperty('labelUrl', String, false) labelUrl: string = undefined;
  @JsonProperty('client', String, false) client: string = undefined;
}

@JsonObject('ShipmentFilters')
export class ShipmentFilters extends PaginationAndSorting {
  @JsonProperty('shipmentId', String, true) shipmentId: string = undefined;
  @JsonProperty('createdAt', DateConverter, true) createdAt: Date = undefined;
  @JsonProperty('client', String, true) client: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('carrier', String, true) carrier: string = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('hasLabel', Boolean, true) hasLabel: boolean = undefined;
}

export const shipmentFiltersMap = (
  shipmentId: string,
  createdAt: Date,
  client: string,
  status: Status,
  carrier: string,
  trackingNumber: string,
  hasLabel: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(shipmentId)) {
    map.push(new MatChipsData('Shipment ID', shipmentId));
  }
  if (!isNil(createdAt)) {
    map.push(new MatChipsData('Created At', fromDateToFiltersString(createdAt)));
  }
  if (!isNil(client)) {
    map.push(new MatChipsData('Client', client));
  }
  if (!isNil(status)) {
    map.push(new MatChipsData('Status', status.description));
  }
  if (!isNilty(carrier)) {
    map.push(new MatChipsData('Carrier', carrier));
  }
  if (!isNilty(trackingNumber)) {
    map.push(new MatChipsData('Tracking Number', trackingNumber));
  }
  if (!isNilty(hasLabel)) {
    map.push(new MatChipsData('Has Label', hasLabel));
  }

  return map;
};
