import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';
import { isNilty } from '@core/utils.service';
import { MatChipsData } from '@models/mat-chips-data-model';
import { Owner } from '@models/owner-model';
import { SpoFilters, spoFiltersMap } from '@models/spo-model';
import { Supplier } from '@models/supplier-model';
import { CommonsService } from '@shared/commons.service';
import { Subject } from 'rxjs';
import { SposService } from '../spos.service';

@Component({
  selector: 'app-spos-list-filters',
  templateUrl: './spos-list-filters.component.html',
  styleUrls: ['./spos-list-filters.component.css'],
})
export class SposListFiltersComponent implements OnInit {
  @Input() spoFilters = true;
  @Input() spoItemFilters = true;
  @Input() filters = new SpoFilters();
  @Output() onFilter = new Subject<SpoFilters>();

  filterForm: FormGroup;
  public openFilter = false;
  filtersMap: MatChipsData[] = [];
  suppliers: Supplier[] = [];
  clients: string[] = [];
  owners: Owner[] = [];
  spoStatuses: string[] = [];

  constructor(private suppliersService: SuppliersService, private sposService: SposService, private commonsService: CommonsService) {}

  ngOnInit() {
    this.filterForm = new FormGroup({
      orderId: new FormControl(),
      owner: new FormControl(),
      orderDateFrom: new FormControl(),
      orderDateTo: new FormControl(),
      client: new FormControl(),
      supplier: new FormControl(),
      earliestDeliveryDate: new FormControl(),
      latestDeliveryDate: new FormControl(),
      status: new FormControl(),
      ean: new FormControl(),
      altEan: new FormControl(),
      hasOrderedQuantity: new FormControl(),
      missingConfirmed: new FormControl(),
      clientApplicationId: new FormControl(),
    });

    if (!isNilty(this.filters)) {
      this.filterForm.patchValue(this.filters);
      this.updateFiltersMap();
    }

    this.suppliersService.getSuppliers().subscribe((resp) => {
      this.suppliers = resp;
      this.setSupplier();
    });

    this.commonsService.getClients().subscribe((resp) => {
      this.clients = resp;
      this.setClient();
    });

    this.commonsService.getSpoStatuses().subscribe((resp) => {
      this.spoStatuses = resp;
      this.setStatus();
    });

    this.commonsService.getOwners().subscribe((resp) => {
      this.owners = resp;
      this.setOwners();
    });
  }

  setSupplier() {
    if (this.filters !== null && this.filters.supplier !== null) {
      this.filterForm.controls['supplier'].patchValue(this.suppliers.find((it) => it.id === this.filters.supplier));
      this.updateFiltersMap();
    }
  }

  setClient() {
    if (this.filters !== null && this.filters.client !== null) {
      this.filterForm.controls['client'].patchValue(this.clients.find((it) => it === this.filters.client));
      this.updateFiltersMap();
    }
  }

  setStatus() {
    if (this.filters !== null && this.filters.status !== null) {
      this.filterForm.controls['status'].patchValue(this.spoStatuses.find((it) => it === this.filters.status));
      this.updateFiltersMap();
    }
  }

  setOwners() {
    if (this.filters !== null && this.filters.owner !== null) {
      this.filterForm.controls['owner'].patchValue(this.owners.find((it) => it.id === this.filters.owner));
      this.updateFiltersMap();
    }
  }

  onSubmitFilter() {
    this.updateFiltersMap();
    this.onFilter.next(this.setSpoFilter());
  }

  resetFilters() {
    this.filterForm.reset();
    this.updateFiltersMap();
    this.onFilter.next(this.setSpoFilter());
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  setSpoFilter(): SpoFilters {
    const filters = new SpoFilters();
    filters.orderId = this.filterForm.value.orderId;
    filters.owner = isNilty(this.filterForm.value.owner) ? null : this.filterForm.value.owner;
    filters.orderDateFrom = this.filterForm.value.orderDateFrom || null;
    filters.orderDateTo = this.filterForm.value.orderDateTo || null;
    filters.client = this.filterForm.value.client;
    filters.supplier = isNilty(this.filterForm.value.supplier) ? null : this.filterForm.value.supplier;
    filters.earliestDeliveryDate = this.filterForm.value.earliestDeliveryDate;
    filters.latestDeliveryDate = this.filterForm.value.latestDeliveryDate;
    filters.status = this.filterForm.value.status;
    filters.ean = this.filterForm.value.ean;
    filters.altEan = this.filterForm.value.altEan;
    filters.hasOrderedQuantity = this.filterForm.value.hasOrderedQuantity;
    filters.missingConfirmed = this.filterForm.value.missingConfirmed;
    filters.clientApplicationId = this.filterForm.value.clientApplicationId;
    return filters;
  }

  updateFiltersMap() {
    this.filtersMap = spoFiltersMap(
      this.filterForm.value.orderId,
      this.filterForm.value.owner,
      this.filterForm.value.orderDate?.begin || null,
      this.filterForm.value.orderDate?.end || null,
      this.filterForm.value.client,
      this.filterForm.value.supplier,
      this.filterForm.value.earliestDeliveryDate,
      this.filterForm.value.latestDeliveryDate,
      this.filterForm.value.status,
      this.filterForm.value.ean,
      this.filterForm.value.altEan,
      this.filterForm.value.hasOrderedQuantity,
      this.filterForm.value.missingConfirmed,
      this.filterForm.value.clientApplicationId
    );
  }
}
