import { Component, Input, OnInit } from '@angular/core';
import { Field } from '@models/supplier-configuration/field-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-receiver-positions-edit',
  templateUrl: './receiver-positions-edit.component.html',
  styleUrls: ['./receiver-positions-edit.component.css'],
})
export class ReceiverPositionsEditComponent implements OnInit {
  @Input() name: string;

  map: any;

  existingKeys: string[];
  allowedFields: Field[];
  availableKeys: Field[];

  newKey: Field;
  newValue: number = null;

  @Input() set _allowedFields(af: Field[]) {
    this.allowedFields = af;
    if (!isNilty(this.existingKeys)) {
      this.setAvailableKeys();
    }
  }

  @Input() set _map(m: any) {
    this.map = m;
    this.existingKeys = Object.keys(this.map);
    if (!isNilty(this.allowedFields)) {
      this.setAvailableKeys();
    }
  }

  ngOnInit(): void {
    this.existingKeys = Object.keys(this.map);
    this.setAvailableKeys();
  }

  delete(key: string) {
    this.existingKeys.splice(
      this.existingKeys.findIndex((it) => it === key),
      1
    );
    delete this.map[key];
    this.setAvailableKeys();
  }

  add() {
    this.map[this.newKey.code] = this.newValue;
    this.existingKeys.push(this.newKey.code);
    this.newKey = undefined;
    this.newValue = undefined;
    this.setAvailableKeys();
  }

  edit(key: string) {
    this.existingKeys.splice(
      this.existingKeys.findIndex((it) => it === key),
      1
    );
    this.newKey = this.allowedFields.find((it) => it.code === key);
    this.newValue = this.map[key];
    this.setAvailableKeys();
  }

  setAvailableKeys() {
    this.availableKeys = this.allowedFields.filter((it) => this.existingKeys.findIndex((k) => k === it.code) === -1);
  }
}
