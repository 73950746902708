import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { isNilty } from '@core/utils.service';
import { StockBalanceFilters, StockBalanceResponse } from '@models/stock-balance-model';
import { StockMovementFilters, StockMovementResponse } from '@models/stock-movement-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductAging, ProductAgingFilters } from '@models/product-aging-model';

@Injectable()
export class StockBalanceService {
  stockBalanceFilters: StockBalanceFilters;
  stockMovementFilters: StockMovementFilters;
  productAgingFilters: ProductAgingFilters;

  stockBalanceResultsNumber = new Subject<number>();
  stockMovementResultsNumber = new Subject<number>();
  productAgingResultNumber = new Subject<number>();

  constructor(
    private converter: ConverterService,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar
  ) {}

  refreshByFilters(): Observable<void> {
    const body = this.converter.fromObjtoJSON(this.stockBalanceFilters);
    const path = this.environmentService.getRestEndpoint('stockBalance') + '/refresh-by-filters';
    return this.http.post(path, body).pipe(
      map(() => {
        console.log('Refreshed');
      })
    );
  }

  findFilteredStockBalances(): Observable<StockBalanceResponse> {
    const body = this.converter.fromObjtoJSON(this.stockBalanceFilters);
    return this.http.post(this.environmentService.getRestEndpoint('stockBalance'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.stockBalanceResultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: StockBalanceResponse) => {
        if (isNilty(resp)) {
          return [];
        } else {
          return this.converter.fromJSONtoObj(resp, StockBalanceResponse);
        }
      })
    );
  }

  findFilteredStockMovement(): Observable<StockMovementResponse> {
    const body = this.converter.fromObjtoJSON(this.stockMovementFilters);
    return this.http.post(this.environmentService.getRestEndpoint('stockMovement'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.stockMovementResultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: StockMovementResponse) => {
        if (isNilty(resp)) {
          return [];
        } else {
          return this.converter.fromJSONtoObj(resp, StockMovementResponse);
        }
      })
    );
  }

  exportStockMovement() {
    const body = this.converter.fromObjtoJSON(this.stockMovementFilters);
    const path = this.environmentService.getRestEndpoint('exportStockMovement');

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map(() => {
        this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  findFilteredProductAging(filters: ProductAgingFilters): Observable<ProductAging[]> {
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(this.environmentService.getRestEndpoint('productAging'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.productAgingResultNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: ProductAging[]) => {
        if (isNilty(resp)) {
          return [];
        } else {
          return resp.map((it) => this.converter.fromJSONtoObj(it, ProductAging));
        }
      })
    );
  }

  exportProductAging() {
    const body = this.converter.fromObjtoJSON(this.productAgingFilters);
    const path = this.environmentService.getRestEndpoint('exportProductAging');

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map(() => {
        this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }
}
