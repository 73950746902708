import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-arianna-params-form',
  templateUrl: './arianna-params-form.component.html',
  styleUrls: ['./arianna-params-form.component.css'],
})
export class AriannaParamsFormComponent implements OnInit {
  @Input() params: any;

  @Output() isValidRoute = new EventEmitter<boolean>();

  parametersList = [{ key: 'recipientEanLocationCode', label: 'Recipient EAN Location Code', required: true }];

  ngOnInit() {
    this.isValidRoute.next(true);
  }

  isValidForm() {
    for (const p of this.parametersList.filter((it) => it.required)) {
      if (isNilty(this.params[p.key])) {
        return this.isValidRoute.next(false);
      }
    }
    this.isValidRoute.next(true);
  }
}
