<h4 class="mat-dialog-title" style="min-width: 350px">{{ data.title }}</h4>

<div class="px-5 pt-2 pb-4">
  <div class="text-center">
    <mat-form-field>
      <input matInput required [(ngModel)]="data.object.code" placeholder="Code" />
    </mat-form-field>
  </div>

  <div class="text-center">
    <mat-form-field>
      <input matInput required [(ngModel)]="data.object.name" placeholder="Name" />
    </mat-form-field>
  </div>

  <div class="text-center">
    <mat-form-field>
      <input matInput [(ngModel)]="data.object.accountNumber" placeholder="AccountNumber" />
    </mat-form-field>
  </div>

  <div class="text-center pt-4">
    <button
      class="btn-product"
      [disabled]="(data.object.code | isNullOrUndefinedOrEmptyString) || (data.object.name | isNullOrUndefinedOrEmptyString)"
      (click)="save()"
    >
      Save
    </button>
    <button class="btn-product" [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
  </div>
</div>
