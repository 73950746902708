<div class="main-content padding-bottom-20">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <legend>Carrier Configuration</legend>
      </div>
    </div>
    <div class="row row-cols-md-3">
      <mat-form-field class="col" *ngIf="currentConfiguration">
        <mat-select
          [(ngModel)]="currentConfiguration.carrier"
          placeholder="Carrier"
          required
          (selectionChange)="onCarrierSelected($event.value)"
        >
          <mat-option *ngFor="let c of carriers" [value]="c">{{ c.carrierName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <mat-select [(ngModel)]="currentConfiguration.contract" placeholder="Contract" required="true" [disabled]="!contracts">
          <mat-option *ngFor="let c of contracts" [value]="c">{{ c | replaceUnderscoreWithSpace }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input
          matInput
          [(ngModel)]="currentConfiguration.destinationCountryCode"
          placeholder="Destination Country Code (ISO2)"
          [maxLength]="2"
          required
        />
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input
          matInput
          [(ngModel)]="currentConfiguration.originCountryCode"
          placeholder="Origin Country Code (ISO2)"
          [maxLength]="2"
          required
        />
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input matInput [(ngModel)]="currentConfiguration.destinationZipCodePrefix" placeholder="Destination Zipcode Prefix" />
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input matInput *ngIf="currentConfiguration" [(ngModel)]="currentConfiguration.storeCode" placeholder="Store Code" />
      </mat-form-field>

      <app-brandon-autocomplete
        *ngIf="currentConfiguration"
        cssClass="col"
        label="Supplier"
        [allData]="suppliers"
        [(value)]="currentConfiguration.supplierCode"
        filterKey="description"
        displayKey="description"
        valueKey="code"
      ></app-brandon-autocomplete>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input matInput [(ngModel)]="currentConfiguration.storeGroupCode" placeholder="Store Group Code" />
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input matInput [(ngModel)]="currentConfiguration.weightKgFrom" placeholder="Weight From" type="number" />
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <input matInput [(ngModel)]="currentConfiguration.weightKgTo" placeholder="Weight To" type="number" />
      </mat-form-field>

      <mat-form-field class="col" *ngIf="currentConfiguration">
        <mat-select [(ngModel)]="currentConfiguration.orderType" placeholder="Order Type">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let ot of orderTypes" [value]="ot">{{ ot }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <hr />
    <div class="row">
      <div class="col-md-12 text-center">
        <button
          class="btn-custom"
          disa
          (click)="save()"
          [disabled]="
            !currentConfiguration?.carrier ||
            (contracts ? !currentConfiguration?.contract : '') ||
            !currentConfiguration?.destinationCountryCode ||
            !currentConfiguration?.originCountryCode
          "
        >
          Save
        </button>

        <button class="btn-custom" *ngIf="editMode" (click)="getConfiguration()">Revert</button>

        <button class="btn-custom" (click)="goBack()">Back</button>
      </div>
    </div>
  </div>
</div>
