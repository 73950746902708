import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { isNilty } from '@core/utils.service';
import { MessageConfiguration } from '@models/supplier-configuration/message-configuration-model';
import { MessageType } from '@models/supplier-configuration/message-types-model';
import { Param, TypeParam } from '@models/supplier-configuration/params-model';
import { SuppliersConfigurationsService } from '../../../suppliers-configurations.service';

@Component({
  selector: 'app-message-types-form',
  templateUrl: './message-types-form.component.html',
  styleUrls: ['./message-types-form.component.css'],
})
export class MessageTypesFormComponent implements OnInit, OnChanges {
  @Input() isReceiver: boolean;
  @Input() messagesConfiguration: MessageConfiguration[];
  @Input() messageTypes: MessageType[];
  @Input() routeTypeParams: TypeParam[];
  @Input() routeType: string;

  params: Param[];
  title: string;

  constructor(private suppliersConfigurationsService: SuppliersConfigurationsService) {}

  ngOnInit() {
    this.title = this.isReceiver ? 'Receiver' : 'Sender';
    this.messageTypes.forEach((type) => {
      this.findConfiguration(type);
    });

    this.setParams();
  }

  ngOnChanges() {
    this.setParams();
  }

  setParams() {
    const routeParams = this.routeTypeParams.find((it) => it['type'] === this.routeType);
    if (!isNilty(routeParams)) {
      this.params = routeParams['params'];
    } else {
      this.params = [];
    }
  }

  findConfiguration(type: MessageType) {
    const c = this.messagesConfiguration.find((it) => it.type === type.typeCode);
    type.configured = !isNilty(c);
    type.configuration = c;
    this.suppliersConfigurationsService.getFields(type.typeCode).subscribe((fields) => {
      type.fields = fields;
    });
    this.setFileTypeParams(type, false);
  }

  configurationChanged(event: MatCheckboxChange, type: MessageType) {
    if (event.checked) {
      this.messagesConfiguration.push(new MessageConfiguration(type.typeCode));
      this.findConfiguration(type);
    } else {
      const index = this.messagesConfiguration.findIndex((it) => it.type === type.typeCode);
      this.messagesConfiguration.splice(index, 1);
    }
  }

  setFileTypeParams(messageType: MessageType, resetParams: boolean = true) {
    if (messageType.configuration?.fileType) {
      messageType.configuration.params = messageType.fileTypes.find((it) => it.type === messageType.configuration.fileType)?.params ?? [];
      if (resetParams) {
        messageType.configuration.fileParams = {};
        messageType.configuration.params.forEach((param) => (messageType.configuration.fileParams[param.key] = param.default));
        this.params.forEach((param) => (messageType.configuration.fileParams[param.key] = param.default));
      }
    }
  }
}
