<div class="container-fluid">
  <div class="row my-3 align-items-center">
    <div class="col-md-10">
      <h2 class="fst-italic text-secondary">Invoice Number: {{ purchaseInvoiceNumber }}</h2>
    </div>

    <div class="col-md-2 text-end">
      <span class="padding-left-5">
        <button class="btn-custom" (click)="goBack()"><i class="fa fa-chevron-left" aria-hidden="true"></i>Back</button>
      </span>
    </div>
  </div>

  <div class="row mx-0">
    <div class="bluebox">
      <p><b>Invoice Date: </b> {{ pi?.purchaseInvoiceDate }}</p>
      <p><b>Supplier Name: </b> {{ pi?.supplier.name }}</p>
      <p><b>Supplier Code: </b> {{ pi?.supplier.code }}</p>
    </div>

    <div class="bluebox">
      <p><b>Currency: </b> {{ pi?.currency }}</p>
      <p class="quantity-value-row"><b>Total Price: </b> {{ pi?.totalPrice }}</p>
      <p class="quantity-value-row"><b>Supplier Vat Number: </b> {{ pi?.supplierGln }}</p>
    </div>
  </div>
</div>

<div class="container-fluid padding-top-15">
  <div class="row">
    <div class="col-md-12">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="orderNumber">Order Number</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.orderNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lineItemReferenceNumber">
        <th mat-header-cell *matHeaderCellDef>Item Reference Number</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.lineItemReferenceNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ean">
        <th mat-header-cell *matHeaderCellDef>Ean</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.ean }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bookTitle">
        <th mat-header-cell *matHeaderCellDef>Book Title</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.bookTitle }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.quantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="price">Price</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.price }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totalPrice">Total Price</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.totalPrice }}
        </td>
      </ng-container>

      <ng-container matColumnDef="taxRate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="taxRate">Tax Rate</th>
        <td mat-cell *matCellDef="let piItem">
          {{ piItem.taxRate }}
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let op; columns: displayedColumns" style="cursor: pointer"></mat-row>
    </table>
  </div>
</div>
