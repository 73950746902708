import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { StockBalance, StockBalanceFilters, StockBalanceHeader, StockBalanceResponse } from '@models/stock-balance-model';
import { StockMovementFilters } from '@models/stock-movement-model';
import { Observable } from 'rxjs';
import { StockBalanceService } from './stock-balance.service';

@Component({
  selector: 'app-stock-balance',
  templateUrl: './stock-balance.component.html',
  styleUrls: ['./stock-balance.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StockBalanceComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  header: StockBalanceHeader;
  dataSource: MatTableDataSource<StockBalance>;
  displayedColumns = [
    'product',
    'logisticPartner',
    'lpAvailableQuantity',
    'lpPhysicalQuantity',
    'brandonAvailableQuantity',
    'brandonPhysicalQuantity',
    'pendingQuantity',
    'reservedQuantity',
    'items',
    'updatedAt',
    'expand',
  ];

  itemsDisplayedColumns = ['stockLocationCode', 'availableQuantity', 'physicalQuantity', 'supplierCode', 'purchasePrice'];
  resultsNumber: Observable<number>;

  constructor(private stockBalanceService: StockBalanceService, private router: Router, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.resultsNumber = this.stockBalanceService.stockBalanceResultsNumber;
    if (isNilty(this.stockBalanceService.stockBalanceFilters)) {
      this.stockBalanceService.stockBalanceFilters = new StockBalanceFilters();
    }
    this.dataSource = new MatTableDataSource<StockBalance>([]);
    this.loadStockBalances();
    this.stockBalanceService.stockMovementFilters = new StockMovementFilters();
  }

  loadPage() {
    this.stockBalanceService.stockBalanceFilters.pageSize = this.paginator.pageSize;
    this.stockBalanceService.stockBalanceFilters.pageIndex = this.paginator.pageIndex;
    this.loadStockBalances();
  }

  onSort() {
    this.stockBalanceService.stockBalanceFilters.sortBy = this.sort.active;
    this.stockBalanceService.stockBalanceFilters.sortDirection = this.sort.direction;
    this.loadStockBalances();
  }

  applyFilters() {
    this.loadStockBalances();
  }

  refreshByFilters() {
    this.stockBalanceService.refreshByFilters().subscribe(() => {
      this.snackBar.open('The selected items are going to be refreshed.', 'CLOSE')._dismissAfter(3000);
    });
  }

  openStockMovement() {
    this.router.navigate(['stock-movement', { hasFilters: true }]);
  }

  openStockMovementFiltered(sku: string) {
    this.stockBalanceService.stockMovementFilters.sku = sku;
    this.openStockMovement();
  }

  private loadStockBalances() {
    this.stockBalanceService.findFilteredStockBalances().subscribe((resp: StockBalanceResponse) => {
      this.header = resp.header;
      this.dataSource.data = resp.stockBalance;
    });
  }
}
