import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailTemplateWithSample } from '@models/supplier-configuration/email-templates-model';

@Component({
  selector: 'app-pending-tooltip-component',
  template: `
    <h4 class="mat-dialog-title">{{ data.templateName }} email template</h4>

    <div class="container px-5 py-4">
      <div class="row padding-bottom-20">
        <div class="col"><b>Subject:</b> {{ data.sampleSubject }}</div>
      </div>
      <div class="row padding-bottom-10">
        <div class="col">
          <b>Body:</b>
        </div>
      </div>
      <div class="row">
        <div class="col" [innerHTML]="data.sampleBody"></div>
      </div>
    </div>
  `,
  styles: [
    `
      b {
        color: #355d90;
      }
    `,
  ],
})
export class EmailTemplateModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: EmailTemplateWithSample) {}
}
