import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarriersConfigurationsEditComponent } from './carriers-configurations/carriers-configurations-edit/carriers-configurations-edit.component';
import { CarriersConfigurationsListComponent } from './carriers-configurations/carriers-configurations-list/carriers-configurations-list.component';
import { CarriersConfigurationsComponent } from './carriers-configurations/carriers-configurations.component';
import { ConfigurationComponent } from './configuration.component';
import { SuppliersConfigurationsEditComponent } from './suppliers-configurations/suppliers-configurations-edit/suppliers-configurations-edit.component';
import { SuppliersConfigurationsListComponent } from './suppliers-configurations/suppliers-configurations-list/suppliers-configurations-list.component';
import { SuppliersConfigurationsComponent } from './suppliers-configurations/suppliers-configurations.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { WarehousesComponent } from './warehouses/warehouses.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: ConfigurationComponent,
    children: [
      {
        path: 'suppliers-configurations',
        component: SuppliersConfigurationsComponent,
        children: [
          { path: '', component: SuppliersConfigurationsListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: SuppliersConfigurationsEditComponent },
          { path: 'new', component: SuppliersConfigurationsEditComponent },
        ],
      },
      { path: 'suppliers', component: SuppliersComponent },
      { path: 'warehouses', component: WarehousesComponent },
      {
        path: 'carriers-configurations',
        component: CarriersConfigurationsComponent,
        children: [
          { path: '', component: CarriersConfigurationsListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: CarriersConfigurationsEditComponent },
          { path: 'new', component: CarriersConfigurationsEditComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
