import { PreloadingStrategy, Route } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { Observable, ObservableInput, of, timer } from 'rxjs';
import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => ObservableInput<any>): Observable<any> {
    return !isDevMode() && route.data && route.data['preload'] ? timer(100).pipe(flatMap(() => load())) : of(null);
  }
}
