import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Param')
export class Param {
  @JsonProperty('key', String, true) key: string = undefined;
  @JsonProperty('label', String, true) label: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('default', Any, true) default: any = undefined;

  constructor(key: string, label: string, type: string, defaultz: string) {
    this.key = key;
    this.label = label;
    this.type = type;
    this.default = defaultz;
  }
}

export class TypeParam {
  type: string;
  params: Param[];

  constructor(type: string, params: Param[]) {
    this.type = type;
    this.params = params;
  }
}

export const SENDER_MESSAGE_TYPES_PARAMS = [
  new TypeParam('ftp', [new Param('path', 'Path', 'STRING', '/IN')]),
  new TypeParam('sftp', [new Param('path', 'Path', 'STRING', '/IN')]),
  new TypeParam('aws_sftp', []),
];

export const RECEIVER_MESSAGE_TYPES_PARAMS = [
  new TypeParam('ftp', [new Param('path', 'Path', 'STRING', '/IN'), new Param('fileMask', 'File Mask', 'STRING', '.*')]),
  new TypeParam('sftp', [new Param('path', 'Path', 'STRING', '/IN'), new Param('fileMask', 'File Mask', 'STRING', '.*')]),
  new TypeParam('aws_sftp', [new Param('fileMask', 'File Mask', 'STRING', '.*')]),
];
