import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PurchaseInvoice } from './purchase-invoice-model';

@JsonObject('PurchaseInvoiceItem')
export class PurchaseInvoiceItem extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('ean', String, false) ean: string = undefined;
  @JsonProperty('quantity', Number, false) quantity: number = undefined;
  @JsonProperty('price', Number, false) price: number = undefined;
  @JsonProperty('totalPrice', Number, false) totalPrice: number = undefined;
  @JsonProperty('orderNumber', String, false) orderNumber: string = undefined;
  @JsonProperty('purchaseOrderItemIds', [String], false) purchaseOrderItemIds: string[] = [];
  @JsonProperty('taxRate', Number, false) taxRate: number = undefined;
  @JsonProperty('lineItemReferenceNumber', String, false) lineItemReferenceNumber: string = undefined;
  @JsonProperty('purchaseInvoice', Object, false) purchaseInvoice: PurchaseInvoice = undefined;
}
