import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';

@JsonObject('StockBalanceItem')
export class StockBalanceItem extends BaseObject {
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
}
