<mat-expansion-panel class="little-expansion-panel">
  <mat-expansion-panel-header>
    <mat-panel-title> {{ name }} - ({{ existingKeys?.length + ' element' + (existingKeys?.length === 1 ? '' : 's') }}) </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="row" *ngIf="availableKeys.length !== 0">
    <div class="col-md-3">
      <mat-form-field>
        <mat-select placeholder="Field" [(ngModel)]="newColumn.field" (ngModelChange)="setColumnField()">
          <mat-option *ngFor="let key of availableKeys" [value]="key.code">{{ key.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field class="width-90per">
        <input matInput placeholder="Label" [(ngModel)]="newColumn.label" />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field class="width-90per">
        <input matInput placeholder="Fixed Value" [(ngModel)]="newColumn.fixedValue" />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field>
        <mat-select placeholder="Type" [(ngModel)]="newColumn.type">
          <mat-option *ngFor="let type of columnTypes" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-1">
      <button class="btn-custom small-add-button margin-top-15" matTooltip="Add new" (click)="add()" [disabled]="!newColumn?.field">
        <i class="fa fa-plus" aria-hidden="true"></i> Add column
      </button>
    </div>
  </div>

  <div class="row padding-top-10">
    <div class="col-md-12">
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let key of existingKeys" [removable]="true" (removed)="delete(key)">
          <span>{{ key }}</span>
          <span class="margin-left-5">
            <i class="fa fa-times" matChipRemove aria-hidden="true" matTooltip="Delete key"></i>
          </span>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
</mat-expansion-panel>
