import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { Supplier } from './supplier-model';

@JsonObject('PurchaseInvoice')
export class PurchaseInvoice extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('purchaseInvoiceNumber', String, true) purchaseInvoiceNumber: string = undefined;
  @JsonProperty('purchaseInvoiceDate', String, true) purchaseInvoiceDate: string = undefined;
  @JsonProperty('totalPrice', Number, true) totalPrice: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('supplier', Supplier, false) supplier: Supplier = undefined;
  @JsonProperty('senderGln', String, true) senderGln: string = undefined;
  @JsonProperty('buyerGln', String, true) buyerGln: string = undefined;
  @JsonProperty('supplierGln', String, true) supplierGln: string = undefined;
  @JsonProperty('totalQuantity', Number, true) totalQuantity: number = undefined;

  forEach(): number {
    throw new Error('Method not implemented.');
  }
}

@JsonObject('PurchaseInvoiceFilters')
export class PurchaseInvoiceFilters extends PaginationAndSorting {
  @JsonProperty('purchaseInvoiceNumber', String, true) purchaseInvoiceNumber: string = undefined;
  @JsonProperty('purchaseInvoiceDate', String, true) purchaseInvoiceDate: string = undefined;
  @JsonProperty('totalPrice', String, true) totalPrice: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('senderGln', String, true) senderGln: string = undefined;
  @JsonProperty('buyerGln', String, true) buyerGln: string = undefined;
  @JsonProperty('supplierGln', String, true) supplierGln: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('quantity', String, true) quantity: string = undefined;
  @JsonProperty('price', String, true) price: string = undefined;
  @JsonProperty('totalPrice', String, true) itemTotalPrice: string = undefined;
  @JsonProperty('orderNumber', String, true) orderNumber: string = undefined;
  @JsonProperty('purchaseOrderItemIds', [String], true) purchaseOrderItemIds: string[] = [];
  @JsonProperty('purchaseInvoice', Object, true) purchaseInvoice: PurchaseInvoice = undefined;
}
