import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { Warehouse } from '@models/warehouse-model';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';

@Injectable()
export class WarehousesService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getWarehouses(): Observable<Warehouse[]> {
    return this.http.get(this.environmentService.getRestEndpoint('warehouses')).pipe(
      map((resp: any[]) => {
        if (!isNilty(resp)) {
          return resp.map((it) => this.converter.fromJSONtoObj(it, Warehouse));
        } else {
          return [];
        }
      })
    );
  }

  saveWarehouse(warehouse: Warehouse): Observable<Warehouse> {
    const body = this.converter.fromObjtoJSON(warehouse);
    return this.http
      .post(this.environmentService.getRestEndpoint('warehouses'), body)
      .pipe(map((resp: Warehouse) => this.converter.fromJSONtoObj(resp, Warehouse)));
  }

  delete(id: string): Observable<any> {
    const endpoint = this.environmentService.getRestEndpoint('warehouses');
    const path = this.environmentService.dev ? endpoint : endpoint + '/' + id;
    return this.http.delete(path);
  }
}
