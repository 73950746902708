<app-shipments-list-filters [filters]="initialFilters" (onFilter)="applyFilters($event)"> </app-shipments-list-filters>

<div class="container-fluid pt-4">
  <h2 class="title-custom">Shipments</h2>

  <div class="row pt-3">
    <div class="col-md-12">
      <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="shipmentId" sticky>
        <th mat-header-cell class="shipment-id-column" *matHeaderCellDef mat-sort-header="shipmentId">Shipment ID</th>
        <td mat-cell class="shipment-id-column" *matCellDef="let shipment">{{ shipment.shipmentId }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="createdAt">Created At</th>
        <td mat-cell class="min-w-150" *matCellDef="let shipment">
          <p>{{ shipment.createdAt | myDateTime }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <th mat-header-cell class="carrier-column" *matHeaderCellDef mat-sort-header="carrier">Carrier</th>
        <td mat-cell class="carrier-column" *matCellDef="let shipment">
          <p>{{ shipment.carrier }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell class="status-column" *matHeaderCellDef mat-sort-header="status">Status</th>
        <td mat-cell class="status-column" *matCellDef="let shipment">
          <p>{{ shipment.status?.description }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="trackingNumber">
        <th mat-header-cell class="min-w-270" *matHeaderCellDef mat-sort-header="trackingCode">Tracking Number</th>
        <td mat-cell class="min-w-270" *matCellDef="let shipment">
          <p>{{ shipment.trackingCode }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell class="min-w-80" *matHeaderCellDef mat-sort-header="client">Client</th>
        <td mat-cell class="min-w-80" *matCellDef="let shipment">
          <p>{{ shipment.client }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="packageCount">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="packageCount">Package count</th>
        <td mat-cell class="min-w-110" *matCellDef="let shipment">
          <p>{{ shipment.packageCount }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell class="min-w-150 text-center" *matHeaderCellDef>Download Label</th>
        <td mat-cell class="min-w-150 text-center" *matCellDef="let shipment">
          <button
            class="btn-product btn-fixed"
            (click)="navigateToFile($event, shipment.labelUrl)"
            [disabled]="shipment.labelUrl | isNullOrUndefinedOrEmptyString"
            style="width: 110px !important"
          >
            <i class="fa fa-download padding-right-5" aria-hidden="true"></i>
            Label
          </button>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </table>
  </div>
</div>
