import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SuppliersService } from './suppliers.service';
import { NameCodeEditModalComponent } from '../name-code-edit-modal/name-code-edit-modal.component';
import { Subscription } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog-component';
import { Supplier } from '@models/supplier-model';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css'],
})
export class SuppliersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<Supplier>;

  subscription: Subscription;

  displayedColumns = ['id', 'name', 'code', 'accountNumber', 'createdAt', 'edit', 'delete'];

  constructor(private suppliersService: SuppliersService, private dialog: MatDialog) {}

  ngOnInit() {
    this.loadSuppliers();
  }

  loadSuppliers() {
    this.suppliersService.resetSupplierCache();
    this.suppliersService.getSuppliers().subscribe((suppliers: Supplier[]) => {
      this.dataSource = new MatTableDataSource(suppliers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  edit(supplier: Supplier) {
    this.suppliersService.resetSupplierCache();
    this.subscription = this.dialog
      .open(NameCodeEditModalComponent, {
        data: {
          title: 'Edit supplier ' + supplier.code,
          object: supplier,
        },
      })
      .afterClosed()
      .subscribe((resp: Supplier) => {
        if (!isNilty(resp)) {
          this.saveSupplier(resp);
          this.subscription.unsubscribe();
        }
      });
  }

  new() {
    this.suppliersService.resetSupplierCache();
    const supplier = new Supplier();
    this.subscription = this.dialog
      .open(NameCodeEditModalComponent, {
        data: {
          title: 'New supplier',
          object: supplier,
        },
      })
      .afterClosed()
      .subscribe((resp: Supplier) => {
        if (!isNilty(resp)) {
          this.saveSupplier(resp);
          this.subscription.unsubscribe();
        }
      });
  }

  saveSupplier(supplier: Supplier) {
    this.suppliersService.resetSupplierCache();
    this.suppliersService.saveSupplier(supplier).subscribe(() => {
      this.loadSuppliers();
    });
  }

  delete(supplier: Supplier) {
    this.suppliersService.resetSupplierCache();
    this.subscription = this.dialog
      .open(DeleteDialogComponent, {
        data: 'Deleting supplier ' + supplier.code + ' - ' + supplier.name,
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.suppliersService.delete(supplier.id).subscribe(() => {
            this.loadSuppliers();
            this.subscription.unsubscribe();
          });
        }
      });
  }
}
