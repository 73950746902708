<legend class="small-label">Email about trouble order configuration</legend>

<div class="col-md-12 padding-bottom-20" *ngIf="false">Templates</div>
<ng-container *ngIf="false">
  <div class="col-md-6">
    <mat-form-field class="width-60-perc">
      <mat-select placeholder="SPO Item Trouble Order Template" [(ngModel)]="routeConfiguration.spoItemTroubleOrderTemplate">
        <mat-option *ngFor="let et of emailTemplates.troubleOrderTemplates" [value]="et.templateName">{{ et.templateName }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      class="btn-custom icon-button margin-left-20"
      (click)="openTemplate(routeConfiguration.spoItemTroubleOrderTemplate)"
      matTooltip="Show template"
    >
      <i class="fa fa-code" aria-hidden="true"></i>
    </button>
  </div>
</ng-container>

<div class="col-md-12 padding-top-10">Receivers</div>

<div class="col-md-12">
  <mat-form-field>
    <mat-chip-grid #receiversList>
      <mat-chip-row *ngFor="let r of receivers" [removable]="true" (removed)="remove(r, receivers)">
        {{ r }}
        <button matChipRemove [attr.aria-label]="'remove ' + r">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="New email..."
        [formControl]="receiverInput"
        [matChipInputFor]="receiversList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, receivers, receiverInput)"
      />
    </mat-chip-grid>
  </mat-form-field>
</div>

<div class="col-md-12">CC Receivers</div>

<div class="col-md-12">
  <mat-form-field>
    <mat-chip-grid #ccList>
      <mat-chip-row *ngFor="let r of receivers" [removable]="true" (removed)="remove(r, receivers)">
        {{ r }}
        <button matChipRemove [attr.aria-label]="'remove ' + r">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="New email..."
        [formControl]="ccInput"
        [matChipInputFor]="ccList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, ccReceivers, ccInput)"
      />
    </mat-chip-grid>
  </mat-form-field>
</div>

<div class="col-md-12">Reply To</div>

<div class="col-md-12">
  <mat-form-field class="reply-to">
    <input matInput type="email" [formControl]="replyTo" (ngModelChange)="updateParamStrings()" />
  </mat-form-field>
</div>

<div class="col-md-12 mt-3 text-center">
  <mat-slide-toggle
    [checked]="troubleRouteActive"
    (click)="setTroubleRouteStatus(troubleRouteActive)"
    matTooltip="Click to {{ troubleRouteActive ? 'disable' : 'activate' }} "
    >{{ troubleRouteActive ? 'Active' : 'Disabled' }}</mat-slide-toggle
  >
</div>
