<app-items-list-filters (onFilter)="applyFilters()"></app-items-list-filters>

<div class="container-fluid pt-4">
  <h2 class="title-custom">Items</h2>

  <div class="row pt-3">
    <div class="col-12">
      <mat-paginator [length]="resultsNumber | async" [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
      </mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="sku">
        <th mat-header-cell class="min-w-180" *matHeaderCellDef mat-sort-header="Sku">Sku</th>
        <td mat-cell class="min-w-180" *matCellDef="let i">
          {{ i.sku }}
        </td>
      </ng-container>

      <ng-container matColumnDef="skuAlt">
        <th mat-header-cell class="min-w-180" *matHeaderCellDef mat-sort-header="skuAlt">Sku-Alt</th>
        <td mat-cell class="min-w-180" *matCellDef="let i">
          <p>{{ i.skuAlt }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="productCode">
        <th mat-header-cell class="min-w-180" *matHeaderCellDef mat-sort-header="productCode">Product Code</th>
        <td mat-cell class="min-w-180" *matCellDef="let i">
          <p>{{ i.productCode }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="articleDesc">
        <th mat-header-cell class="min-w-500" *matHeaderCellDef mat-sort-header="articleDesc">Description</th>
        <td mat-cell class="min-w-500" *matCellDef="let i">
          <p>{{ i.articleDesc }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="barcode">
        <th mat-header-cell class="min-w-180" *matHeaderCellDef mat-sort-header="barcode">Barcode</th>
        <td mat-cell class="min-w-180" *matCellDef="let i">
          <p>{{ i.barcode }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="unit">Unit</th>
        <td mat-cell *matCellDef="let i">
          <!-- {{ i.unit }} -->
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="size">Size</th>
        <td mat-cell *matCellDef="let i">
          {{ i.size }}
        </td>
      </ng-container>

      <ng-container matColumnDef="colorCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="colorCode">Color Code</th>
        <td mat-cell *matCellDef="let i">
          {{ i.colorCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="colorDesc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="colorDesc">Color Description</th>
        <td mat-cell *matCellDef="let i">
          {{ i.colorDesc }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="createdAt">Created at</th>
        <td mat-cell class="min-w-150" *matCellDef="let i">
          {{ i.createdAt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="updatedAt">Updated at</th>
        <td mat-cell class="min-w-150" *matCellDef="let i">
          {{ i.updatedAt }}
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
  </div>
</div>
