<div mat-dialog-title>
  <h1 mat-dialog-title class="error-title"><b>Shippypro Error</b></h1>
  <div class="col-md-12"><b>OperationId:</b> {{ operationId }}</div>
  <div class="col-md-12"><b>Error:</b> {{ error }}</div>
</div>

<div mat-dialog-content>
  <form class="row" [formGroup]="labelModelForm" (ngSubmit)="removeError()">
    <div class="col-md-6">
      <mat-form-field>
        <input matInput type="text" id="customerCode" formControlName="customerCode" placeholder="Customer Code" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="addressName" formControlName="addressName" placeholder="Address Name" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="customerAddress" formControlName="customerAddress" placeholder="Customer Address" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="addressLine2" formControlName="addressLine2" placeholder="Address Line2" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="addressLine3" formControlName="addressLine3" placeholder="Address Line3" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="completeAddress" formControlName="completeAddress" placeholder="Complete Address" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="customerLocation" formControlName="customerLocation" placeholder="Customer Location" />
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field>
        <input matInput type="text" id="customerCountry" formControlName="customerCountry" placeholder="Customer Country" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="county" formControlName="county" placeholder="County" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="customerZipCode" formControlName="customerZipCode" placeholder="Customer Zip Code" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="state" formControlName="state" placeholder="State" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="district" formControlName="district" placeholder="District" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="addressPhone" formControlName="addressPhone" placeholder="Address Phone" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" id="addressType" formControlName="addressType" placeholder="Address Type" />
      </mat-form-field>
    </div>
    <div class="form-buttons">
      <button class="flex-grow-1" mat-button type="submit">Save</button>
      <button class="flex-grow-1" mat-button type="button" (click)="close()">Back</button>
    </div>
  </form>
</div>
