import { Component, OnInit, Output } from '@angular/core';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';
import { isNilty } from '@core/utils.service';
import { operationsFieldDefinitions } from '@environments/operations';
import { BridgetEnums } from '@models/bridget-enums-model';
import { StockMovementFilters, stockMovementFiltersMap } from '@models/stock-movement-model';
import { Supplier } from '@models/supplier-model';
import { CommonsService } from '@shared/commons.service';
import { Subject } from 'rxjs';
import { StockBalanceService } from '../../stock-balance.service';
import { StockLocation } from '@models/stock-location-model';

@Component({
  selector: 'app-stock-movement-filters',
  templateUrl: './stock-movement-filters.component.html',
  styleUrls: ['./stock-movement-filters.component.css'],
})
export class StockMovementFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<void>();
  @Output() hasFilters = new Subject<boolean>();

  public openFilter = false;

  filters: StockMovementFilters;

  suppliers: Supplier[];
  operationTypes: string[];
  orderTypes: string[];
  stockLocations: StockLocation[];

  filtersMap = [];
  suppliersSelected: string[] = [];

  constructor(private supplierService: SuppliersService, private service: StockBalanceService, private commonService: CommonsService) {}

  ngOnInit() {
    this.operationTypes = operationsFieldDefinitions.map((it) => it.type);

    this.commonService.getBridgetEnums().subscribe((r: BridgetEnums) => {
      this.orderTypes = r.orderTypes;
    });

    this.commonService.getStockLocations().subscribe((resp: StockLocation[]) => {
      this.stockLocations = resp;
    });

    this.supplierService.getSuppliers().subscribe((resp: Supplier[]) => {
      this.suppliers = resp.sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0));
    });

    if (isNilty(this.service.stockMovementFilters)) {
      this.service.stockMovementFilters = new StockMovementFilters();
    }
    this.filters = this.service.stockMovementFilters;
  }

  onSubmitFilter() {
    this.updateFiltersMap();

    if (this.filtersMap.length > 0) this.hasFilters.next(true);
    else this.hasFilters.next(false);

    this.service.stockMovementFilters.pageIndex = 0;
    this.onFilter.next();
  }

  resetFilters() {
    this.filters = new StockMovementFilters();
    this.service.stockMovementFilters = this.filters;
    this.hasFilters.next(false);
    this.onFilter.next();
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = stockMovementFiltersMap(
      this.filters.operationType,
      this.filters.orderType,
      this.filters.operationId,
      this.filters.supplierCode,
      this.filters.sku,
      this.filters.dateFrom,
      this.filters.dateTo,
      this.filters.stockLocationCode,
      this.filters.customerOrderId
    );
  }
}
