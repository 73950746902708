import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmailParamsFormComponent } from '../email-params-form/email-params-form.component';

@Component({
  selector: 'app-email-trouble-order-params-form',
  templateUrl: './email-trouble-order-params-form.component.html',
  styleUrls: ['./email-trouble-order-params-form.component.css'],
})
export class EmailTroubleOrderParamsFormComponent extends EmailParamsFormComponent {
  @Input() troubleRouteActive = null;
  @Output() newTroubleRouteStatus = new EventEmitter<boolean>();

  setTroubleRouteStatus(value: boolean) {
    this.troubleRouteActive = !value;
    this.newTroubleRouteStatus.emit(this.troubleRouteActive);
  }
}
