<app-spos-list-filters [filters]="initialFilters" (onFilter)="applyFilters($event)"> </app-spos-list-filters>

<div class="container-fluid pt-4">
  <h2 class="title-custom">Spo Items</h2>

  <div class="row">
    <div class="col-md-6 padding-top-10">
      <button class="btn-custom" (click)="openSpos()">Show SPOs</button>
    </div>
    <div class="col-md-6">
      <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="orderId" sticky>
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="order.orderId">Order ID</th>
        <td mat-cell class="min-w-150" *matCellDef="let spoItem">
          <button [routerLink]="['/spo-detail']" [queryParams]="{ orderId: spoItem.order.orderId }">{{ spoItem.order?.orderId }}</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sendingRouteType">
        <th mat-header-cell class="sent-via-column" *matHeaderCellDef mat-sort-header="sendingRouteType">Sent via</th>
        <td mat-cell class="sent-via-column" *matCellDef="let spoItem">{{ spoItem.order?.sendingRouteType }}</td>
      </ng-container>

      <ng-container matColumnDef="clientOrderItemId">
        <th mat-header-cell class="client-application-id-column" *matHeaderCellDef mat-sort-header="clientOrderItemId">
          Client OrderItem ID
        </th>
        <td mat-cell class="client-application-id-column" *matCellDef="let spoItem">{{ spoItem.clientOrderItemId }}</td>
      </ng-container>

      <ng-container matColumnDef="supplierOrderItemId">
        <th mat-header-cell class="client-application-id-column" *matHeaderCellDef mat-sort-header="supplierOrderItemId">
          Supplier OrderItem ID
        </th>
        <td mat-cell class="client-application-id-column" *matCellDef="let spoItem">{{ spoItem.supplierOrderItemId }}</td>
      </ng-container>

      <ng-container matColumnDef="supplierOrderItemStatus">
        <th mat-header-cell class="supplier-status-column" *matHeaderCellDef mat-sort-header="supplierOrderItemStatus">Supplier Status</th>
        <td mat-cell class="supplier-status-column" *matCellDef="let spoItem">{{ spoItem.supplierOrderItemStatus }}</td>
      </ng-container>

      <ng-container matColumnDef="orderDate">
        <th mat-header-cell class="order-date-column" *matHeaderCellDef mat-sort-header="order.orderDate">Order Date</th>
        <td mat-cell class="order-date-column" *matCellDef="let spoItem" [matTooltip]="spoItem.order?.orderDate" matTooltipPosition="below">
          {{ spoItem.order?.orderDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="client" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order.client" class="min-w-80">Client</th>
        <td mat-cell class="min-w-80" *matCellDef="let spoItem" [matTooltip]="spoItem.order?.client" matTooltipPosition="below">
          <p>{{ spoItem.order?.client }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="owner" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order.owner" class="min-w-80">Owner</th>
        <td mat-cell class="min-w-80" *matCellDef="let spoItem" [matTooltip]="spoItem.order?.owner" matTooltipPosition="below">
          <p>{{ spoItem.order?.owner }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order.supplier" class="min-w-150">Supplier</th>
        <td mat-cell class="min-w-150" *matCellDef="let spoItem" [matTooltip]="spoItem.order?.supplier?.name" matTooltipPosition="below">
          <p>{{ spoItem.order?.supplier?.name }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="earliestDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order.earliestDeliveryDate" class="order-date-column">Earliest Date</th>
        <td mat-cell class="order-date-column" *matCellDef="let spoItem">
          <p>{{ spoItem.order?.earliestDeliveryDate }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order.latestDeliveryDate" class="order-date-column">Latest Date</th>
        <td mat-cell class="order-date-column" *matCellDef="let spoItem">
          <p>{{ spoItem.order?.latestDeliveryDate }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order.status" class="min-w-80">Status</th>
        <td mat-cell class="min-w-80" *matCellDef="let spoItem">
          <p>{{ spoItem.order?.status }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <th mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean">EAN</th>
        <td mat-cell class="ean-column" *matCellDef="let spoItem">{{ spoItem.ean }}</td>
      </ng-container>

      <ng-container matColumnDef="altEan" sticky>
        <th mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="altEan">Actual EAN</th>
        <td mat-cell class="ean-column" *matCellDef="let spoItem">{{ spoItem.altEan }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="price">Price</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.price | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="requestedQuantity">Requested quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo?.requestedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="confirmedQuantity">Confirmed quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo?.confirmedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="orderedQuantity">
          Ordered
          <br />
          quantity
        </th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo?.orderedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="requestedValue">
          Requested <br />
          value
        </th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.requestedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="confirmedValue">
          Confirmed <br />
          value
        </th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.confirmedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="orderedValue">
          Ordered <br />
          value
        </th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.orderedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </table>
  </div>
</div>
