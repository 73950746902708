<app-spos-list-filters [spoFilters]="false" [filters]="initialFilters" (onFilter)="applyFilters($event)"> </app-spos-list-filters>

<div class="container-fluid">
  <div class="row my-3 align-items-center">
    <div class="col-md-10">
      <h2 class="fst-italic text-secondary">SPO: {{ spo?.orderId }}</h2>
    </div>

    <div class="col-md-2 text-end">
      <span class="padding-left-5">
        <button class="btn-custom" (click)="goBack()"><i class="fa fa-chevron-left" aria-hidden="true"></i> Back</button>
      </span>
    </div>
  </div>

  <div class="row mx-0">
    <div class="bluebox">
      <p><b>Client: </b> {{ spo?.client }}</p>
      <p><b>Client Order Id: </b> {{ spo?.clientOrderId }}</p>
      <p><b>Owner: </b> {{ spo?.owner }}</p>
      <p><b>Supplier: </b> {{ spo?.supplier?.code + ' - ' + spo?.supplier?.name }}</p>
      <p><b>Status: </b> {{ spo?.status }}</p>
      <p><b>Order Date: </b> {{ spo?.orderDate }}</p>
      <p><b>Delivery window: </b> {{ spo?.earliestDeliveryDate + ' - ' + spo?.latestDeliveryDate }}</p>
    </div>

    <div class="bluebox">
      <ng-container *ngIf="spo?.shipTo">
        <div class="row">
          <div class="col-md-12">
            <h6><b>Ship to</b></h6>
          </div>

          <div class="col-md-6">
            <p><b>Code:</b> {{ spo.shipTo.code }}</p>
          </div>
          <div class="col-md-6">
            <p><b>Country:</b> {{ spo.shipTo.countryCode }}</p>
          </div>
          <div class="col-md-12">
            <p><b>Name:</b> {{ spo.shipTo.name }}</p>
          </div>
          <div class="col-md-12">
            <p><b>City:</b> {{ spo.shipTo.city }}</p>
          </div>
          <div class="col-md-12 address-col">
            <p><b>Address:</b> {{ spo.shipTo.address }}</p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="bluebox">
      <div class="row margin-bottom-10">
        <div class="col-md-6">
          <h6 class="quantity-value-row-title"><b>Requested</b></h6>
          <p class="quantity-value-row"><b>Quantity: </b> {{ spo?.requestedQuantity }}</p>
          <p class="quantity-value-row"><b>Value: </b> {{ spo?.requestedValue | myCurrency }}</p>
        </div>

        <div class="col-md-6">
          <h6 class="quantity-value-row-title"><b>Confirmed</b></h6>
          <p class="quantity-value-row"><b>Quantity: </b> {{ spo?.confirmedQuantity }}</p>
          <p class="quantity-value-row"><b>Value: </b> {{ spo?.confirmedValue | myCurrency }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h6 class="quantity-value-row-title"><b>Ordered</b></h6>
          <p class="quantity-value-row"><b>Quantity: </b> {{ spo?.orderedQuantity }}</p>
          <p class="quantity-value-row"><b>Value: </b> {{ spo?.orderedValue | myCurrency }}</p>
        </div>
      </div>
    </div>

    <div class="bluebox">
      <p><b>Sent via: </b> {{ spo?.sendingRouteType }}</p>
      <p *ngIf="!spo?.clientOrderId"><b>Client Order ID: </b> {{ spo?.orderId }}</p>
      <p *ngIf="spo?.supplierOrderId"><b>Supplier Order ID: </b> {{ spo?.supplierOrderId }}</p>
      <p *ngIf="spo?.supplierStatus"><b>Supplier Order Status: </b> {{ spo?.supplierStatus }}</p>
    </div>
  </div>
</div>

<div class="padding-top-15">
  <mat-toolbar class="lot-edit-bulk-actions">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <button class="btn-custom box-shadow-none margin-right-6" (click)="download(spo.spoFileUrl)" [disabled]="!spo?.spoFileUrl">
            <i class="fa fa-download margin-right-6" aria-hidden="true"></i>SPO
          </button>
          <button
            class="btn-custom box-shadow-none margin-right-38"
            (click)="download(spo.externalClientSpoFileUrl)"
            *ngIf="spo?.externalClientSpoFileUrl"
          >
            <i class="fa fa-download margin-right-6" aria-hidden="true"></i>Client SPO
          </button>

          <button class="btn-custom box-shadow-none margin-right-6" (click)="download(spo.spoaFileUrl)" [disabled]="!spo?.spoaFileUrl">
            <i class="fa fa-download margin-right-6" aria-hidden="true"></i>SPOA
          </button>
          <button
            class="btn-custom box-shadow-none margin-right-6"
            (click)="download(spo.externalClientSpoaFileUrl)"
            *ngIf="spo?.externalClientSpoaFileUrl"
          >
            <i class="fa fa-download margin-right-6" aria-hidden="true"></i> Client SPOA
          </button>

          <button
            class="btn-custom box-shadow-none margin-right-6"
            (click)="download(spo.spoConfirmFileUrl)"
            [disabled]="!spo?.spoConfirmFileUrl"
          >
            <i class="fa fa-download margin-right-6" aria-hidden="true"></i>SPO Ordered
          </button>
          <button
            class="btn-custom box-shadow-none margin-right-6"
            (click)="setStatusSent()"
            *ngIf="isAuthorized | async"
            [disabled]="spo?.status !== 'CONFIRMED'"
          >
            <i class="fa fa-undo margin-right-6" aria-hidden="true"></i>Reset Status SENT
          </button>
          <button class="btn-custom box-shadow-none margin-right-6" [disabled]="!hasShippingInfo" (click)="deleteShippingInfo()">
            <i class="fa fa-trash margin-right-6" aria-hidden="true"></i>Delete Shipping Info
          </button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container-fluid padding-top-15">
  <div class="row">
    <div class="col-md-12">
      <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="ean" sticky>
        <th mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean">EAN</th>
        <td mat-cell class="ean-column" *matCellDef="let spoItem">{{ spoItem.ean }}</td>
      </ng-container>

      <ng-container matColumnDef="altEan" sticky>
        <th mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="altEan">Actual EAN</th>
        <td mat-cell class="ean-column" *matCellDef="let spoItem">{{ spoItem.altEan }}</td>
      </ng-container>

      <ng-container matColumnDef="clientOrderItemId">
        <th mat-header-cell class="client-application-id-column" *matHeaderCellDef mat-sort-header="clientOrderItemId">
          Client OrderItem ID
        </th>
        <td mat-cell class="client-application-id-column" *matCellDef="let spoItem">{{ spoItem.clientOrderItemId }}</td>
      </ng-container>

      <ng-container matColumnDef="supplierOrderItemId">
        <th mat-header-cell class="client-application-id-column" *matHeaderCellDef mat-sort-header="supplierOrderItemId">
          Supplier OrderItem ID
        </th>
        <td mat-cell class="client-application-id-column" *matCellDef="let spoItem">{{ spoItem.supplierOrderItemId }}</td>
      </ng-container>

      <ng-container matColumnDef="supplierOrderItemStatus">
        <th mat-header-cell class="supplier-status-column" *matHeaderCellDef mat-sort-header="supplierOrderItemStatus">Supplier Status</th>
        <td mat-cell class="supplier-status-column" *matCellDef="let spoItem">{{ spoItem.supplierOrderItemStatus }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="price">Price</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.price | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="requestedQuantity">Requested quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo?.requestedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="confirmedQuantity">Confirmed quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo?.confirmedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="orderedQuantity">
          Ordered <br />
          quantity
        </th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo?.orderedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="requestedValue">
          Requested <br />
          value
        </th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.requestedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="confirmedValue">
          Confirmed <br />
          value
        </th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.confirmedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="orderedValue">
          Ordered <br />
          value
        </th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo?.orderedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </table>
  </div>
</div>
