import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConverterService } from '@core/converter.service';
import { Carrier } from '@models/carrier-model';
import {
  CarrierConfigsFilters,
  CarriersConfigurationGet,
  CarriersConfigurationSave,
} from '@models/carriers-configuration/carriers-configuration-model';
import { GenericArrayCache, cachedArrayGet } from '@shared/service-cached-array';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';

@Injectable()
export class CarriersConfigurationsService {
  carrierConfigsFilters: CarrierConfigsFilters;

  resultsNumber = new Subject<number>();

  private carriersCache = new GenericArrayCache<Carrier>(this.http, this.converter);

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar
  ) {}

  resetCarrierCache() {
    this.carriersCache.reset();
  }

  getCarriers(): Observable<Carrier[]> {
    return cachedArrayGet<Carrier>(this.carriersCache, this.environmentService.getRestEndpoint('getCarriers'));
  }

  getCarriersConfigurations(): Observable<CarriersConfigurationGet[]> {
    const path = this.environmentService.getRestEndpoint('carriersConfigurations');
    return this.http.post(path, this.carrierConfigsFilters, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: CarriersConfigurationGet[]) => resp.map((it) => this.converter.fromJSONtoObj(it, CarriersConfigurationGet)))
    );
  }

  getConfiguration(id: string): Observable<CarriersConfigurationGet> {
    const path = this.environmentService.getRestEndpoint('carriersConfigurations') + '/' + id;
    return this.http.get(path).pipe(map((resp: CarriersConfigurationGet) => this.converter.fromJSONtoObj(resp, CarriersConfigurationGet)));
  }

  deleteConfiguration(id: string): Observable<CarriersConfigurationGet> {
    const path = this.environmentService.getRestEndpoint('carriersConfigurations') + '/' + id;
    return this.http
      .delete(path)
      .pipe(map((resp: CarriersConfigurationGet) => this.converter.fromJSONtoObj(resp, CarriersConfigurationGet)));
  }

  save(config: CarriersConfigurationSave): Observable<any> {
    const body = this.converter.fromObjtoJSON(config);
    return this.http.post(this.environmentService.getRestEndpoint('saveCarriersConfiguration'), body);
  }

  uploadCarrierConfigurations(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('uploadCarriersConfigurations'), formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      })
    );
  }
}
