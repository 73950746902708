import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';

@JsonObject('NameAndCode')
export class NameAndCode extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('accountNumber', String, true) accountNumber: string = undefined;
}
