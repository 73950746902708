import { isNilty } from '@core/utils.service';

const checkIfLonger = (value: string, limit: number) => {
  if (!isNilty(value)) {
    if (value.length > limit) {
      return value.substr(0, limit - 3) + '…';
    } else {
      return value;
    }
  } else {
    return '';
  }
};

export class MatChipsData {
  filter: string;

  constructor(key: string, value: string | number) {
    if (key === 'EAN' || typeof value === 'number') {
      this.filter = key + ': ' + value;
    } else {
      this.filter = key + (!isNilty(value) ? ': ' + checkIfLonger(value, 15) : '');
    }
  }
}
