import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNilty } from '@core/utils.service';
import { EmailTemplates } from '@models/supplier-configuration/email-templates-model';
import { RouteConfiguration } from '@models/supplier-configuration/route-configuration-model';
import { SuppliersConfigurationsService } from '../../../suppliers-configurations.service';
import { EmailTemplateModalComponent } from './email-template-modal.component';

@Component({
  selector: 'app-email-params-form',
  templateUrl: './email-params-form.component.html',
  styleUrls: ['./email-params-form.component.css'],
})
export class EmailParamsFormComponent implements OnInit {
  @Input() routeConfiguration: RouteConfiguration;

  @Output() isValidRoute = new EventEmitter<boolean>();

  receiverInput = new FormControl('', Validators.email);
  ccInput = new FormControl('', Validators.email);
  replyTo = new FormControl('', [Validators.required, Validators.email]);

  sposWillBeConfirmed: boolean;

  receivers: string[];
  ccReceivers: string[];

  params: any;

  emailTemplates: EmailTemplates;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private snackBar: MatSnackBar,
    private supplierConfigurationsService: SuppliersConfigurationsService,
    private matDialog: MatDialog
  ) {}

  @Input() set _sposWillBeConfirmed(r: boolean) {
    this.sposWillBeConfirmed = r;
    if (!r && this.routeConfiguration) {
      this.routeConfiguration.spoConfirmTemplate = null;
    }
  }

  ngOnInit() {
    this.params = this.routeConfiguration.endpointParams;
    this.receivers = this.splitString(this.params['receiverEmail']);
    this.ccReceivers = this.splitString(this.params['ccReceivers']);
    this.replyTo.setValue(this.params['replyTo']);
    this.isValidForm();

    this.supplierConfigurationsService.getEmailTemplates().subscribe((r) => {
      console.log(r);
      this.emailTemplates = r;
    });
  }

  isValidForm() {
    this.isValidRoute.next(this.receivers.length > 0 && this.replyTo.valid);
  }

  add(event: MatChipInputEvent, list: string[], control: FormControl): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (!isNilty(value)) {
      if (control.valid) {
        list.push(value);
        this.updateParamStrings();
        // Reset the input value
        if (input) {
          input.value = '';
          control.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  remove(r: string, list: string[]): void {
    const index = list.indexOf(r);

    if (index >= 0) {
      list.splice(index, 1);
      this.updateParamStrings();
      this.isValidForm();
    }
  }

  updateParamStrings() {
    this.params['receiverEmail'] = this.receivers.join(',');
    this.params['ccReceivers'] = this.ccReceivers.join(',');
    this.params['replyTo'] = this.replyTo.value;
    this.isValidForm();
  }

  openTemplate(templateName: string) {
    const template =
      this.emailTemplates.confirmTemplates.find((it) => it.templateName === templateName) ||
      this.emailTemplates.sendingTemplates.find((it) => it.templateName === templateName);
    if (!isNilty(template)) {
      this.matDialog.open(EmailTemplateModalComponent, { data: template });
    }
  }

  private splitString(mailString: any): string[] {
    if (isNilty(mailString)) {
      return [];
    } else {
      return (mailString as string).split(',');
    }
  }
}
