import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { isNilty } from '@core/utils.service';
import { PurchaseInvoiceItem } from '@models/purchase-invoice-item-model';
import { PurchaseInvoice, PurchaseInvoiceFilters } from '@models/purchase-invoice-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';

@Injectable()
export class PurchaseInvoiceService {
  purchaseInvoiceFilters: PurchaseInvoiceFilters;
  purchaseInvoiceItemFilters: PurchaseInvoiceFilters;

  purchaseInvoiceResultsNumber = new Subject<number>();
  purchaseInvoiceItemResultsNumber = new Subject<number>();

  constructor(private converter: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getPurchaseInvoiceByFilters(): Observable<PurchaseInvoice[]> {
    const body = this.converter.fromObjtoJSON(this.purchaseInvoiceFilters);
    return this.http
      .post(this.environmentService.getRestEndpoint('purchaseInvoice'), body, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.purchaseInvoiceResultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        })
      )
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return resp.map((it) => this.converter.fromJSONtoObj(it, PurchaseInvoice));
          } else {
            return [];
          }
        })
      );
  }

  getPurchaseInvoice(purchaseInvoiceNumber: string): Observable<PurchaseInvoice> {
    return this.http
      .get(this.environmentService.getRestEndpoint('purchaseInvoice') + '/' + purchaseInvoiceNumber, { observe: 'response' })
      .pipe(map((resp: HttpResponse<any>) => resp.body))
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return this.converter.fromJSONtoObj(resp, PurchaseInvoice);
          } else {
            return null;
          }
        })
      );
  }

  getPurchaseInvoiceItemsByFilters(): Observable<PurchaseInvoiceItem[]> {
    const body = this.converter.fromObjtoJSON(this.purchaseInvoiceItemFilters);
    return this.http.post(this.environmentService.getRestEndpoint('purchaseInvoiceItem'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.purchaseInvoiceItemResultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((purchaseInvoiceResp: PurchaseInvoiceItem[]) => {
        if (!isNilty(purchaseInvoiceResp)) {
          return purchaseInvoiceResp.map((it) => this.converter.fromJSONtoObj(it, PurchaseInvoiceItem));
        } else {
          return [];
        }
      })
    );
  }
}
