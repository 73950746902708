<div class="row">
  <legend>
    {{ title }}
    <i
      class="fa fa-question-circle small-help-icon"
      aria-hidden="true"
      [matTooltip]="
        isReceiver
          ? 'Jimbo will wait for messages from this endpoint (SPOA and PACKING_LIST messages).'
          : 'Jimbo will send messages to this endpoint (SPO and CONFIRM_SPO messages).'
      "
    ></i>
  </legend>
</div>

<div class="row margin-top-10">
  <div class="col-md-3">
    <mat-form-field>
      <mat-select [(ngModel)]="routeConfiguration.endpointType" (ngModelChange)="resetParams($event)" placeholder="Endpoint type">
        <mat-option *ngFor="let et of endpointTypes" [value]="et.endpointType">{{ et.endpointType }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="!isReceiver">
    <div class="col-md-2 padding-top-15">
      <mat-checkbox [(ngModel)]="routeConfiguration.immediate" name="immediate" (ngModelChange)="isValidForm()"
        >Send SPOs immediately?</mat-checkbox
      >
    </div>
    <div class="col-md-3">
      <mat-form-field>
        <input
          matInput
          type="text"
          [(ngModel)]="routeConfiguration.cronExpression"
          (ngModelChange)="isValidForm()"
          [disabled]="routeConfiguration.immediate"
          id="cronExpression"
          placeholder="Cron expression"
        />
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field>
        <input
          matInput
          type="number"
          [(ngModel)]="routeConfiguration.minimumThreshold"
          [disabled]="routeConfiguration.immediate"
          id="minimumThreshold"
          placeholder="Minimum Threshold"
        />
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field>
        <input
          matInput
          type="number"
          [(ngModel)]="routeConfiguration.latestShippingBuffer"
          [disabled]="routeConfiguration.immediate"
          id="latestShippingBuffer"
          placeholder="Latest Shipping Buffer"
        />
      </mat-form-field>
    </div>
  </ng-container>
</div>

<div class="row margin-top-10">
  <app-email-params-form
    *ngIf="routeConfiguration?.endpointType === 'email' && cancellationRouteActive === null && troubleRouteActive === null"
    [routeConfiguration]="routeConfiguration"
    [_sposWillBeConfirmed]="sposWillBeConfirmed"
    (isValidRoute)="updateValidity($event)"
  ></app-email-params-form>

  <app-email-cancel-order-params-form
    *ngIf="routeConfiguration?.endpointType === 'email' && cancellationRouteActive !== null"
    [routeConfiguration]="routeConfiguration"
    (isValidRoute)="updateValidity($event)"
    [cancellationRouteActive]="cancellationRouteActive"
    (newCancellationRouteStatus)="setCancellationRouteStatus($event)"
  ></app-email-cancel-order-params-form>

  <app-email-trouble-order-params-form
    *ngIf="routeConfiguration?.endpointType === 'email' && troubleRouteActive !== null"
    [routeConfiguration]="routeConfiguration"
    (isValidRoute)="updateValidity($event)"
    [troubleRouteActive]="troubleRouteActive"
    (newTroubleRouteStatus)="setTroubleRouteStatus($event)"
  ></app-email-trouble-order-params-form>

  <app-ftp-params-form
    *ngIf="
      routeConfiguration?.endpointType === 'ftp' ||
      routeConfiguration?.endpointType === 'sftp' ||
      routeConfiguration?.endpointType === 'ftps'
    "
    [params]="routeConfiguration?.endpointParams"
    (isValidRoute)="updateValidity($event)"
    [isReceiver]="isReceiver"
  ></app-ftp-params-form>

  <app-pubeasy-params-form
    *ngIf="routeConfiguration?.endpointType === 'pubeasy'"
    [params]="routeConfiguration?.endpointParams"
    (isValidRoute)="updateValidity($event)"
  ></app-pubeasy-params-form>

  <app-arianna-params-form
    *ngIf="routeConfiguration?.endpointType === 'arianna'"
    [params]="routeConfiguration?.endpointParams"
    (isValidRoute)="updateValidity($event)"
  ></app-arianna-params-form>
</div>
