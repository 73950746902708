import { Component, Input } from '@angular/core';
import { Operation } from '@models/operation-model';
import { Header } from '@models/operations-config-model';

@Component({
  selector: 'app-operation-details-accordion',
  templateUrl: './operation-details-accordion.component.html',
  styleUrls: ['./operation-details-accordion.component.css'],
})
export class OperationDetailsAccordionComponent {
  @Input() operation: Operation;
  @Input() accordion: Header;
}
