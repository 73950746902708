import { JsonObject, JsonProperty } from 'json2typescript';
import { PaginationAndSorting } from '../pagination-and-sorting-model';

@JsonObject('SupplierConfigurationFilters')
export class SupplierConfigurationFilters extends PaginationAndSorting {
  @JsonProperty('supplierString', String, true) supplierString: string = undefined;
  @JsonProperty('client', String, true) client: string = undefined;

  constructor() {
    super();
    this.pageSize = 10;
  }
}
