<mat-toolbar class="container-fluid">
  <div class="row col-md-12">
    <div class="col-md-8 col-md-offset-2">
      <button class="btn-custom" (click)="new()">New Supplier</button>
    </div>
  </div>
</mat-toolbar>

<div class="container-fluid margin-bottom-60">
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let supplier">{{ supplier.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
      <td mat-cell *matCellDef="let supplier" [matTooltip]="supplier.name" matTooltipPosition="below">{{ supplier.name }}</td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="code">Code</th>
      <td mat-cell *matCellDef="let supplier">{{ supplier.code }}</td>
    </ng-container>

    <ng-container matColumnDef="accountNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="accountNumber">AccountNumber</th>
      <td mat-cell *matCellDef="let supplier">{{ supplier.accountNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Created At</th>
      <td mat-cell *matCellDef="let supplier">{{ supplier.createdAt }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell class="button-column" *matHeaderCellDef></th>
      <td mat-cell class="button-column" *matCellDef="let supplier">
        <button class="btn-product btn-fixed" (click)="edit(supplier)">
          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          Edit
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell class="button-column" *matHeaderCellDef></th>
      <td mat-cell class="button-column" *matCellDef="let supplier">
        <button class="btn-product btn-fixed" (click)="delete(supplier)">
          <i class="fa fa-trash" aria-hidden="true"></i>
          Delete
        </button>
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="sticky-top"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>
