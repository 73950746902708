import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css'],
})
export class ContainerComponent {
  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe((params) => {
      if (params.has('token')) {
        sessionStorage.setItem('token', params.get('token'));
        this.router.navigate([], {
          queryParams: {
            token: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }
}
