import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { Param } from './params-model';

@JsonObject('MessageConfiguration')
export class MessageConfiguration {
  @JsonProperty('fileType', String, true) fileType: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('fileParams', Any, true) fileParams: any = undefined;
  @JsonProperty('active', Boolean, true) active = true;

  params: Param[];

  constructor(type: string) {
    this.type = type;
    this.fileParams = {};
  }
}
