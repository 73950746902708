<div class="main-content padding-bottom-20">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <legend>Supplier {{ currentConfiguration?.supplier ? currentConfiguration?.supplier.code : '' }} Configuration</legend>
        </div>

        <div class="row margin-bottom-20">
          <div class="col-md-12" *ngIf="!editMode">
            <app-brandon-autocomplete
              label="Supplier"
              [allData]="suppliers"
              [(value)]="selectedSupplierId"
              filterKey="description"
              displayKey="description"
              valueKey="id"
              (valueChange)="supplierSelected($event)"
            ></app-brandon-autocomplete>

            <button class="btn-custom margin-right-left-15 margin-bottom-10" matTooltip="Add new supplier" (click)="newSupplier()">
              <i class="fa fa-plus" aria-hidden="true"></i> New
            </button>

            <mat-form-field class="margin-right-left-15" *ngIf="currentConfiguration">
              <mat-select [(ngModel)]="currentConfiguration.client" placeholder="Client">
                <mat-option *ngFor="let c of clients" [value]="c">{{ c }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <ng-container *ngIf="editMode">
            <div class="col-md-2">
              <span><b>Name:</b> {{ currentConfiguration?.supplier.name }} </span>
            </div>
            <div class="col-md-2">
              <span><b>Code:</b> {{ currentConfiguration?.supplier.code }} </span>
            </div>
            <div class="col-md-2">
              <span><b>Client:</b> {{ currentConfiguration?.client }} </span>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="currentConfiguration">
          <hr />
          <div class="row">
            <div class="col-md-3">
              <mat-checkbox
                [(ngModel)]="currentConfiguration.splitSpoFiles"
                matTooltip="If true, will send a different message for each order"
                >Split SPO files</mat-checkbox
              >
            </div>
            <div class="col-md-3">
              <mat-checkbox
                [(ngModel)]="currentConfiguration.sposWillBeConfirmed"
                matTooltip="If true, Jimbo will expect a CONFIRM_SPO message to Close the orders"
                >SPOs will be confirmed
              </mat-checkbox>
            </div>
            <div class="col-md-3">
              <mat-checkbox
                [(ngModel)]="currentConfiguration.sendOnlyOrdered"
                matTooltip="If true, only the rows with orderedQuantity > 0 will be sent in SPO messages"
                >Send only ordered</mat-checkbox
              >
            </div>
            <div class="col-md-3">
              <mat-checkbox
                [(ngModel)]="currentConfiguration.dontSendRequested"
                matTooltip="If true, the requestedQuantities will be set equals to the ordered in SPO messages"
                >Don't send requested</mat-checkbox
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-checkbox
                [(ngModel)]="currentConfiguration.groupSpoItems"
                matTooltip="If true, SpoItems with same EAN inside the same SPO will be grouped together before being sent to the Supplier"
              >
                Group SPOItems</mat-checkbox
              >
            </div>
            <div class="col-md-3">
              <mat-checkbox
                [(ngModel)]="currentConfiguration.updatesPricesWithSpoa"
                matTooltip="If true, the prices on the orders will be updated with the ones received in SPOAs and sent back to Clients"
              >
                Update Prices with SPOA</mat-checkbox
              >
            </div>
          </div>

          <div class="row margin-bottom-10 margin-top-20">
            <div class="col-md-12">Notification Receivers</div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-chip-grid #notReceiver aria-label="New email...">
                  <mat-chip-row *ngFor="let r of notificationReceivers" [removable]="true" (removed)="remove(r, notificationReceivers)">
                    {{ r }}
                    <button matChipRemove [attr.aria-label]="'remove ' + r">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  <input
                    placeholder="New email..."
                    [formControl]="notificationReceiver"
                    [matChipInputFor]="notReceiver"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event, notificationReceivers, notificationReceiver)"
                  />
                </mat-chip-grid>
              </mat-form-field>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedSupplierId && currentConfiguration?.client">
          <div class="row route-config-div margin-top-10 margin-bottom-10">
            <div class="col-md-12">
              <app-route-configuration-edit
                (isValidRoute)="updateValidity($event)"
                title="Sender Route"
                [routeConfiguration]="currentConfiguration?.senderRoute"
                [endpointTypes]="endpointTypes"
                [sposWillBeConfirmed]="currentConfiguration?.sposWillBeConfirmed"
                [isReceiver]="false"
              ></app-route-configuration-edit>

              <div class="row" *ngIf="currentConfiguration">
                <app-message-types-form
                  [isReceiver]="false"
                  [messagesConfiguration]="currentConfiguration?.senderMessages"
                  [messageTypes]="messageTypes?.senderMessages"
                  [routeType]="currentConfiguration.senderRoute?.endpointType"
                  [routeTypeParams]="senderMessageTypesParams"
                ></app-message-types-form>
              </div>
            </div>
          </div>

          <div class="row margin-bottom-10 margin-top-20" *ngIf="!currentConfiguration.receiverRoute && canSetReceiverMessages">
            The Receiver Route will inherit the Sender Route params.
            <a class="link" (click)="addReceiverRoute()">Click here to add a different configuration.</a>
          </div>
          <div class="row margin-bottom-10 margin-top-20" *ngIf="!currentConfiguration.receiverRoute && !canSetReceiverMessages">
            Email cannot be used as a Receiver Route.
            <a class="link" (click)="addReceiverRoute()">Click here to add a Receiver Route configuration.</a>
          </div>

          <div
            class="row route-config-div margin-top-20 margin-bottom-10"
            *ngIf="currentConfiguration?.receiverRoute || (canSetReceiverMessages && currentConfiguration)"
          >
            <div class="col-md-12">
              <app-route-configuration-edit
                *ngIf="currentConfiguration?.receiverRoute"
                (isValidRoute)="updateValidity($event)"
                title="Receiver Route"
                [routeConfiguration]="currentConfiguration?.receiverRoute"
                [endpointTypes]="receiverEndpointTypes"
                [sposWillBeConfirmed]="currentConfiguration?.sposWillBeConfirmed"
                [isReceiver]="true"
              ></app-route-configuration-edit>

              <div class="row" *ngIf="canSetReceiverMessages && currentConfiguration">
                <app-message-types-form
                  [isReceiver]="true"
                  [messagesConfiguration]="currentConfiguration.receiverMessages"
                  [messageTypes]="messageTypes.receiverMessages"
                  [routeType]="
                    currentConfiguration.receiverRoute?.endpointType
                      ? currentConfiguration.receiverRoute.endpointType
                      : currentConfiguration.senderRoute.endpointType
                  "
                  [routeTypeParams]="receiverMessageTypesParams"
                ></app-message-types-form>
              </div>
            </div>
          </div>

          <div class="row margin-bottom-10 margin-top-20" *ngIf="!currentConfiguration.cancellationRoute">
            <a class="link" (click)="addCancellationRoute()">Click here to add a Cancel Order Route configuration.</a>
          </div>

          <div class="row route-config-div margin-top-20 margin-bottom-10" *ngIf="currentConfiguration.cancellationRoute">
            <div class="col-md-12">
              <app-route-configuration-edit
                (isValidRoute)="updateValidity($event)"
                title="Cancel Order Route"
                [routeConfiguration]="currentConfiguration?.cancellationRoute"
                [endpointTypes]="endpointTypes"
                [isReceiver]="false"
                [cancellationRouteActive]="currentConfiguration?.cancellationRouteActive"
                (changeCancellationRouteStatus)="setCancellationRouteStatus($event)"
              ></app-route-configuration-edit>
            </div>
          </div>

          <div class="row margin-bottom-10 margin-top-20" *ngIf="!currentConfiguration?.troubleRoute">
            <a class="link" (click)="addTroubleRoute()">Click here to add a Trouble Order Route configuration.</a>
          </div>

          <div class="row route-config-div margin-top-20 margin-bottom-10" *ngIf="currentConfiguration?.troubleRoute">
            <div class="col-md-12">
              <app-route-configuration-edit
                (isValidRoute)="updateValidity($event)"
                title="Trouble Order Route"
                [routeConfiguration]="currentConfiguration?.troubleRoute"
                [endpointTypes]="endpointTypes"
                [isReceiver]="false"
                [troubleRouteActive]="currentConfiguration?.troubleRouteActive"
                (changeTroubleRouteStatus)="setTroubleRouteStatus($event)"
              ></app-route-configuration-edit>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <hr />
    <div class="row">
      <div class="col-md-12 text-center">
        <button class="btn-custom margin-right-6" [disabled]="!canSave" (click)="save()">Save</button>

        <button class="btn-custom margin-right-6" *ngIf="editMode" (click)="getConfiguration()">Revert</button>

        <button class="btn-custom" (click)="goBack()">Back</button>
      </div>
    </div>
  </div>
</div>
