<mat-toolbar id="configurationMenu" color="accent" class="container-fluid">
  <span>
    <button
      *ngIf="hasBridgetAdministrationPermission | async"
      class="btn-configuration"
      [routerLink]="['/configuration/suppliers']"
      routerLinkActive="active"
    >
      <i class="fa fa-address-book-o" aria-hidden="true"></i>
      Suppliers
    </button>

    <button
      *ngIf="hasJimboSupplierConfigurationPermission | async"
      class="btn-configuration"
      [routerLink]="['/configuration/suppliers-configurations']"
      routerLinkActive="active"
    >
      <i class="fa fa-wrench" aria-hidden="true"></i>
      Suppliers Configurations
    </button>

    <button
      *ngIf="hasBridgetAdministrationPermission | async"
      class="btn-configuration"
      [routerLink]="['/configuration/warehouses']"
      routerLinkActive="active"
    >
      <i class="fa fa-cubes" aria-hidden="true"></i>
      Warehouses
    </button>

    <button
      *ngIf="hasBridgetAdministrationPermission | async"
      class="btn-configuration"
      [routerLink]="['/configuration/carriers-configurations']"
      routerLinkActive="active"
    >
      <i class="fa fa-truck" aria-hidden="true"></i>
      Carriers Configurations
    </button>
  </span>

  <span class="fill-remaining-space"></span>
</mat-toolbar>
