<app-spos-list-filters [spoItemFilters]="false" [filters]="initialFilters" (onFilter)="applyFilters($event)"> </app-spos-list-filters>

<div class="container-fluid pt-4">
  <h2 class="title-custom">Spos</h2>

  <div class="row pt-3">
    <div class="col-md-6">
      <button class="btn-custom" (click)="openSpoItems()">Show SPO Items</button>
      <button class="btn-custom" (click)="importSpoa()">Import SPOA</button>
    </div>
    <div class="col-md-6">
      <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="orderId" sticky>
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="orderId">Order ID</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <button (click)="openSpo(spo)">{{ spo.orderId }}</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="clientOrderId">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="clientOrderId">Client Order ID</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">{{ spo.clientOrderId }}</td>
      </ng-container>

      <ng-container matColumnDef="supplierOrderId">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="supplierOrderId">Supplier Order ID</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">{{ spo.supplierOrderId }}</td>
      </ng-container>

      <ng-container matColumnDef="sendingRouteType">
        <th mat-header-cell class="sent-via-column" *matHeaderCellDef mat-sort-header="sendingRouteType">Sent via</th>
        <td mat-cell class="sent-via-column" *matCellDef="let spo">{{ spo.sendingRouteType }}</td>
      </ng-container>

      <ng-container matColumnDef="status" sticky>
        <th mat-header-cell class="min-w-80" *matHeaderCellDef mat-sort-header="status">Status</th>
        <td mat-cell class="min-w-80" *matCellDef="let spo">
          <p>{{ spo.status }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderDate" sticky>
        <th mat-header-cell class="order-date-column" *matHeaderCellDef mat-sort-header="orderDate">Order Date</th>
        <td mat-cell class="order-date-column" *matCellDef="let spo">{{ spo.orderDate }}</td>
      </ng-container>

      <ng-container matColumnDef="client" sticky>
        <th mat-header-cell class="min-w-80" *matHeaderCellDef mat-sort-header="client">Client</th>
        <td mat-cell class="min-w-80" *matCellDef="let spo">{{ spo.client }}</td>
      </ng-container>

      <ng-container matColumnDef="owner" sticky>
        <th mat-header-cell class="min-w-80" *matHeaderCellDef mat-sort-header="owner">Owner</th>
        <td mat-cell class="min-w-80" *matCellDef="let spo">{{ spo.owner }}</td>
      </ng-container>

      <ng-container matColumnDef="supplier" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="min-w-150">Supplier</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo" [matTooltip]="spo.supplier?.name" matTooltipPosition="below">
          <p>{{ spo.supplier?.name }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="earliestDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="earliestDeliveryDate" class="order-date-column">Earliest Date</th>
        <td mat-cell class="order-date-column" *matCellDef="let spo">
          <p>{{ spo.earliestDeliveryDate }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="latestDeliveryDate" class="order-date-column">Latest Date</th>
        <td mat-cell class="order-date-column" *matCellDef="let spo">
          <p>{{ spo.latestDeliveryDate }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="requestedQuantity">Requested quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo.requestedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="confirmedQuantity">Confirmed quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo.confirmedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderedQuantity">
        <th mat-header-cell class="min-w-110" *matHeaderCellDef mat-sort-header="orderedQuantity">Ordered quantity</th>
        <td mat-cell class="min-w-110" *matCellDef="let spo">
          <p>{{ spo.orderedQuantity }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="requestedValue">Requested value</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo.requestedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="confirmedValue">Confirmed value</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo.confirmedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="orderedValue">
        <th mat-header-cell class="min-w-150" *matHeaderCellDef mat-sort-header="orderedValue">Ordered value</th>
        <td mat-cell class="min-w-150" *matCellDef="let spo">
          <p>{{ spo.orderedValue | myCurrency }}</p>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </table>
  </div>
</div>
