import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-ftp-params-form',
  templateUrl: './ftp-params-form.component.html',
  styleUrls: ['./ftp-params-form.component.css'],
})
export class FtpParamsFormComponent implements OnInit {
  @Input() params: any;
  @Input() isReceiver: boolean;

  @Output() isValidRoute = new EventEmitter<boolean>();

  parametersList = [
    { key: 'host', label: 'Host', required: true },
    { key: 'port', label: 'Port', required: true },
    { key: 'user', label: 'User', required: true },
    { key: 'password', label: 'Password', required: true },
  ];

  onlyReceiverParameters = [{ key: 'pollDelayInMinutes', label: 'Poll delay (minutes)', required: false }];

  ngOnInit() {
    this.isValidForm();
  }

  isValidForm() {
    const allParameters = [...this.parametersList, ...this.onlyReceiverParameters];

    for (const p of allParameters.filter((it) => it.required)) {
      if (isNilty(this.params[p.key])) {
        return this.isValidRoute.next(false);
      }
    }
    this.isValidRoute.next(true);
  }
}
