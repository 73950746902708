import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Observable } from 'rxjs';
import { ItemsService } from './items.service';
import { Item, ItemFilters } from '@models/item-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.css'],
})
export class ItemsListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<Item>;
  displayedColumns = [
    'sku',
    'skuAlt',
    'productCode',
    'articleDesc',
    'barcode',
    'unit',
    'size',
    'colorCode',
    'colorDesc',
    'createdAt',
    'updatedAt',
  ];

  resultsNumber: Observable<number>;

  constructor(private itemService: ItemsService) {}

  ngOnInit() {
    this.resultsNumber = this.itemService.resultsNumber;

    if (isNilty(this.itemService.itemsFilters)) {
      this.itemService.itemsFilters = new ItemFilters();
    }

    this.itemService.getItemsByFilters().subscribe((resp: Item[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  loadPage() {
    this.itemService.itemsFilters.pageSize = this.paginator.pageSize;
    this.itemService.itemsFilters.pageIndex = this.paginator.pageIndex;
    this.itemService.getItemsByFilters().subscribe((resp: Item[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  onSort() {
    this.itemService.itemsFilters.sortBy = this.sort.active;
    this.itemService.itemsFilters.sortDirection = this.sort.direction;
    this.itemService.getItemsByFilters().subscribe((resp: Item[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  applyFilters() {
    this.itemService.getItemsByFilters().subscribe((resp: Item[]) => {
      this.paginator.pageIndex = 0;
      this.dataSource.data = resp;
    });
  }
}
