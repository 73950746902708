<app-stock-movement-filters (onFilter)="applyFilters()" (hasFilters)="hasFilters = $event"></app-stock-movement-filters>

<div class="container-fluid py-4">
  <h2 class="title-custom">Stock Movement</h2>

  <div class="pt-3 mb-3" *ngIf="header">
    <app-stock-buttons exclude="stock-movement"></app-stock-buttons>
  </div>

  <div class="row mx-0" *ngIf="header">
    <div *ngFor="let item of header | keyvalue" class="bluebox">
      <p class="key-table-fixed">
        <b class="margin-right-6"> {{ item?.key }} :</b>
        {{
          item?.key === 'Total Purchase Price Amount' ? (item?.value | myCurrency : dataSource.data[0]?.purchasePriceCurrency) : item?.value
        }}
      </p>
    </div>
  </div>

  <div class="row justify-content-end pt-3">
    <div class="col-md-8" *ngIf="!header">
      <app-stock-buttons exclude="stock-movement"></app-stock-buttons>
      <button
        class="btn-icon ms-2 mt-2"
        [class.d-none]="!hasFilters || dataSource.filteredData.length === 0"
        (click)="exportCSV()"
        matTooltip="Download data"
      >
        <i class="fa fa-download"></i>
      </button>
    </div>

    <div class="col-md-8" *ngIf="header">
      <button
        class="btn-icon ms-2"
        [class.d-none]="!hasFilters || dataSource.filteredData.length === 0"
        (click)="exportCSV()"
        matTooltip="Download data"
      >
        <i class="fa fa-download"></i>
      </button>
      <button class="btn-icon ms-2" (click)="openProductAgingInfo(productAgingFilters)" matTooltip="Show product aging info">
        <i class="fa fa-info"></i>
      </button>
    </div>

    <div class="col-md-4">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="table font-Titillium mb-0" matSort (matSortChange)="onSort()">
    <ng-container matColumnDef="operationType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="operationType">Type</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.operationType.replace('OPERATION_', '') }}
      </td>
    </ng-container>
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="sku">Sku</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.sku }}
      </td>
    </ng-container>
    <ng-container matColumnDef="operationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="operationId">Operation Id</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.operationId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="customerOrderId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="customerOrderId">Customer Order Id</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.customerOrderId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="orderType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="orderType">Order Type</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.orderType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="stockLocationCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="stockLocationCode">Stock Location</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.stockLocationCode }}
      </td>
    </ng-container>
    <ng-container matColumnDef="receivedQty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="receivedQty">Received Qty</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.quantity > 0 ? sm.quantity : '' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="shippedQty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="shippedQty">Shipped Qty</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.quantity < 0 ? Math.abs(sm.quantity) : '' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="totalPurchasePriceAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="totalPurchasePriceAmount">Total Purchase Price Amount</th>
      <td mat-cell class="text-end" *matCellDef="let sm">
        {{ sm.totalPurchasePriceAmount | myCurrency : sm.purchasePriceCurrency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="destinationCountry">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="destinationCountry">Destination Country</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.destinationCountry }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Created At</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.createdAt }}
      </td>
    </ng-container>
    <ng-container matColumnDef="supplierCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="supplierCode">Supplier</th>
      <td mat-cell *matCellDef="let sm">
        {{ sm.supplierCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let sm">
        <button *ngIf="!header" class="btn-icon ms-2" (click)="openProductAgingInfoFromRow(sm)" matTooltip="Show product aging info">
          <i class="fa fa-info"></i>
        </button>
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>
