import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Operation } from '@models/operation-model';
import { GenericConfirmationModalComponent } from '../../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { OperationsService } from '../../operations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { OperationLabelInfoModel } from '@models/operation-label-info-model';

@Component({
  selector: 'app-shippypro-error-modal',
  templateUrl: './shippypro-error-modal.component.html',
  styleUrls: ['./shippypro-error-modal.component.css'],
})
export class ShippyproErrorModalComponent implements OnInit {
  operationId: string;
  error: string;
  labelModelForm: FormGroup;
  operationLabelInfoModel = new OperationLabelInfoModel();
  constructor(
    public dialogRef: MatDialogRef<ShippyproErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Operation,
    private dialog: MatDialog,
    private operationService: OperationsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.error = this.data.data?.shippyProErrorMessage as string;
    this.operationId = this.data.data?.operationId as string;

    this.labelModelForm = new FormGroup({
      customerCode: new FormControl(this.data.data.customerCode),
      addressName: new FormControl(this.data.data.addressName),
      customerAddress: new FormControl(this.data.data.customerAddress),
      addressLine2: new FormControl(this.data.data.addressLine2),
      addressLine3: new FormControl(this.data.data.addressLine3),
      completeAddress: new FormControl(this.data.data.completeAddress),
      customerLocation: new FormControl(this.data.data.customerLocation),
      customerCountry: new FormControl(this.data.data.customerCountry),
      county: new FormControl(this.data.data.county),
      customerZipCode: new FormControl(this.data.data.customerZipCode),
      state: new FormControl(this.data.data.state),
      district: new FormControl(this.data.data.district),
      addressPhone: new FormControl(this.data.data.addressPhone),
      addressType: new FormControl(this.data.data.addressType),
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  removeError() {
    this.operationLabelInfoModel.operationId = this.operationId;
    this.operationLabelInfoModel.customerCode = this.labelModelForm.value.customerCode;
    this.operationLabelInfoModel.addressName = this.labelModelForm.value.addressName;
    this.operationLabelInfoModel.customerAddress = this.labelModelForm.value.customerAddress;
    this.operationLabelInfoModel.addressLine2 = this.labelModelForm.value.addressLine2;
    this.operationLabelInfoModel.addressLine3 = this.labelModelForm.value.addressLine3;
    this.operationLabelInfoModel.completeAddress = this.labelModelForm.value.completeAddress;
    this.operationLabelInfoModel.customerLocation = this.labelModelForm.value.customerLocation;
    this.operationLabelInfoModel.customerCountry = this.labelModelForm.value.customerCountry;
    this.operationLabelInfoModel.county = this.labelModelForm.value.county;
    this.operationLabelInfoModel.customerZipCode = this.labelModelForm.value.customerZipCode;
    this.operationLabelInfoModel.state = this.labelModelForm.value.state;
    this.operationLabelInfoModel.district = this.labelModelForm.value.district;
    this.operationLabelInfoModel.addressPhone = this.labelModelForm.value.addressPhone;
    this.operationLabelInfoModel.addressType = this.labelModelForm.value.addressType;

    this.dialog
      .open(GenericConfirmationModalComponent, { data: `This will remove the ShippyPro error from operation ${this.operationId}.` })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.operationService.removeShippyProError(this.operationLabelInfoModel).subscribe(() => {
            this.snackBar.open('Error has been removed from the operation')._dismissAfter(2000);
            this.dialogRef.close();
          });
        }
      });
  }
}
