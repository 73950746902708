import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isNilty } from '@core/utils.service';
import { MatChipsData } from '@models/mat-chips-data-model';
import { PurchaseInvoiceFilters } from '@models/purchase-invoice-model';
import { Supplier } from '@models/supplier-model';
import { CommonsService } from '@shared/commons.service';
import { Subject } from 'rxjs';
import { SuppliersService } from '../../../configuration/suppliers/suppliers.service';
import { PurchaseInvoiceService } from '../purchase-invoice.service';

@Component({
  selector: 'app-purchase-invoice-filters',
  templateUrl: './purchase-invoice-filters.component.html',
  styleUrls: ['./purchase-invoice-filters.component.css'],
})
export class PurchaseInvoiceFiltersComponent implements OnInit {
  @Input() purchaseInvoiceFilters = true;
  @Input() purchaseInvoiceItemFilters = true;
  @Input() filters = new PurchaseInvoiceFilters();
  @Output() onFilter = new Subject<PurchaseInvoiceFilters>();

  filterForm: FormGroup;
  public openFilter = false;
  filtersMap: MatChipsData[] = [];
  suppliers: Supplier[] = [];
  clients: string[] = [];
  spoStatuses: string[] = [];

  constructor(
    private commonService: CommonsService,
    private suppliersService: SuppliersService,
    private purchaseInvoiceService: PurchaseInvoiceService
  ) {}

  ngOnInit() {
    this.filterForm = new FormGroup({
      purchaseInvoiceNumber: new FormControl(),
      supplier: new FormControl(),
    });

    if (!isNilty(this.filters)) {
      this.filterForm.patchValue(this.filters);
    }

    this.suppliersService.getSuppliers().subscribe((resp) => {
      this.suppliers = resp;
    });
  }

  onSubmitFilter() {
    this.onFilter.next(this.setPurchaseInvoiceFilter());
  }

  resetFilters() {
    this.filterForm.reset();
    this.onFilter.next(this.setPurchaseInvoiceFilter());
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  setPurchaseInvoiceFilter(): PurchaseInvoiceFilters {
    const filters = new PurchaseInvoiceFilters();
    filters.purchaseInvoiceNumber = this.filterForm.value.purchaseInvoiceNumber;
    filters.supplier = this.filterForm.value.supplier;
    return filters;
  }
}
