import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperationRow } from '@models/operation-row-model';

@Component({
  selector: 'app-error-messages-modal',
  templateUrl: './error-messages-modal.component.html',
  styleUrls: ['./error-messages-modal.component.css'],
})
export class ErrorMessagesModalComponent implements OnInit {
  errorString: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OperationRow) {}

  ngOnInit() {
    this.errorString = this.data.messages.replace('\n\n', '<br>');
  }
}
