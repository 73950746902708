<legend class="small-label">FTP configuration</legend>

<div class="col-md-3" *ngFor="let p of parametersList">
  <mat-form-field>
    <input matInput [(ngModel)]="params[p.key]" (ngModelChange)="isValidForm()" [placeholder]="p.label" [required]="p.required" />
  </mat-form-field>
</div>

<ng-container *ngIf="isReceiver">
  <div class="col-md-3" *ngFor="let p of onlyReceiverParameters">
    <mat-form-field>
      <input matInput [(ngModel)]="params[p.key]" (ngModelChange)="isValidForm()" [placeholder]="p.label" [required]="p.required" />
    </mat-form-field>
  </div>
</ng-container>
