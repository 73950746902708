export class Field {
  name: string;
  type: string;
  label: string;
  class?: string;
}

export class Header {
  headerName: string;
  fields: Field[];
}

export class OperationConfiguration {
  type: string;
  headerFields: Header[];
  accordionFields: Header[];
  rowFields: Field[];
}
