import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RouteConfiguration')
export class RouteConfiguration {
  @JsonProperty('endpointType', String, true) endpointType: string = undefined;
  @JsonProperty('endpointParams', Any, true) endpointParams: any = undefined;
  @JsonProperty('immediate', Boolean, true) immediate = true;
  @JsonProperty('cronExpression', String, true) cronExpression: string = undefined;
  @JsonProperty('spoSendingTemplate', String, true) spoSendingTemplate: string = undefined;
  @JsonProperty('spoConfirmTemplate', String, true) spoConfirmTemplate: string = undefined;
  @JsonProperty('spoItemCancelOrderTemplate', String, true) spoItemCancelOrderTemplate: string = undefined;
  @JsonProperty('spoItemTroubleOrderTemplate', String, true) spoItemTroubleOrderTemplate: string = undefined;
  @JsonProperty('minimumThreshold', Number, true) minimumThreshold: number = undefined;
  @JsonProperty('latestShippingBuffer', Number, true) latestShippingBuffer: number = undefined;
}
