import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { isNilty } from '@core/utils.service';
import { Warehouse } from '@models/warehouse-model';
import { Subscription } from 'rxjs';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog-component';
import { WarehouseEditModalComponent } from './warehouse-edit-modal/warehouse-edit-modal.component';
import { WarehousesService } from './warehouses.service';

@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.css'],
})
export class WarehousesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<Warehouse>;
  subscription: Subscription;
  displayedColumns = ['id', 'code', 'name', 'logisticPartner', 'owner', 'createdAt', 'edit', 'delete'];
  constructor(private warehousesService: WarehousesService, private dialog: MatDialog) {}

  ngOnInit() {
    this.loadWarehouses();
  }

  loadWarehouses() {
    this.warehousesService.getWarehouses().subscribe((warehouses: Warehouse[]) => {
      this.dataSource = new MatTableDataSource(warehouses);
      this.dataSource.paginator = this.paginator;
    });
  }

  edit(warehouse: Warehouse) {
    this.subscription = this.dialog
      .open(WarehouseEditModalComponent, {
        data: {
          title: 'Edit warehouse ' + warehouse.code,
          object: warehouse,
        },
      })
      .afterClosed()
      .subscribe((resp: Warehouse) => {
        if (!isNilty(resp)) {
          this.saveWarehouse(resp);
          this.subscription.unsubscribe();
        }
      });
  }

  new() {
    const warehouse = new Warehouse();
    this.subscription = this.dialog
      .open(WarehouseEditModalComponent, {
        data: {
          title: 'New warehouse',
          object: warehouse,
        },
      })
      .afterClosed()
      .subscribe((resp: Warehouse) => {
        if (!isNilty(resp)) {
          this.saveWarehouse(resp);
          this.subscription.unsubscribe();
        }
      });
  }

  saveWarehouse(warehouse: Warehouse) {
    this.warehousesService.saveWarehouse(warehouse).subscribe(() => {
      this.loadWarehouses();
    });
  }

  delete(warehouse: Warehouse) {
    this.subscription = this.dialog
      .open(DeleteDialogComponent, {
        data: 'Deleting warehouse ' + warehouse.code,
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.warehousesService.delete(warehouse.id).subscribe(() => {
            this.loadWarehouses();
            this.subscription.unsubscribe();
          });
        }
      });
  }
}
