import { isNilty } from '@core/utils.service';
import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { DateConverter, fromDateToFiltersString } from './date-converter';
import { MatChipsData } from './mat-chips-data-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('StockMovement')
export class StockMovement extends BaseObject {
  @JsonProperty('operationType', String, true) operationType: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('operationId', String, true) operationId: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('totalPurchasePriceAmount', Number, true) totalPurchasePriceAmount: number = undefined;
  @JsonProperty('purchasePriceCurrency', String, true) purchasePriceCurrency: string = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('destinationCountry', String, true) destinationCountry: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('customerOrderId', String, true) customerOrderId: string = undefined;
}

@JsonObject('StockMovementResponse')
export class StockMovementResponse {
  @JsonProperty('header', Map, true) header: Map<string, number> = undefined;
  @JsonProperty('result', [StockMovement], true) StockMovement: StockMovement[] = undefined;
}

@JsonObject('StockMovementFilters')
export class StockMovementFilters extends PaginationAndSorting {
  @JsonProperty('operationType', String, true) operationType: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('operationId', String, true) operationId: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('dateFrom', DateConverter, true) dateFrom: Date = undefined;
  @JsonProperty('dateTo', DateConverter, true) dateTo: Date = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('customerOrderId', String, true) customerOrderId: string = undefined;
}

export const stockMovementFiltersMap = (
  operationType?: string,
  orderType?: string,
  operationId?: string,
  supplierCode?: string,
  sku?: string,
  dateFrom?: Date,
  dateTo?: Date,
  stockLocationCode?: string,
  customerOrderId?: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(operationType)) {
    map.push(new MatChipsData('Operation Type', operationType));
  }
  if (!isNilty(orderType)) {
    map.push(new MatChipsData('Order Type', orderType));
  }
  if (!isNilty(operationId)) {
    map.push(new MatChipsData('Operation Id', operationId));
  }
  if (!isNilty(supplierCode)) {
    map.push(new MatChipsData('Supplier Code', supplierCode));
  }
  if (!isNilty(sku)) {
    map.push(new MatChipsData('Sku', sku));
  }
  if (dateFrom ?? false) {
    map.push(new MatChipsData('Date From', fromDateToFiltersString(dateFrom)));
  }
  if (dateTo ?? false) {
    map.push(new MatChipsData('Date To', fromDateToFiltersString(dateTo)));
  }
  if (!isNilty(stockLocationCode)) {
    map.push(new MatChipsData('Stock Location', stockLocationCode));
  }
  if (!isNilty(customerOrderId)) {
    map.push(new MatChipsData('Customer Order Ids', customerOrderId));
  }
  return map;
};
