<mat-toolbar>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <button class="btn-custom" [routerLink]="['new']">New Configuration</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container-fluid margin-bottom-60">
  <div class="row padding-top-10">
    <div class="col-md-3">
      <mat-form-field style="min-width: 100%">
        <input matInput [(ngModel)]="filters.supplierString" placeholder="Search Supplier Name / Code" />
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field>
        <mat-select [(ngModel)]="filters.client" placeholder="Client">
          <mat-option [value]="undefined">None</mat-option>
          <mat-option *ngFor="let c of clients" [value]="c">{{ c }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 pt-3">
      <button (click)="search()">
        <i class="fa fa-search me-1" aria-hidden="true"></i>
        Search
      </button>
      <button class="btn-icon ms-2" (click)="reset()"><i class="fa fa-undo" aria-hidden="true"></i></button>
    </div>

    <div class="col-md-5">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="supplierCode">
      <th mat-header-cell *matHeaderCellDef>Supplier Code</th>
      <td mat-cell *matCellDef="let sc">{{ sc.supplier.code }}</td>
    </ng-container>

    <ng-container matColumnDef="supplierName">
      <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
      <td mat-cell *matCellDef="let sc">{{ sc.supplier.name }}</td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Client</th>
      <td mat-cell *matCellDef="let sc">{{ sc.client }}</td>
    </ng-container>

    <ng-container matColumnDef="senderRouteType">
      <th mat-header-cell *matHeaderCellDef>Sender route type</th>
      <td mat-cell *matCellDef="let sc">{{ sc.senderRoute.endpointType }}</td>
    </ng-container>

    <ng-container matColumnDef="receiverRouteType">
      <th mat-header-cell *matHeaderCellDef>Receiver route type</th>
      <td mat-cell *matCellDef="let sc">{{ sc.receiverRoute?.endpointType }}</td>
    </ng-container>

    <ng-container matColumnDef="cancellationRoute">
      <th mat-header-cell *matHeaderCellDef>Cancel order</th>
      <td mat-cell *matCellDef="let sc">{{ sc.cancellationRouteActive ? 'on' : sc.cancellationRoute !== null ? 'off' : '' }}</td>
    </ng-container>

    <ng-container matColumnDef="troubleRoute">
      <th mat-header-cell *matHeaderCellDef>Trouble order</th>
      <td mat-cell *matCellDef="let sc">{{ sc.troubleRouteActive ? 'on' : sc.troubleRoute !== null ? 'off' : '' }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell class="button-column" *matHeaderCellDef></th>
      <td mat-cell class="button-column" *matCellDef="let sc">
        <button class="btn-sm" [routerLink]="['edit', sc.id]">
          <i class="fa fa-pencil-square-o me-1" aria-hidden="true"></i>
          Edit
        </button>
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="sticky-top"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>
