import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { EmailTemplates } from '@models/supplier-configuration/email-templates-model';
import { EndpointType } from '@models/supplier-configuration/endpoint-type-model';
import { Field } from '@models/supplier-configuration/field-model';
import { MessageTypesOutput } from '@models/supplier-configuration/message-types-model';
import { SupplierConfigurationFilters } from '@models/supplier-configuration/supplier-configuration-filters-model';
import { SupplierConfiguration } from '@models/supplier-configuration/supplier-configuration-model';
import { GenericCache, cachedGet } from '@shared/service-cached';
import { GenericArrayCache, cachedArrayGet } from '@shared/service-cached-array';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';

@Injectable()
export class SuppliersConfigurationsService {
  resultsNumber = new Subject<number>();

  private clientsCache = new GenericArrayCache<string>(this.http, this.converter);
  private endpointTypesCache = new GenericArrayCache<EndpointType>(this.http, this.converter);
  private messageTypesCache = new GenericCache<MessageTypesOutput>(this.http, this.converter, MessageTypesOutput);
  private emailTemplatesCache = new GenericCache<EmailTemplates>(this.http, this.converter, EmailTemplates);
  private senderColumnTypesCache = new GenericArrayCache<string>(this.http, this.converter);

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getClients(): Observable<string[]> {
    return cachedArrayGet<string>(this.clientsCache, this.environmentService.getRestEndpoint('findJimboClients'));
  }

  getMessageTypes(): Observable<MessageTypesOutput> {
    return cachedGet<MessageTypesOutput>(
      this.messageTypesCache,
      this.environmentService.getRestEndpoint('suppliersConfigurationsMessageTypes')
    );
  }

  getEndpointTypes(): Observable<EndpointType[]> {
    return cachedArrayGet<EndpointType>(
      this.endpointTypesCache,
      this.environmentService.getRestEndpoint('suppliersConfigurationsEndpointTypes')
    );
  }

  getEmailTemplates(): Observable<EmailTemplates> {
    return cachedGet<EmailTemplates>(
      this.emailTemplatesCache,
      this.environmentService.getRestEndpoint('suppliersConfigurationsEmailTemplates')
    );
  }

  getSuppliersConfigurations(filters: SupplierConfigurationFilters): Observable<SupplierConfiguration[]> {
    const path = this.environmentService.getRestEndpoint('findSuppliersConfigurations');
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: SupplierConfiguration[]) => resp.map((it) => this.converter.fromJSONtoObj(it, SupplierConfiguration)))
    );
  }

  getConfiguration(id: string): Observable<SupplierConfiguration> {
    const path = this.environmentService.getRestEndpoint('suppliersConfigurations') + '/' + id;
    return this.http.get(path).pipe(map((resp: SupplierConfiguration) => this.converter.fromJSONtoObj(resp, SupplierConfiguration)));
  }

  save(config: SupplierConfiguration): Observable<any> {
    const body = this.converter.fromObjtoJSON(config);
    return this.http.post(this.environmentService.getRestEndpoint('saveSupplierConfiguration'), body);
  }

  getFields(routeType: string): Observable<Field[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('suppliersConfigurationsFields') + '/' + routeType)
      .pipe(map((resp: Field) => this.converter.fromJSONtoObj(resp, Field)));
  }

  getSenderColumnTypes(): Observable<string[]> {
    return cachedArrayGet<string>(
      this.senderColumnTypesCache,
      this.environmentService.getRestEndpoint('suppliersConfigurationsSenderColumnTypes')
    );
  }
}
