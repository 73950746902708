import { Component, Input, OnInit } from '@angular/core';
import { Field } from '@models/supplier-configuration/field-model';
import { isNilty } from '@core/utils.service';
import { SenderColumnDefinition } from '@models/sender-column-definition-model';
import { SuppliersConfigurationsService } from '../../../../suppliers-configurations.service';

@Component({
  selector: 'app-sender-positions-edit',
  templateUrl: './sender-positions-edit.component.html',
  styleUrls: ['./sender-positions-edit.component.css'],
})
export class SenderPositionsEditComponent implements OnInit {
  @Input() name: string;

  map: SenderColumnDefinition[];

  existingKeys: string[];
  allowedFields: Field[];
  availableKeys: Field[];

  columnTypes: string[];

  newColumn: SenderColumnDefinition;

  constructor(private suppliersConfigurationsService: SuppliersConfigurationsService) {}

  @Input() set _allowedFields(af: Field[]) {
    this.allowedFields = af;
    if (!isNilty(this.existingKeys)) {
      this.setAvailableKeys();
    }
  }

  @Input() set _map(m: SenderColumnDefinition[]) {
    this.map = m;
    this.existingKeys = this.map.map((item) => item.field);
    if (!isNilty(this.allowedFields)) {
      this.setAvailableKeys();
    }
  }

  ngOnInit(): void {
    this.existingKeys = this.map.map((item) => item.field);
    this.setAvailableKeys();
    this.newColumn = new SenderColumnDefinition();

    this.suppliersConfigurationsService.getSenderColumnTypes().subscribe((res) => {
      this.columnTypes = res;
    });
  }

  delete(key: string) {
    this.existingKeys.splice(
      this.existingKeys.findIndex((it) => it === key),
      1
    );
    this.map.splice(
      this.map.findIndex((it) => it.field === key),
      1
    );
    this.setAvailableKeys();
  }

  add() {
    this.map.push(this.newColumn);
    this.existingKeys.push(this.newColumn.field);
    this.newColumn = new SenderColumnDefinition();
    this.setAvailableKeys();
  }

  setAvailableKeys() {
    this.availableKeys = this.allowedFields.filter((it) => this.existingKeys.findIndex((k) => k === it.code) === -1);
  }

  setColumnField() {
    this.newColumn.label = this.allowedFields.find((it) => it.code === this.newColumn.field).label;
  }
}
