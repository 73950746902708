<h1 mat-dialog-title style="color: red; text-align: center; z-index: 100000">Warnings for product {{ data.data['sku'] }}</h1>

<div mat-dialog-content [innerHTML]="errorString" class="margin-bottom-10">
  <!--{{ errorString }}-->
</div>

<div mat-dialog-actions>
  <span style="width: 100%; text-align: center">
    <button mat-button cdkFocusInitial [mat-dialog-close]="null">Ok</button>
  </span>
</div>
