<mat-toolbar id="header" class="bg-white border-5 border-bottom border-secondary header-position mat-toolbar-single-row px-0">
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col">
        <a (click)="refreshHomepage()" style="cursor: pointer">
          <img src="https://brandon-frontend-assets.s3.eu-west-1.amazonaws.com/bridget/{{ logoUrl }}" height="30" />
        </a>
      </div>

      <div class="col">
        <div class="text-end">
          <div class="header-menu d-inline-block">
            <button class="btn-header no-focus" routerLink="/items" routerLinkActive="active">Items</button>
            <button class="btn-header no-focus" routerLink="/operations" routerLinkActive="active">Operations</button>
            <button class="btn-header no-focus" routerLink="/stock-balance" routerLinkActive="active">Stock</button>
            <button *ngIf="hasJimboReaderPermission | async" class="btn-header no-focus" routerLink="/spos" routerLinkActive="active">
              SPO
            </button>
            <button class="btn-header no-focus" routerLink="/shipments" routerLinkActive="active">Shipments</button>
            <button class="btn-header no-focus" routerLink="/invoices" routerLinkActive="active">Invoices</button>
            <button
              *ngIf="(hasJimboSupplierConfigurationPermission | async) || (hasBridgetAdministrationPermission | async)"
              class="btn-header no-focus"
              routerLink="/configuration"
              routerLinkActive="active"
            >
              Configuration
            </button>

            <label class="email-custom"><i class="fa fa-user padding-right-5" aria-hidden="true"></i>{{ currentMail }}</label>
          </div>
          <a (click)="logout()">
            <button>
              <i class="fa fa-sign-out" aria-hidden="true"></i>
              Logout
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
