import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SupplierConfigurationFilters } from '@models/supplier-configuration/supplier-configuration-filters-model';
import { SupplierConfiguration } from '@models/supplier-configuration/supplier-configuration-model';
import { Observable } from 'rxjs';
import { SuppliersConfigurationsService } from '../suppliers-configurations.service';

@Component({
  selector: 'app-suppliers-configurations-list',
  templateUrl: './suppliers-configurations-list.component.html',
  styleUrls: ['./suppliers-configurations-list.component.css'],
})
export class SuppliersConfigurationsListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<SupplierConfiguration>;

  displayedColumns = [
    'client',
    'supplierCode',
    'supplierName',
    'senderRouteType',
    'receiverRouteType',
    'cancellationRoute',
    'troubleRoute',
    'edit',
  ];

  filters = new SupplierConfigurationFilters();
  clients: string[];
  resultsNumber: Observable<number>;

  constructor(private suppConfigurationService: SuppliersConfigurationsService) {}

  ngOnInit() {
    this.resultsNumber = this.suppConfigurationService.resultsNumber;
    this.dataSource = new MatTableDataSource([]);
    this.loadClients();
    this.loadConfigurations();
  }

  loadClients() {
    this.suppConfigurationService.getClients().subscribe((resp: string[]) => (this.clients = resp));
  }

  loadConfigurations() {
    this.suppConfigurationService.getSuppliersConfigurations(this.filters).subscribe((sc: SupplierConfiguration[]) => {
      this.dataSource.data = sc;
    });
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageIndex = this.paginator.pageIndex;
    this.loadConfigurations();
  }

  search() {
    this.filters.pageIndex = 0;
    this.loadConfigurations();
  }

  reset() {
    this.filters = new SupplierConfigurationFilters();
    this.loadConfigurations();
  }
}
