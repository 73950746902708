import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { Item, ItemFilters } from '@models/item-model';
import { Subject } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';

@Injectable()
export class ItemsService {
  itemsFilters: ItemFilters;

  resultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getItemsByFilters(): Observable<Item[]> {
    const body = this.converter.fromObjtoJSON(this.itemsFilters);
    return this.http
      .post(this.environmentService.getRestEndpoint('items'), body, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        })
      )
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return resp.map((it) => this.converter.fromJSONtoObj(it, Item));
          } else {
            return [];
          }
        })
      );
  }
}
