import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Warehouse } from '@models/warehouse-model';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-warehouse-edit-modal',
  templateUrl: './warehouse-edit-modal.component.html',
  styleUrls: ['./warehouse-edit-modal.component.css'],
})
export class WarehouseEditModalComponent implements OnInit {
  partners: string[];
  selectedPartner: string;

  constructor(
    public dialogRef: MatDialogRef<WarehouseEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; object: Warehouse },
    private commonService: CommonsService
  ) {}

  ngOnInit() {
    this.commonService.getBridgetEnums().subscribe((r) => (this.partners = r.logisticPartners));
  }

  save() {
    this.dialogRef.close(this.data.object);
  }

  partnerSelected(partner: string) {
    this.data.object.logisticPartner = partner;
  }
}
