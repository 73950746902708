import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';
import { isNilty } from '@core/utils.service';
import { Carrier } from '@models/carrier-model';
import { CarriersConfigurationGet, CarriersConfigurationSave } from '@models/carriers-configuration/carriers-configuration-model';
import { Supplier } from '@models/supplier-model';
import { CarriersConfigurationsService } from '../carriers-configurations.service';
import { CommonsService } from '@shared/commons.service';
import { BridgetEnums } from '@models/bridget-enums-model';

@Component({
  selector: 'app-carriers-configurations-edit',
  templateUrl: './carriers-configurations-edit.component.html',
  styleUrls: ['./carriers-configurations-edit.component.css'],
})
export class CarriersConfigurationsEditComponent implements OnInit {
  editMode = false;

  currentId: string;
  currentConfiguration: CarriersConfigurationGet;

  suppliers: Supplier[];
  carriers: Carrier[];
  contracts: string[];
  orderTypes: string[];

  constructor(
    private route: ActivatedRoute,
    private carriersConfigurationsService: CarriersConfigurationsService,
    private suppliersService: SuppliersService,
    private location: Location,
    private commonService: CommonsService
  ) {}

  ngOnInit() {
    this.loadCarriers();
    this.loadSuppliers();

    this.commonService.getBridgetEnums().subscribe((r: BridgetEnums) => {
      this.orderTypes = r.orderTypes;
    });

    this.route.params.subscribe((params: Params) => {
      this.currentId = params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.getConfiguration();
      } else {
        this.currentConfiguration = new CarriersConfigurationGet();
      }
    });
  }

  getConfiguration() {
    this.carriersConfigurationsService.getConfiguration(this.currentId).subscribe((it: CarriersConfigurationGet) => {
      this.currentConfiguration = it;
      this.currentConfiguration.carrier = this.carriers.find((carrier) => carrier.name === this.currentConfiguration.carrier.name);
      this.onCarrierSelected(this.currentConfiguration.carrier, true);
    });
  }

  loadCarriers() {
    this.carriersConfigurationsService.getCarriers().subscribe((resp: Carrier[]) => (this.carriers = resp));
  }

  loadSuppliers() {
    this.suppliersService.getSuppliers().subscribe((resp: Supplier[]) => (this.suppliers = resp));
  }

  save() {
    const save = new CarriersConfigurationSave(this.currentConfiguration);
    this.carriersConfigurationsService.save(save).subscribe(() => this.goBack());
  }

  goBack() {
    this.location.back();
  }

  onCarrierSelected(actualCarrier: Carrier, onLoad = false) {
    const selectedCarrier = this.carriers.find((carrier) => carrier.name === actualCarrier.name);
    this.contracts = selectedCarrier && !isNilty(selectedCarrier.contracts) ? selectedCarrier.contracts : null;
    if (!onLoad) this.currentConfiguration.contract = null;
  }
}
