import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Carrier')
export class Carrier {
  @JsonProperty('carrierName', String, true) carrierName: string = undefined;
  @JsonProperty('alternativeCodes', [String], true) alternativeCodes: string[] = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('contracts', [String], true) contracts: string[] = undefined;
}
