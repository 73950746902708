<app-purchase-invoice-filters
  [purchaseInvoiceItemFilters]="false"
  [filters]="initialFilters"
  (onFilter)="applyFilters($event)"
></app-purchase-invoice-filters>

<div class="container-fluid pt-4">
  <h2 class="title-custom">Invoices</h2>

  <div class="row pt-3">
    <div class="col-md-12">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="supplierName">
        <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
        <td mat-cell *matCellDef="let pi">
          {{ pi.supplier?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="purchaseInvoiceNumber" sticky>
        <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
        <td mat-cell *matCellDef="let pi">
          <button (click)="openDetail(pi)">{{ pi.purchaseInvoiceNumber }}</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="purchaseInvoiceDate">
        <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
        <td mat-cell *matCellDef="let pi">
          {{ pi.purchaseInvoiceDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totalQuantity">Total Quantity</th>
        <td mat-cell *matCellDef="let pi">
          {{ pi.totalQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totalPrice">Total Price</th>
        <td mat-cell *matCellDef="let pi">
          {{ pi.totalPrice }}
        </td>
      </ng-container>

      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="currency">Currency</th>
        <td mat-cell *matCellDef="let pi">
          {{ pi.currency }}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplierCode">
        <th mat-header-cell *matHeaderCellDef>Supplier Code</th>
        <td mat-cell *matCellDef="let pi">
          {{ pi.supplier?.code }}
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let op; columns: displayedColumns"></mat-row>
    </table>
  </div>
</div>
