<mat-form-field [class]="cssClass">
  <mat-label>{{ label }}</mat-label>

  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let option of selectedOptions" (removed)="remove(option)">
      {{ option.name }}
      <button matChipRemove [attr.aria-label]="'remove ' + option.name">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>

  <input
    type="text"
    placeholder="Pick one"
    matInput
    [matAutocomplete]="auto"
    #filterInput
    [disabled]="disabled"
    [matChipInputFor]="chipGrid"
  />

  <mat-autocomplete
    [autoActiveFirstOption]="autoActiveFirstOption"
    #auto="matAutocomplete"
    (optionSelected)="selectionChange($event)"
    #autocomplete
  >
    <mat-option *ngIf="allowNullOption" [value]="null">None</mat-option>
    <mat-option *ngFor="let option of filteredData | async" [value]="valueKey ? option[valueKey] : option">
      {{ displayOption(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
