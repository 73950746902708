import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { DateConverter } from './date-converter';

@JsonObject('Operation')
export class Operation extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('documentNumber', String, true) documentNumber: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('client', String, true) client: string = undefined;
  @JsonProperty('data', Any, true) data: any = undefined;
  @JsonProperty('operationFeed', Any, true) operationFeed: any = undefined;
  @JsonProperty('confirmationFeed', Any, true) confirmationFeed: any = undefined;
  @JsonProperty('consignmentExpirationDate', DateConverter, true) consignmentExpirationDate: Date = undefined;
}

@JsonObject('OperationFilters')
export class OperationFilters extends PaginationAndSorting {
  @JsonProperty('operationType', String, true) operationType: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('client', String, true) client: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('customerOrderReference', String, true) customerOrderReference: string = undefined;
  @JsonProperty('feedId', String, true) feedId: string = undefined;
  @JsonProperty('documentNumber', String, true) documentNumber: string = undefined;
  @JsonProperty('operationId', String, true) operationId: string = undefined;
  @JsonProperty('warehouse', String, true) warehouse: string = undefined;
  @JsonProperty('carrier', String, true) carrier: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
}
