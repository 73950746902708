import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('OperationLabelInfoModel')
export class OperationLabelInfoModel {
  @JsonProperty('operationId', String, true) operationId: string = undefined;
  @JsonProperty('customerCode', String, false) customerCode: string = undefined;
  @JsonProperty('addressName', String, false) addressName: string = undefined;
  @JsonProperty('customerAddress', String, false) customerAddress: string = undefined;
  @JsonProperty('addressLine2', String, false) addressLine2: string = undefined;
  @JsonProperty('addressLine3', String, false) addressLine3: string = undefined;
  @JsonProperty('completeAddress', String, false) completeAddress: string = undefined;
  @JsonProperty('customerLocation', String, false) customerLocation: string = undefined;
  @JsonProperty('customerCountry', String, false) customerCountry: string = undefined;
  @JsonProperty('county', String, false) county: string = undefined;
  @JsonProperty('customerZipCode', String, false) customerZipCode: string = undefined;
  @JsonProperty('state', String, false) state: string = undefined;
  @JsonProperty('district', String, false) district: string = undefined;
  @JsonProperty('addressPhone', String, false) addressPhone: string = undefined;
  @JsonProperty('addressType', String, false) addressType: string = undefined;
}
