import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouteConfiguration } from '@models/supplier-configuration/route-configuration-model';
import { EndpointType } from '@models/supplier-configuration/endpoint-type-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-route-configuration-edit',
  templateUrl: './route-configuration-edit.component.html',
  styleUrls: ['./route-configuration-edit.component.css'],
})
export class RouteConfigurationEditComponent implements OnInit {
  @Input() title: string;
  @Input() sposWillBeConfirmed: boolean;
  @Input() routeConfiguration: RouteConfiguration;
  @Input() endpointTypes: EndpointType[];
  @Input() isReceiver = false;
  @Input() cancellationRouteActive = null;
  @Input() troubleRouteActive = null;
  @Output() isValidRoute = new EventEmitter<boolean>();
  @Output() changeCancellationRouteStatus = new EventEmitter<boolean>();
  @Output() changeTroubleRouteStatus = new EventEmitter<boolean>();
  private lastValidity: boolean;

  ngOnInit() {
    if (this.cancellationRouteActive != null || this.troubleRouteActive != null) {
      this.endpointTypes = this.endpointTypes.filter((it) => it.endpointType === 'email');
    }
  }

  resetParams(endpointType: string) {
    this.routeConfiguration.endpointParams = {};
    if (this.endpointTypes.find((it) => it.endpointType === endpointType).requiredParams.length === 0) {
      this.isValidRoute.emit(true);
    }
  }

  updateValidity(isRouteValid: boolean) {
    this.lastValidity = isRouteValid;
    this.isValidForm();
  }

  isValidForm() {
    let valid = this.lastValidity;
    if (!this.routeConfiguration.immediate) {
      valid = valid && !isNilty(this.routeConfiguration.cronExpression);
    }
    this.isValidRoute.emit(valid);
  }

  setCancellationRouteStatus(value: boolean) {
    this.changeCancellationRouteStatus.emit(value);
  }
  setTroubleRouteStatus(value: boolean) {
    this.changeTroubleRouteStatus.emit(value);
  }
}
