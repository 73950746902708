import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@core/authorization.service';
import { Permissions } from '@models/permissions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-configuration-menu',
  templateUrl: './configuration-menu.component.html',
  styleUrls: ['./configuration-menu.component.css'],
})
export class ConfigurationMenuComponent implements OnInit {
  hasJimboSupplierConfigurationPermission: Observable<boolean>;
  hasBridgetAdministrationPermission: Observable<boolean>;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasJimboSupplierConfigurationPermission = this.authorizationService.hasJimboPermission(Permissions.JimboSupplierConfiguration);
    this.hasBridgetAdministrationPermission = this.authorizationService.hasBridgetPermission(Permissions.BridgetAdministration);
  }
}
