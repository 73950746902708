import { JsonObject, JsonProperty } from 'json2typescript';
import { Supplier } from '../supplier-model';
import { MessageConfiguration } from './message-configuration-model';
import { RouteConfiguration } from './route-configuration-model';

@JsonObject('SupplierConfiguration')
export class SupplierConfiguration {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('splitSpoFiles', Boolean, true) splitSpoFiles = false;
  @JsonProperty('sendOnlyOrdered', Boolean, true) sendOnlyOrdered = false;
  @JsonProperty('sposWillBeConfirmed', Boolean, true) sposWillBeConfirmed = false;
  @JsonProperty('dontSendRequested', Boolean, true) dontSendRequested = false;
  @JsonProperty('updatesPricesWithSpoa', Boolean, true) updatesPricesWithSpoa = false;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('client', String, true) client: string = undefined;
  @JsonProperty('notificationReceiver', String, true) notificationReceiver: string = undefined;
  @JsonProperty('senderRoute', RouteConfiguration, true) senderRoute: RouteConfiguration = undefined;
  @JsonProperty('receiverRoute', RouteConfiguration, true) receiverRoute: RouteConfiguration = undefined;
  @JsonProperty('senderMessages', [MessageConfiguration], true) senderMessages: MessageConfiguration[] = undefined;
  @JsonProperty('receiverMessages', [MessageConfiguration], true) receiverMessages: MessageConfiguration[] = undefined;
  @JsonProperty('cancellationRoute', RouteConfiguration, true) cancellationRoute: RouteConfiguration = undefined;
  @JsonProperty('cancellationRouteActive', Boolean, true) cancellationRouteActive = false;
  @JsonProperty('troubleRoute', RouteConfiguration, true) troubleRoute: RouteConfiguration = undefined;
  @JsonProperty('troubleRouteActive', Boolean, true) troubleRouteActive = false;
  @JsonProperty('groupSpoItems', Boolean, true) groupSpoItems = false;

  constructor() {
    this.senderRoute = new RouteConfiguration();
    this.senderMessages = [];
    this.receiverMessages = [];
  }
}
