import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-error-modal',
  templateUrl: './generic-error-modal.component.html',
  styleUrls: ['./generic-error-modal.component.css'],
})
export class GenericErrorModalComponent implements OnInit {
  errorString: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}

  ngOnInit() {
    this.errorString = this.data;
  }
}
