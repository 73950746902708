<h1 mat-dialog-title class="error-title">Error!</h1>

<div mat-dialog-content>
  {{ errorString }}
</div>

<div mat-dialog-actions>
  <span class="error-actions">
    <button mat-button cdkFocusInitial [mat-dialog-close]="null">Ok</button>
  </span>
</div>
