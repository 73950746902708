import { JsonObject, JsonProperty } from 'json2typescript';
import { NameAndCode } from './name-code-model';

@JsonObject('Supplier')
export class Supplier extends NameAndCode {
  @JsonProperty('id', String, true) id: string = undefined;

  description: string;

  initDescription() {
    this.description = this.code + ' - ' + this.name;
  }
}
