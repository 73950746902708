<div class="col-md-12 margin-bottom-20">NOTE: every email will have <i>ambra-spo-notification@brandongroup.it</i> in CC by default.</div>

<legend class="small-label">Email configuration</legend>

<div class="col-md-12 padding-bottom-20">Templates</div>
<ng-container *ngIf="emailTemplates">
  <div class="col-md-6">
    <mat-form-field class="width-60-perc">
      <mat-select placeholder="Spo Sending Template" [(ngModel)]="routeConfiguration.spoSendingTemplate">
        <mat-option *ngFor="let et of emailTemplates.sendingTemplates" [value]="et.templateName">{{ et.templateName }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="btn-icon margin-left-20" (click)="openTemplate(routeConfiguration.spoSendingTemplate)" matTooltip="Show template">
      <i class="fa fa-code" aria-hidden="true"></i>
    </button>
  </div>

  <div class="col-md-6">
    <ng-container *ngIf="sposWillBeConfirmed">
      <mat-form-field class="width-60-perc">
        <mat-select placeholder="Spo Confirm Template" [(ngModel)]="routeConfiguration.spoConfirmTemplate">
          <mat-option *ngFor="let et of emailTemplates.confirmTemplates" [value]="et.templateName">{{ et.templateName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="btn-icon margin-left-20" (click)="openTemplate(routeConfiguration.spoConfirmTemplate)" matTooltip="Show template">
        <i class="fa fa-code" aria-hidden="true"></i>
      </button>
    </ng-container>
  </div>
</ng-container>

<div class="col-md-12 padding-top-10">Receivers</div>

<div class="col-md-12">
  <mat-form-field>
    <mat-chip-grid #receiversList aria-label="New email...">
      <mat-chip-row *ngFor="let r of receivers" [removable]="true" (removed)="remove(r, receivers)">
        {{ r }}
        <button matChipRemove [attr.aria-label]="'remove ' + r">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="New email..."
        [formControl]="receiverInput"
        [matChipInputFor]="receiversList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, receivers, receiverInput)"
      />
    </mat-chip-grid>
  </mat-form-field>
</div>

<div class="col-md-12">CC Receivers</div>

<div class="col-md-12">
  <mat-form-field>
    <mat-chip-grid #ccList aria-label="New email...">
      <mat-chip-row *ngFor="let r of ccReceivers" [removable]="true" (removed)="remove(r, ccReceivers)">
        {{ r }}
        <button matChipRemove [attr.aria-label]="'remove ' + r">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="New email..."
        [formControl]="ccInput"
        [matChipInputFor]="ccList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, ccReceivers, ccInput)"
      />
    </mat-chip-grid>
  </mat-form-field>
</div>

<div class="col-md-12">Reply To</div>

<div class="col-md-12">
  <mat-form-field class="reply-to">
    <input matInput type="email" [formControl]="replyTo" (ngModelChange)="updateParamStrings()" />
  </mat-form-field>
</div>
