import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pubeasy-params-form',
  templateUrl: './pubeasy-params-form.component.html',
  styleUrls: ['./pubeasy-params-form.component.css'],
})
export class PubeasyParamsFormComponent implements OnInit {
  @Input() params: any;

  @Output() isValidRoute = new EventEmitter<boolean>();

  parametersList = [
    { key: 'affiliateId', label: 'Affiliate Id' },
    { key: 'pubeasyCredentialsName', label: 'pubeasy credentials name' },
  ];

  ngOnInit() {
    this.isValidRoute.next(true);
  }

  isValidForm() {
    // Here just for coherence with other forms
    this.isValidRoute.next(true);
  }
}
