import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { DateConverter, fromDateToFiltersString } from './date-converter';
import { MatChipsData } from './mat-chips-data-model';
import { isNilty } from '@core/utils.service';

@JsonObject('ProductAgingDetail')
export class ProductAgingDetail {
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('operationId', String, true) operationId: string = undefined;
}

@JsonObject('ProductAgingInfo')
export class ProductAgingInfo extends BaseObject {
  @JsonProperty('incomesWithoutExit', [ProductAgingDetail], true) incomesWithoutExit: ProductAgingDetail[] = undefined;
  @JsonProperty('lastExit', ProductAgingDetail, true) lastExit: ProductAgingDetail = undefined;
}

@JsonObject('ProductAging')
export class ProductAging extends BaseObject {
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('incomesWithoutExit', [ProductAgingDetail], true) incomesWithoutExit: ProductAgingDetail[] = undefined;
  @JsonProperty('lastExit', ProductAgingDetail, true) lastExit: ProductAgingDetail = undefined;
  @JsonProperty('totalResidualQuantity', Number, true) totalResidualQuantity: number = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
}

@JsonObject('ProductAgingFilters')
export class ProductAgingFilters extends PaginationAndSorting {
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('dateFrom', DateConverter, true) dateFrom: Date = undefined;
  @JsonProperty('dateTo', DateConverter, true) dateTo: Date = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('operationId', String, true) operationId: string = undefined;
}

export const productAgingFiltersMap = (
  sku?: string,
  logisticPartner?: string,
  dateFrom?: Date,
  dateTo?: Date,
  stockLocationCode?: string,
  operationId?: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(sku)) {
    map.push(new MatChipsData('Sku', sku));
  }
  if (!isNilty(logisticPartner)) {
    map.push(new MatChipsData('Logistic Partner', logisticPartner));
  }
  if (dateFrom ?? false) {
    map.push(new MatChipsData('Date From', fromDateToFiltersString(dateFrom)));
  }
  if (dateTo ?? false) {
    map.push(new MatChipsData('Date To', fromDateToFiltersString(dateTo)));
  }
  if (stockLocationCode ?? false) {
    map.push(new MatChipsData('Stock Location Code', stockLocationCode));
  }
  if (operationId ?? false) {
    map.push(new MatChipsData('Operation Id', operationId));
  }
  return map;
};
