import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Spo } from './spo-model';

@JsonObject('SpoItem')
export class SpoItem extends BaseObject {
  @JsonProperty('order', Spo, true) order: Spo = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('requestedQuantity', Number, true) requestedQuantity: number = undefined;
  @JsonProperty('requestedValue', Number, true) requestedValue: number = undefined;
  @JsonProperty('confirmedQuantity', Number, true) confirmedQuantity: number = undefined;
  @JsonProperty('confirmedValue', Number, true) confirmedValue: number = undefined;
  @JsonProperty('orderedQuantity', Number, true) orderedQuantity: number = undefined;
  @JsonProperty('orderedValue', Number, true) orderedValue: number = undefined;
  @JsonProperty('price', Number, true) price: number = undefined;
  @JsonProperty('altEan', String, true) altEan: string = undefined;
  @JsonProperty('clientOrderItemId', String, true) clientOrderItemId: string = undefined;
  @JsonProperty('supplierOrderItemId', String, true) supplierOrderItemId: string = undefined;
  @JsonProperty('supplierOrderItemStatus', String, true) supplierOrderItemStatus: string = undefined;
}
