import { isNilty } from '@core/utils.service';
import { Carrier } from '@models/carrier-model';
import { MatChipsData } from '@models/mat-chips-data-model';
import { PaginationAndSorting } from '@models/pagination-and-sorting-model';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CarriersConfiguration')
export class CarriersConfiguration {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('originCountryCode', String, true) originCountryCode: string = undefined;
  @JsonProperty('destinationCountryCode', String, true) destinationCountryCode: string = undefined;
  @JsonProperty('destinationZipCodePrefix', String, true) destinationZipCodePrefix: string = undefined;
  @JsonProperty('contract', String, true) contract: string[] = undefined;
  @JsonProperty('weightKgFrom', Number, true) weightKgFrom: number = undefined;
  @JsonProperty('weightKgTo', Number, true) weightKgTo: number = undefined;
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('storeGroupCode', String, true) storeGroupCode: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
}

@JsonObject('CarriersConfigurationGet')
export class CarriersConfigurationGet extends CarriersConfiguration {
  @JsonProperty('carrier', Carrier, true) carrier: Carrier = undefined;
}

@JsonObject('CarriersConfigurationSave')
export class CarriersConfigurationSave extends CarriersConfiguration {
  @JsonProperty('carrier', String, true) carrier: string = undefined;

  constructor(conf?: CarriersConfigurationGet) {
    super();
    if (isNilty(conf)) return;

    this.id = conf.id;
    this.originCountryCode = conf.originCountryCode;
    this.destinationCountryCode = conf.destinationCountryCode;
    this.destinationZipCodePrefix = conf.destinationZipCodePrefix;
    this.contract = conf.contract;
    this.weightKgFrom = conf.weightKgFrom;
    this.weightKgTo = conf.weightKgTo;
    this.storeCode = conf.storeCode;
    this.storeGroupCode = conf.storeGroupCode;
    this.supplierCode = conf.supplierCode;
    this.carrier = conf.carrier.name;
    this.orderType = conf.orderType;
  }
}

@JsonObject('CarrierConfigsFilters')
export class CarrierConfigsFilters extends PaginationAndSorting {
  @JsonProperty('carrier', String, true) carrier: string = undefined;
  @JsonProperty('originCountryCode', String, true) originCountryCode: string = undefined;
  @JsonProperty('destinationCountryCode', String, true) destinationCountryCode: string = undefined;
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('storeGroup', String, true) storeGroup: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
}

export const CarrierConfigsFiltersMap = (
  carrier: string,
  destinationCountryCode: string,
  storeCode: string,
  supplierCode: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(carrier)) {
    map.push(new MatChipsData('Carrier', carrier));
  }
  if (!isNilty(destinationCountryCode)) {
    map.push(new MatChipsData('Destination', destinationCountryCode));
  }
  if (!isNilty(storeCode)) {
    map.push(new MatChipsData('Store Code', storeCode));
  }
  if (!isNilty(supplierCode)) {
    map.push(new MatChipsData('Supplier Code', supplierCode));
  }

  return map;
};
