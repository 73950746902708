import { Component, OnInit, Output } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { ItemFilters } from '@models/item-model';
import { Subject } from 'rxjs';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-items-list-filters',
  templateUrl: './items-list-filters.component.html',
  styleUrls: ['./items-list-filters.component.css'],
})
export class ItemsListFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<void>();

  public openFilter = false;
  filters: ItemFilters;
  filtersMap = [];

  constructor(private itemService: ItemsService) {}

  ngOnInit() {
    if (isNilty(this.itemService.itemsFilters)) {
      this.itemService.itemsFilters = new ItemFilters();
    }
    this.filters = this.itemService.itemsFilters;
  }

  onSubmitFilter() {
    this.updateFiltersMap();
    this.itemService.itemsFilters.pageIndex = 0;
    this.onFilter.next();
  }

  resetFilters() {
    this.filters = new ItemFilters();
    this.itemService.itemsFilters = this.filters;
    this.onFilter.next();
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    // TODO
  }
}
