import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';
import { SpoaService } from '@container/main-section/spoa/spoa.service';
import { GenericConfirmationModalComponent } from '@container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { isNil, isNilty } from '@core/utils.service';
import { BridgetEnums } from '@models/bridget-enums-model';
import { SupplierConfiguration } from '@models/supplier-configuration/supplier-configuration-model';
import { Supplier } from '@models/supplier-model';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-import-spoa-modal',
  templateUrl: './import-spoa-modal.component.html',
  styleUrls: ['./import-spoa-modal.component.css'],
})
export class ImportSpoaModalComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('spoaFile') spoaFile: ElementRef;

  currentFileUpload: File;
  completedSteps = [false, false, false];
  lastStep = false;
  hasTracking = false;

  suppliers: Supplier[];
  clients: string[];
  suppConfigs: SupplierConfiguration[];

  selectedSupplier: Supplier;
  selectedClient: string;
  selectedSuppConfig: SupplierConfiguration;

  constructor(
    public dialogRef: MatDialogRef<ImportSpoaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: void,
    private exitDialog: MatDialog,
    private commonsService: CommonsService,
    private spoaService: SpoaService,
    private suppliersService: SuppliersService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.suppliersService.getSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
    });

    this.commonsService.getBridgetEnums().subscribe((benum: BridgetEnums) => {
      this.clients = benum.clients;
    });

    this.commonsService.getSuppliersConfiguration().subscribe((supplierConfiguration: SupplierConfiguration[]) => {
      this.suppConfigs = supplierConfiguration;
    });
  }

  updatedData() {
    this.completedSteps[0] = !isNilty(this.selectedSupplier) && !isNilty(this.selectedClient);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.completedSteps[0]
      ? this.findSupplierConfig(this.selectedSupplier, this.selectedClient)
      : this.clientAllowed(this.selectedSupplier);
  }

  loadFile() {
    this.spoaFile.nativeElement.click();
  }

  fileChanged(event) {
    this.currentFileUpload = event.target ? (event.target.files ? event.target.files[0] : undefined) : undefined;
    this.completedSteps[1] = !isNil(this.currentFileUpload);
  }

  confirmFile() {
    if (this.currentFileUpload.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.snackBar.open('ERROR! Please, select an .XLSX file.', 'CLOSE')._dismissAfter(5000);
    } else {
      this.lastStep = true;
      this.stepper.next();
    }
  }

  confirmOperation() {
    const format = this.hasTracking ? 'manual_spoa_with_tracking_excel' : 'manual_spoa_excel';
    this.spoaService.importSPOA(this.currentFileUpload, this.selectedSuppConfig.id, format, this.selectedSupplier).subscribe(() => {
      this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      this.dialogRef.close();
    });
  }

  close() {
    this.exitDialog
      .open(GenericConfirmationModalComponent, {
        data: 'The import operation will be lost.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.dialogRef.close();
        }
      });
  }

  private clientAllowed(supplier: Supplier) {
    this.clients = this.suppConfigs.filter((sc) => sc.supplier.id === supplier.id).map((sc) => sc.client);
  }

  private findSupplierConfig(supplier: Supplier, client: string) {
    this.selectedSuppConfig = this.suppConfigs.filter((sc) => sc.client === client && sc.supplier.id === supplier.id)[0];
  }
}
