import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StockMovementComponent } from '@container/main-section/stock/stock-movement/stock-movement.component';
import { AppPreloadingStrategy } from './app-loading-strategy';
import { ContainerComponent } from '@container/container.component';
import { ItemsListComponent } from '@container/main-section/items-list/items-list.component';
import { OperationDetailsComponent } from '@container/main-section/operations/operation-details/operation-details.component';
import { OperationsListComponent } from '@container/main-section/operations/operations-list/operations-list.component';
import { PurchaseInvoiceItemComponent } from '@container/main-section/purchase-invoice/purchase-invoice-item/purchase-invoice-item.component';
import { PurchaseInvoiceListComponent } from '@container/main-section/purchase-invoice/purchase-invoice-list/purchase-invoice-list.component';
import { ShipmentsListComponent } from '@container/main-section/shipments/shipments-list/shipments-list.component';
import { SpoDetailsComponent } from '@container/main-section/spos/spo-details/spo-details.component';
import { SpoItemsListComponent } from '@container/main-section/spos/spo-items-list/spo-items-list.component';
import { SposListComponent } from '@container/main-section/spos/spos-list/spos-list.component';
import { StockBalanceComponent } from '@container/main-section/stock/stock-balance.component';
import { ProductAgingComponent } from '@container/main-section/stock/product-aging/product-aging.component';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      { path: 'items', component: ItemsListComponent },
      { path: 'operations', component: OperationsListComponent },
      {
        path: 'operation-detail/:operationId',
        component: OperationDetailsComponent,
      },
      { path: 'stock-balance', component: StockBalanceComponent },
      { path: 'stock-movement', component: StockMovementComponent },
      { path: 'product-aging', component: ProductAgingComponent },
      { path: 'spos', component: SposListComponent },
      { path: 'spo-items', component: SpoItemsListComponent },
      { path: 'spo-detail', component: SpoDetailsComponent },
      { path: 'shipments', component: ShipmentsListComponent },
      { path: 'invoices', component: PurchaseInvoiceListComponent },
      { path: 'invoices-detail/:purchaseInvoiceNumber', component: PurchaseInvoiceItemComponent },
      {
        path: 'configuration',
        loadChildren: () => import('./container/configuration/configuration.module').then((m) => m.ConfigurationModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
