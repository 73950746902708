import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ErrorMessagesModalComponent } from '@container/modal/error-messages-modal.component.ts/error-messages-modal.component';
import { AuthorizationService } from '@core/authorization.service';
import { isNilty } from '@core/utils.service';
import { Operation } from '@models/operation-model';
import { OperationDetailsOutput, OperationRow, OperationRowFilters } from '@models/operation-row-model';
import { Field, Header } from '@models/operations-config-model';
import { Permissions } from '@models/permissions';
import { CommonsService } from '@shared/commons.service';
import { Observable } from 'rxjs';
import { operationsFieldDefinitions } from 'src/environments/operations';
import { OperationsService } from '../operations.service';
import { ShippyproErrorModalComponent } from './shippypro-error-modal/shippypro-error-modal.component';
@Component({
  selector: 'app-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.css'],
})
export class OperationDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  operation: Operation = new Operation();
  rowData: OperationRow;

  hasFeedFiles = false;
  isExportable = false;

  operationId: string;

  dataSource;

  loading = true;

  headers: Header[];
  accordions: Header[];
  operationRowColumns: Field[];

  displayedColumns: string[];

  resultsNumber: Observable<number>;
  expectedQuantity: Observable<number>;
  receivedQuantity: Observable<number>;
  expectedQuantityValue: number;
  receivedQuantityValue: number;

  rowClass = 'col-3';

  hasBridgetAdministrationPermission: Observable<boolean>;
  hasBridgetSuperPermission: boolean;

  operationRowStatuses: string[];

  private allowedExportTypes = ['INBOUND', 'OUTBOUND', 'TRANSFER_ORDER'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private operationsService: OperationsService,
    private location: Location,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private commonsService: CommonsService
  ) {}

  ngOnInit() {
    this.commonsService.getBridgetEnums().subscribe((r) => {
      this.operationRowStatuses = r.operationRowStatuses;
    });

    this.resultsNumber = this.operationsService.resultsNumber;

    this.expectedQuantity = this.operationsService.expectedQuantity;
    this.expectedQuantity.subscribe((value) => {
      this.expectedQuantityValue = value;
    });

    this.receivedQuantity = this.operationsService.receivedQuantity;
    this.receivedQuantity.subscribe((value) => {
      this.receivedQuantityValue = value;
    });

    if (isNilty(this.operationsService.operationRowsFilters)) {
      this.operationsService.operationRowsFilters = new OperationRowFilters();
    }

    this.hasBridgetAdministrationPermission = this.authorizationService.hasBridgetPermission(Permissions.BridgetAdministration);

    this.activatedRoute.params.subscribe((params) => {
      this.operationId = params['operationId'];
      if (!isNilty(this.operationId)) {
        this.getRows();
      } else {
        this.location.back();
      }
    });
  }

  getRows() {
    this.operationsService.getRowsByFilters(this.operationId).subscribe((resp: OperationDetailsOutput) => {
      if (!isNilty(resp)) {
        this.operation = resp.operation;

        this.isExportable = this.allowedExportTypes.includes(resp.operation.type);

        this.checkFeedFiles();

        this.rowData = resp.dataRows[0];
        this.drawPage();
        this.displayedColumns = this.operationRowColumns.map((it) => it.name);
        if (!isNilty(resp.dataRows) && resp.dataRows.length !== 0) {
          this.dataSource = new MatTableDataSource<OperationRow>(resp.dataRows);
        }

        this.authorizationService.hasBridgetPermission(Permissions.BridgetSuper).subscribe((r) => {
          this.hasBridgetSuperPermission = r;
          if (r) {
            this.displayedColumns.push('superActions');
          }
        });
      }
    });
  }

  checkFeedFiles() {
    this.hasFeedFiles =
      !isNilty(this.operation?.operationFeed?.operationFeedUrl) ||
      !isNilty(this.operation?.operationFeed?.outputFeedUrl) ||
      !isNilty(this.operation?.operationFeed?.confirmationFeedUrl) ||
      !isNilty(this.operation?.confirmationFeed?.operationFeedUrl) ||
      !isNilty(this.operation?.confirmationFeed?.outputFeedUrl) ||
      !isNilty(this.operation?.confirmationFeed?.confirmationFeedUrl);
  }

  drawPage() {
    this.operationRowColumns = operationsFieldDefinitions.find((it) => it.type === this.operation.type).rowFields;
    this.headers = operationsFieldDefinitions.find((it) => it.type === this.operation.type).headerFields;
    this.accordions = operationsFieldDefinitions.find((it) => it.type === this.operation.type).accordionFields;

    switch (this.headers.length) {
      case 2: {
        this.rowClass = 'col-6';
        break;
      }
      case 3: {
        this.rowClass = 'col-4';
        break;
      }
      case 4: {
        this.rowClass = 'col-3';
        break;
      }
    }

    this.loading = false;
  }

  loadPage() {
    this.operationsService.operationRowsFilters.pageSize = this.paginator.pageSize;
    this.operationsService.operationRowsFilters.pageIndex = this.paginator.pageIndex;
    this.getRows();
  }

  onSort() {
    this.operationsService.operationRowsFilters.sortBy = this.sort.active;
    this.operationsService.operationRowsFilters.sortDirection = this.sort.direction;
    this.getRows();
  }

  goBack() {
    this.location.back();
  }

  openMessages(row: OperationRow) {
    this.dialog.open(ErrorMessagesModalComponent, { data: row });
  }

  navigateToFile(event: MouseEvent, fileLink: string) {
    event.stopPropagation();
    window.open(fileLink);
  }

  openShippyproErrorModal() {
    this.dialog
      .open(ShippyproErrorModalComponent, { data: this.operation })
      .afterClosed()
      .subscribe(() => this.getRows());
  }

  changeRowStatus(rowId: string, newStatus: string) {
    this.operationsService.changeRowStatus(this.operationId, rowId, newStatus).subscribe(() => this.loadPage());
  }

  pollOperation() {
    this.operationsService.pollOperation(this.operationId).subscribe(() => this.loadPage());
  }
  sendOperation() {
    this.operationsService.sendOperation(this.operationId).subscribe(() => this.loadPage());
  }
  moveOperationToNew() {
    this.operationsService.moveOperationToNew(this.operationId).subscribe(() => this.loadPage());
  }

  exportOperation() {
    this.operationsService.exportOperationNew(this.operation.id).subscribe((response) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = response.url;
      downloadLink.download = response.fileName;
      downloadLink.click();
    });
  }
}
