import { Observable, Subject, of, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserPermissions } from '@models/user-permissions-model';
import { ConverterService } from './converter.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { Clients } from '@models/clients';

@Injectable()
export class AuthorizationService {
  currentUserMail = '';

  bridgetPermissions: string[];
  jimboPermissions: string[];

  private bridgetPermissionsSubject = new Subject<string[]>();
  private jimboPermissionsSubject = new Subject<string[]>();

  private gettingPermissions = false;

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getPermissions(client: string = Clients.BRIDGET): Observable<string[]> {
    if (!this.bridgetPermissions || !this.jimboPermissions) {
      if (!this.gettingPermissions) {
        this.gettingPermissions = true;
        const bridgetCall = this.getPermissionsFromPonch(Clients.BRIDGET).pipe(
          map((role: UserPermissions) => {
            this.currentUserMail = role.email;
            this.bridgetPermissions = role.permissions;
          })
        );
        const jimboCall = this.getPermissionsFromPonch(Clients.JIMBO).pipe(
          map((role: UserPermissions) => {
            this.jimboPermissions = role.permissions;
          })
        );

        return forkJoin([bridgetCall, jimboCall]).pipe(
          map(() => {
            this.bridgetPermissionsSubject.next(this.bridgetPermissions);
            this.jimboPermissionsSubject.next(this.jimboPermissions);
            return client === Clients.BRIDGET ? this.bridgetPermissions : this.jimboPermissions;
          })
        );
      } else {
        return client === Clients.BRIDGET ? this.bridgetPermissionsSubject : this.jimboPermissionsSubject;
      }
    } else {
      return of(client === Clients.BRIDGET ? this.bridgetPermissions : this.jimboPermissions);
    }
  }

  hasBridgetPermission(permission: string): Observable<boolean> {
    return this.getPermissions(Clients.BRIDGET).pipe(
      map((p) => {
        if (p.includes(permission)) {
          return true;
        }
      })
    );
  }

  hasBridgetPermissions(permissions: string[]): Observable<boolean> {
    return this.getPermissions(Clients.BRIDGET).pipe(map((p) => p.some((permission) => permissions.includes(permission))));
  }

  hasJimboPermission(permission: string): Observable<boolean> {
    return this.getPermissions(Clients.JIMBO).pipe(
      map((p) => {
        if (p.includes(permission)) {
          return true;
        }
      })
    );
  }

  private getPermissionsFromPonch(client: string): Observable<UserPermissions> {
    return this.http
      .get(this.environmentService.getRestEndpoint('userPermissions') + '?application=' + client, {})
      .pipe(map((resp: UserPermissions) => this.converter.fromJSONtoObj(resp, UserPermissions)));
  }
}
