<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-listbox>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-listbox>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="container-fluid">
      <div class="row pt-3">
        <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
          <div class="filter-box col-md-12 text-light-secondary margin-top-5">
            <div class="row justify-content-center row-cols-4">
              <div class="col">
                <mat-form-field>
                  <input matInput type="text" formControlName="shipmentId" placeholder="Shipment ID" />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field>
                  <input matInput formControlName="createdAt" [matDatepicker]="createdAt" placeholder="Created At" />
                  <mat-datepicker-toggle matSuffix [for]="createdAt"></mat-datepicker-toggle>
                  <mat-datepicker #createdAt></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field>
                  <mat-select filterKey="code" formControlName="client" id="status" placeholder="Client">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option [value]="c" *ngFor="let c of clients">
                      {{ c }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field>
                  <mat-select filterKey="name" formControlName="status" id="status" placeholder="Status">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option [value]="st" *ngFor="let st of status">
                      {{ st.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field>
                  <input matInput type="text" formControlName="carrier" placeholder="Carrier" />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field>
                  <input matInput type="text" formControlName="trackingNumber" placeholder="Tracking Number" />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field>
                  <mat-select formControlName="hasLabel" placeholder="Has Label">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option [value]="true">True</mat-option>
                    <mat-option [value]="false">False</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-12 border-top-white text-center">
            <button type="submit" (click)="onSubmitFilter()">Filter</button>
            <button type="button" (click)="resetFilters()">Reset</button>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>
</div>
