<div class="container-fluid py-4">
  <div *ngIf="emptyProductAging">
    <h4 class="col text-center summary mb-0">No Product Aging Info</h4>
  </div>

  <div *ngIf="!emptyProductAging" class="row mat-dialog-title">
    <h4 class="col text-center summary mb-0">Product Aging Info</h4>
  </div>

  <mat-tab-group *ngIf="!emptyProductAging" class="mt-4" (selectedTabChange)="onTabChanged($event)">
    <mat-tab *ngFor="let productAging of productAgingList" label="{{ productAging.stockLocationCode }}">
      <div class="row">
        <div class="col mx-1">
          <div class="row mat-dialog-title mt-4">
            <h6 class="col text-center summary mb-0">Incomes Without Exit</h6>
          </div>
          <table mat-table [dataSource]="incomesDataSource" matSort>
            <ng-container matColumnDef="operationId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="operationId" class="text-nowrap">Operation Id</th>
              <td mat-cell *matCellDef="let detail">{{ detail.operationId }}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="quantity">Quantity</th>
              <td mat-cell *matCellDef="let detail">{{ detail.quantity }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="date">Date</th>
              <td mat-cell *matCellDef="let detail">{{ detail.date }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sticky"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>

        <div class="col mx-1">
          <div class="row mat-dialog-title mt-4">
            <h6 class="col text-center summary mb-0">Last Exit</h6>
          </div>

          <table mat-table [dataSource]="lastExitDataSource" matSort>
            <ng-container matColumnDef="operationId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="operationId" class="text-nowrap">Operation Id</th>
              <td mat-cell *matCellDef="let detail">{{ detail.operationId }}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="quantity">Quantity</th>
              <td mat-cell *matCellDef="let detail">{{ detail.quantity }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="date">Date</th>
              <td mat-cell *matCellDef="let detail">{{ detail.date }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sticky"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
