import { Component, OnInit, Output } from '@angular/core';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';
import { WarehousesService } from '@container/configuration/warehouses/warehouses.service';
import { isNilty } from '@core/utils.service';
import { BridgetEnums } from '@models/bridget-enums-model';
import { OperationFilters } from '@models/operation-model';
import { Supplier } from '@models/supplier-model';
import { Warehouse } from '@models/warehouse-model';
import { CommonsService } from '@shared/commons.service';
import { Subject } from 'rxjs';
import { operationsFieldDefinitions } from 'src/environments/operations';
import { OperationsService } from '../../operations.service';
@Component({
  selector: 'app-operations-list-filters',
  templateUrl: './operations-list-filters.component.html',
  styleUrls: ['./operations-list-filters.component.css'],
})
export class OperationsListFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<void>();

  public openFilter = false;

  filters: OperationFilters;

  orderTypes: string[];
  logisticPartners: string[];
  clients: string[];
  suppliers: Supplier[];
  operationTypes: string[];
  statusList: string[];
  warehouses: Warehouse[];

  filtersMap = [];

  constructor(
    private operationsService: OperationsService,
    private suppliersService: SuppliersService,
    private warehousesService: WarehousesService,
    private commonService: CommonsService
  ) {}

  ngOnInit() {
    this.operationTypes = operationsFieldDefinitions.map((it) => it.type);

    this.commonService.getBridgetEnums().subscribe((r: BridgetEnums) => {
      this.orderTypes = r.orderTypes;
      this.clients = r.clients;
      this.logisticPartners = r.logisticPartners;
      this.statusList = r.operationStatuses;
    });

    this.suppliersService.getSuppliers().subscribe((resp: Supplier[]) => {
      this.suppliers = resp;
    });

    this.warehousesService.getWarehouses().subscribe((resp: Warehouse[]) => {
      this.warehouses = resp;
    });

    if (isNilty(this.operationsService.operationFilters)) {
      this.operationsService.operationFilters = new OperationFilters();
    }
    this.filters = this.operationsService.operationFilters;
  }

  onSubmitFilter() {
    this.updateFiltersMap();
    this.operationsService.operationFilters.pageIndex = 0;
    this.onFilter.next();
  }

  resetFilters() {
    this.filters = new OperationFilters();
    this.operationsService.operationFilters = this.filters;
    this.onFilter.next();
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    // TODO
  }
}
