<div class="row mat-dialog-title">
  <h4 class="col-md-10 offset-md-1 text-center summary mb-0">Import SPOA</h4>
  <div class="col-md-1 text-end">
    <i class="fa fa-times exit-icon" (click)="close()" style="cursor: pointer"></i>
  </div>
</div>

<div mat-dialog-content class="border">
  <mat-vertical-stepper [linear]="true">
    <ng-template matStepperIcon="edit" let-index="index">
      <i class="fa fa-check"></i>
    </ng-template>

    <ng-template matStepperIcon="done" let-index="index">
      <i class="fa fa-check"></i>
    </ng-template>

    <mat-step [completed]="completedSteps[0]" label="Select Supplier and Client">
      <div class="row padding-top-15">
        <div class="col-md-4 text-center padding-bottom-20">
          <mat-form-field>
            <mat-select [(ngModel)]="selectedSupplier" placeholder="Supplier" (ngModelChange)="updatedData()">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let s of suppliers" [value]="s">{{ s.description }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4 text-center padding-bottom-20">
          <mat-form-field>
            <mat-select
              [disabled]="selectedSupplier | isNullOrUndefinedOrEmptyString"
              [(ngModel)]="selectedClient"
              placeholder="Client"
              (ngModelChange)="updatedData()"
            >
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let c of clients" [value]="c">{{ c }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4 text-center padding-bottom-20">
          Has Tracking informations
          <mat-slide-toggle
            class="margin-top-5"
            [(ngModel)]="hasTracking"
            matTooltip="Enable if excel file contains tracking number and carrier informations"
          ></mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-end padding-top-20">
          <button
            class="btn-custom"
            matStepperNext
            [disabled]="(selectedSupplier | isNullOrUndefinedOrEmptyString) || (selectedClient | isNullOrUndefinedOrEmptyString)"
          >
            Next
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [completed]="completedSteps[1]" label="Select File">
      <div class="row padding-top-15">
        <div class="col-md-8">
          <mat-form-field class="full-width">
            <input matInput [value]="currentFileUpload ? currentFileUpload.name : ''" disabled placeholder="File" />
          </mat-form-field>
        </div>

        <div class="col-md-4 padding-top-6">
          <button class="box-shadow-none btn-custom search-button" (click)="loadFile()">Search</button>
          <input #spoaFile type="file" style="display: none" (change)="fileChanged($event)" />
        </div>
      </div>

      <div class="row padding-top-20">
        <div class="col-md-6 text-start">
          <button class="btn-custom" matStepperPrevious>
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            Back
          </button>
        </div>

        <div class="col-md-6 text-end">
          <button class="btn-custom" (click)="confirmFile()" [disabled]="currentFileUpload | isNullOrUndefined">
            Next
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [completed]="completedSteps[2]" label="Summary">
      <div class="row">
        <ng-container *ngIf="lastStep">
          <div class="col-md-12 summary">
            <div class="row">
              <p><b>Supplier: </b> {{ selectedSupplier.description }}</p>
            </div>

            <div class="row">
              <p><b>Client: </b> {{ selectedClient }}</p>
            </div>

            <div class="row">
              <div *ngIf="hasTracking; else noTracking">
                <p><b>Has Tracking informations: </b>Yes</p>
              </div>
              <ng-template #noTracking
                ><p><b>Has Tracking informations: </b>No</p></ng-template
              >
            </div>

            <div class="row">
              <p><b>File: </b> {{ currentFileUpload.name }}</p>
            </div>

            <div class="row padding-top-20">
              <div class="col-md-6 text-start">
                <button class="btn-custom" matStepperPrevious>
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  Back
                </button>
              </div>

              <div class="col-md-6 text-end">
                <button class="btn-custom" (click)="confirmOperation()">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div mat-dialog-actions></div>
