import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';

@JsonObject('SenderColumnDefinition')
export class SenderColumnDefinition extends BaseObject {
  @JsonProperty('field', String, true) field: string = undefined;
  @JsonProperty('label', String, false) label: string = undefined;
  @JsonProperty('fixedValue', String, false) fixedValue: string = undefined;
  @JsonProperty('type', String, false) type: string = undefined;
}
