import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { EndpointType } from '@models/supplier-configuration/endpoint-type-model';
import { MessageTypesOutput } from '@models/supplier-configuration/message-types-model';
import { RECEIVER_MESSAGE_TYPES_PARAMS, SENDER_MESSAGE_TYPES_PARAMS } from '@models/supplier-configuration/params-model';
import { RouteConfiguration } from '@models/supplier-configuration/route-configuration-model';
import { SupplierConfiguration } from '@models/supplier-configuration/supplier-configuration-model';
import { Supplier } from '@models/supplier-model';
import { NameCodeEditModalComponent } from '../../name-code-edit-modal/name-code-edit-modal.component';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { SuppliersConfigurationsService } from '../suppliers-configurations.service';

@Component({
  selector: 'app-suppliers-configurations-edit',
  templateUrl: './suppliers-configurations-edit.component.html',
  styleUrls: ['./suppliers-configurations-edit.component.css'],
})
export class SuppliersConfigurationsEditComponent implements OnInit {
  editMode = false;

  currentId: string;
  currentConfiguration: SupplierConfiguration;

  suppliers: Supplier[];
  selectedSupplierId: string = undefined;

  clients: string[];

  endpointTypes: EndpointType[];
  receiverEndpointTypes: EndpointType[];

  messageTypes: MessageTypesOutput;

  canSave = false;

  canSetReceiverMessages = false;

  senderMessageTypesParams = SENDER_MESSAGE_TYPES_PARAMS;
  receiverMessageTypesParams = RECEIVER_MESSAGE_TYPES_PARAMS;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  notificationReceiver = new FormControl('', [Validators.required, Validators.email]);
  notificationReceivers: string[] = [];
  lastIsRouteValid: boolean;

  constructor(
    private route: ActivatedRoute,
    private suppliersConfigurationsService: SuppliersConfigurationsService,
    private suppliersService: SuppliersService,
    private location: Location,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadSuppliers();
    this.loadEndpointTypes();
    this.loadMessageTypes();
    this.loadClients();

    this.route.params.subscribe((params: Params) => {
      this.currentId = params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.getConfiguration();
      } else {
        this.currentConfiguration = new SupplierConfiguration();
      }
    });
  }

  getConfiguration() {
    this.selectedSupplierId = undefined;
    this.suppliersConfigurationsService.getConfiguration(this.currentId).subscribe((sc: SupplierConfiguration) => {
      this.currentConfiguration = sc;
      this.notificationReceivers = sc.notificationReceiver.split(',');
      this.selectedSupplierId = this.currentConfiguration.supplier.id;
      this.checkCanSetReceiverMessages();
      this.updateValidity(true);
    });
  }

  loadSuppliers() {
    this.suppliersService.getSuppliers().subscribe((resp: Supplier[]) => {
      this.suppliers = resp;
      if (!isNilty(this.currentConfiguration)) {
        this.currentConfiguration.supplier = this.suppliers.find((it) => it.id === this.selectedSupplierId);
      }
    });
  }

  loadClients() {
    this.suppliersConfigurationsService.getClients().subscribe((resp: string[]) => (this.clients = resp));
  }

  loadEndpointTypes() {
    this.suppliersConfigurationsService.getEndpointTypes().subscribe((resp: EndpointType[]) => {
      this.endpointTypes = resp;
      // The only endpoint configured for receiving messages is ftp
      this.receiverEndpointTypes = resp.filter((it) => it.receiver);
    });
  }

  loadMessageTypes() {
    this.suppliersConfigurationsService.getMessageTypes().subscribe((resp: MessageTypesOutput) => (this.messageTypes = resp));
  }

  supplierSelected(id: string) {
    this.selectedSupplierId = this.suppliers.find((it) => it.id === id)?.code;
    this.currentConfiguration.supplier = this.suppliers.find((it) => it.id === id);
  }

  newSupplier() {
    const supplier = new Supplier();
    const subscription = this.dialog
      .open(NameCodeEditModalComponent, {
        data: {
          title: 'New supplier',
          object: supplier,
        },
      })
      .afterClosed()
      .subscribe((resp: Supplier) => {
        if (!isNilty(resp)) {
          this.suppliersService.saveSupplier(resp).subscribe((supp: Supplier) => {
            subscription.unsubscribe();
            this.selectedSupplierId = supp.id;
            this.loadSuppliers();
          });
        }
      });
  }

  updateValidity(isRouteValid: boolean) {
    this.lastIsRouteValid = isRouteValid;
    setTimeout(() => {
      this.canSave =
        !isNilty(this.currentConfiguration.supplier) &&
        !isNilty(this.currentConfiguration.senderRoute) &&
        this.notificationReceivers.length > 0 &&
        isRouteValid;
      this.checkCanSetReceiverMessages();
    }, 0);
  }

  save() {
    this.currentConfiguration.notificationReceiver = this.notificationReceivers.join(',');
    this.suppliersConfigurationsService.save(this.currentConfiguration).subscribe(() => {
      this.goBack();
    });
  }

  addReceiverRoute() {
    this.currentConfiguration.receiverRoute = new RouteConfiguration();
    this.checkCanSetReceiverMessages();
  }

  goBack() {
    this.location.back();
  }

  checkCanSetReceiverMessages() {
    const endpoint = this.currentConfiguration.receiverRoute
      ? this.currentConfiguration.receiverRoute.endpointType
      : this.currentConfiguration.senderRoute.endpointType;
    this.canSetReceiverMessages = this.receiverEndpointTypes.findIndex((it) => it.endpointType === endpoint) !== -1;
  }

  add(event: MatChipInputEvent, list: string[], control: FormControl): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (!isNilty(value)) {
      if (control.valid) {
        list.push(value);

        this.updateValidity(this.lastIsRouteValid);
        // Reset the input value
        if (input) {
          input.value = '';
          control.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  remove(r: string, list: string[]): void {
    const index = list.indexOf(r);

    if (index >= 0) {
      list.splice(index, 1);
    }
    this.updateValidity(this.lastIsRouteValid);
  }

  addCancellationRoute() {
    this.currentConfiguration.cancellationRoute = new RouteConfiguration();
    this.currentConfiguration.cancellationRouteActive = true;
  }

  setCancellationRouteStatus(value: boolean) {
    this.currentConfiguration.cancellationRouteActive = value;
  }

  addTroubleRoute() {
    this.currentConfiguration.troubleRoute = new RouteConfiguration();
    this.currentConfiguration.troubleRouteActive = true;
  }

  setTroubleRouteStatus(value: boolean) {
    this.currentConfiguration.troubleRouteActive = value;
  }
}
