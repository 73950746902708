import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { isNil, isNilty } from '@core/utils.service';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): string {
    return !isNil(date) ? fromDateToString(date) : undefined;
  }

  deserialize(string: string): Date {
    return stringToDate(string);
  }
}

export const stringToDate = (string: string): Date => {
  if (isNilty(string)) {
    return undefined;
  }
  const date = string.split(' ');

  let hour = null;
  let minute = null;

  if (date.length === 2) {
    const time = date[1].split(':');
    if (time.length !== 2) {
      return undefined;
    }

    hour = Number(time[0]);
    minute = Number(time[1]);
  }

  const split = date[0].split('/');
  if (split.length !== 3) {
    return undefined;
  }

  const year = Number(split[2]);
  const month = Number(split[1]) - 1;
  const day = Number(split[0]);
  return new Date(year, month, day, hour, minute);
};

export const fromDateToString = (date: Date) => {
  if (date == null) {
    return null;
  } else {
    const day = date.getDate();
    let dayString = day.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }
    const month = date.getMonth() + 1;
    let monthString = month.toString();
    if (month < 10) {
      monthString = '0' + monthString;
    }

    const year = date.getFullYear().toString();

    return dayString + '/' + monthString + '/' + year;
  }
};

export const fromDateToFiltersString = (date: Date) => {
  if (date == null) {
    return null;
  } else {
    const day = date.getDate();
    let dayString = day.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }
    const month = date.getMonth() + 1;
    let monthString = month.toString();
    if (month < 10) {
      monthString = '0' + monthString;
    }

    const year = date.getFullYear().toString().substring(2, 4);

    return dayString + '/' + monthString + '/' + year;
  }
};
