import { JsonObject, JsonProperty } from 'json2typescript';
import { Operation } from './operation-model';

@JsonObject('NewOperationInput')
export class NewOperationInput {
  @JsonProperty('operation', Operation, true) operation: Operation = undefined;
  @JsonProperty('url', String, true) url: string = undefined;

  constructor(op: Operation, url: string) {
    this.operation = op;
    this.url = url;
  }
}
