import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Supplier } from './supplier-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { MatChipsData } from './mat-chips-data-model';
import { isNilty, isNil } from '@core/utils.service';
import { DateConverter, fromDateToString } from './date-converter';
import { Owner } from './owner-model';

@JsonObject('ShipToWarehouse')
export class ShipToWarehouse {
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('city', String, true) city: string = undefined;
  @JsonProperty('region', String, true) region: string = undefined;
  @JsonProperty('address', String, true) address: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('postalCode', String, true) postalCode: string = undefined;
  @JsonProperty('phone', String, true) phone: string = undefined;
  @JsonProperty('mail', String, true) mail: string = undefined;
}

@JsonObject('Spo')
export class Spo extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('supplier', Supplier, false) supplier: Supplier = undefined;
  @JsonProperty('client', String, false) client: string = undefined;
  @JsonProperty('orderId', String, false) orderId: string = undefined;
  @JsonProperty('owner', String, false) owner: string = undefined;
  @JsonProperty('orderDate', String, false) orderDate: string = undefined;
  @JsonProperty('status', String, false) status: string = undefined;
  @JsonProperty('requestedQuantity', Number, false) requestedQuantity: number = undefined;
  @JsonProperty('requestedValue', Number, false) requestedValue: number = undefined;
  @JsonProperty('spoFileUrl', String, false) spoFileUrl: string = undefined;
  @JsonProperty('spoConfirmFileUrl', String, false) spoConfirmFileUrl: string = undefined;
  @JsonProperty('spoaFileUrl', String, false) spoaFileUrl: string = undefined;
  @JsonProperty('confirmedQuantity', Number, true) confirmedQuantity: number = undefined;
  @JsonProperty('confirmedValue', Number, true) confirmedValue: number = undefined;
  @JsonProperty('orderedQuantity', Number, true) orderedQuantity: number = undefined;
  @JsonProperty('orderedValue', Number, true) orderedValue: number = undefined;
  @JsonProperty('earliestDeliveryDate', String, true) earliestDeliveryDate: string = undefined;
  @JsonProperty('latestDeliveryDate', String, true) latestDeliveryDate: string = undefined;

  @JsonProperty('clientOrderId', String, true) clientOrderId: string = undefined;
  @JsonProperty('supplierOrderId', String, true) supplierOrderId: string = undefined;
  @JsonProperty('supplierStatus', String, true) supplierStatus: string = undefined;
  @JsonProperty('externalClientSpoFileUrl', String, true) externalClientSpoFileUrl: string = undefined;
  @JsonProperty('externalClientSpoaFileUrl', String, true) externalClientSpoaFileUrl: string = undefined;

  @JsonProperty('sendingRouteType', String, true) sendingRouteType: string = undefined;
  @JsonProperty('shipTo', ShipToWarehouse, true) shipTo: ShipToWarehouse = undefined;
}

@JsonObject('SpoFilters')
export class SpoFilters extends PaginationAndSorting {
  @JsonProperty('spoId', String, true) spoId: string = undefined;
  @JsonProperty('orderId', String, true) orderId: string = undefined;
  @JsonProperty('owner', String, true) owner: string = undefined;
  @JsonProperty('orderDateFrom', DateConverter, true) orderDateFrom: Date = undefined;
  @JsonProperty('orderDateTo', DateConverter, true) orderDateTo: Date = undefined;
  @JsonProperty('client', String, true) client: string = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('earliestDeliveryDate', DateConverter, true) earliestDeliveryDate: Date = undefined;
  @JsonProperty('latestDeliveryDate', DateConverter, true) latestDeliveryDate: Date = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('altEan', String, true) altEan: string = undefined;
  @JsonProperty('hasOrderedQuantity', Boolean, false) hasOrderedQuantity = false;
  @JsonProperty('clientApplicationId', String, true) clientApplicationId: string = undefined;
  @JsonProperty('missingConfirmed', Boolean, false) missingConfirmed = false;
}

export const spoFiltersMap = (
  orderId: string,
  owner: Owner,
  orderDateFrom: Date,
  orderDateTo: Date,
  client: string,
  supplier: Supplier,
  earliestDeliveryDate: Date,
  latestDeliveryDate: Date,
  status: string,
  ean: string,
  altEan: string,
  hasOrderedQuantity: boolean,
  missingConfirmed: boolean,
  clientApplicationId: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(orderId)) {
    map.push(new MatChipsData('Order ID', orderId));
  }
  if (!isNilty(owner)) {
    map.push(new MatChipsData('Owner', owner.name));
  }
  if (!isNil(orderDateFrom) && !isNil(orderDateTo)) {
    map.push(new MatChipsData('Order Date', fromDateToString(orderDateFrom) + ' - ' + fromDateToString(orderDateTo)));
  }
  if (!isNilty(client)) {
    map.push(new MatChipsData('Client', client));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier.code));
  }
  if (!isNil(earliestDeliveryDate)) {
    map.push(new MatChipsData('Ealiest Delivery Date', fromDateToString(earliestDeliveryDate)));
  }
  if (!isNil(latestDeliveryDate)) {
    map.push(new MatChipsData('Latest Delivery Date', fromDateToString(latestDeliveryDate)));
  }
  if (!isNilty(status)) {
    map.push(new MatChipsData('Status', status));
  }
  if (!isNilty(ean)) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (!isNilty(altEan)) {
    map.push(new MatChipsData('Actual EAN', altEan));
  }
  if (!isNilty(hasOrderedQuantity) && hasOrderedQuantity) {
    map.push(new MatChipsData('Ordered > 0', ''));
  }
  if (!isNilty(missingConfirmed) && missingConfirmed) {
    map.push(new MatChipsData('Missing', ''));
  }
  if (!isNilty(clientApplicationId)) {
    map.push(new MatChipsData('Client Application ID', clientApplicationId));
  }
  return map;
};
