<app-operations-list-filters (onFilter)="applyFilters()"></app-operations-list-filters>

<div class="container-fluid pt-4">
  <h2 class="title-custom">Operations</h2>

  <div class="row pt-3">
    <div class="col-md-6">
      <button *ngIf="hasBridgetAdministrationPermission | async" class="btn-custom" (click)="createOperation()">
        <i class="fa fa-plus" aria-hidden="true"></i>
        Create operation
      </button>
    </div>
    <div class="col-md-6">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="type">
        <th mat-header-cell class="min-w-180" *matHeaderCellDef mat-sort-header="type">Type</th>
        <td mat-cell class="min-w-180" *matCellDef="let op" (click)="openDetails(op)">
          {{ op.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="logisticPartner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="logisticPartner">Logistic Partner</th>
        <td mat-cell *matCellDef="let op" (click)="openDetails(op)">{{ op.logisticPartner }}</td>
      </ng-container>

      <ng-container matColumnDef="orderType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="orderType">Order Type</th>
        <td mat-cell *matCellDef="let op" (click)="openDetails(op)">
          {{ op.orderType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="client">Client</th>
        <td mat-cell *matCellDef="let op" (click)="openDetails(op)">
          {{ op.client }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentNumber">
        <th mat-header-cell class="min-w-270" *matHeaderCellDef mat-sort-header="documentNumber">Document Number</th>
        <td mat-cell class="min-w-270" *matCellDef="let op" (click)="openDetails(op)">
          {{ op.documentNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerOrderId">
        <th mat-header-cell class="min-w-170" *matHeaderCellDef mat-sort-header="customerOrderId">Customer Order Id</th>
        <td mat-cell class="min-w-170" *matCellDef="let op" (click)="openDetails(op)">
          {{ op.data.customerOrderId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplierCode">
        <th mat-header-cell class="min-w-220" *matHeaderCellDef mat-sort-header="supplierCode">Supplier</th>
        <td mat-cell class="min-w-220" *matCellDef="let op" (click)="openDetails(op)">
          {{ op.data.supplierCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="warehouse">
        <th mat-header-cell class="min-w-220" *matHeaderCellDef mat-sort-header="warehouse">Warehouse</th>
        <td mat-cell class="min-w-220" *matCellDef="let op" (click)="openDetails(op)">
          {{ op.data.warehouse }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th class="min-w-150" mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Created at</th>
        <td class="min-w-150" mat-cell *matCellDef="let op" (click)="openDetails(op)">
          {{ op.createdAt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
        <td mat-cell *matCellDef="let op" (click)="openDetails(op)">
          {{ op.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th class="min-w-150" mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">Updated at</th>
        <td class="min-w-150" mat-cell *matCellDef="let op" (click)="openDetails(op)">
          {{ op.updatedAt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="feeds">
        <th mat-header-cell *matHeaderCellDef>Feeds</th>
        <td mat-cell *matCellDef="let op">
          <!--TODO Make a single pipe-->
          <a *ngIf="!(op.operationFeed | isNullOrUndefinedOrEmptyString) || !(op.confirmationFeed | isNullOrUndefinedOrEmptyString)">
            <i class="fa fa-file-text" aria-hidden="true" [matMenuTriggerFor]="operationFeeds" style="cursor: pointer"></i>
          </a>
          <mat-menu #operationFeeds="matMenu">
            <ng-container *ngIf="!(op.operationFeed | isNullOrUndefinedOrEmptyString)">
              <button
                (click)="navigateToFile($event, op.operationFeed.operationFeedUrl)"
                mat-menu-item
                *ngIf="!(op.operationFeed.operationFeedUrl | isNullOrUndefinedOrEmptyString)"
              >
                <i class="fa fa-download padding-right-5" aria-hidden="true"></i>
                Input File
              </button>
              <button
                (click)="navigateToFile($event, op.operationFeed.outputFeedUrl)"
                mat-menu-item
                *ngIf="!(op.operationFeed.outputFeedUrl | isNullOrUndefinedOrEmptyString)"
              >
                <i class="fa fa-upload padding-right-5" aria-hidden="true"></i>
                Output Feed
              </button>
              <button
                (click)="navigateToFile($event, op.operationFeed.outputDetailsFeedUrl)"
                mat-menu-item
                *ngIf="!(op.operationFeed.outputDetailsFeedUrl | isNullOrUndefinedOrEmptyString)"
              >
                <i class="fa fa-upload padding-right-5" aria-hidden="true"></i>
                Output Details Feed
              </button>
            </ng-container>
            <ng-container *ngIf="!(op.confirmationFeed | isNullOrUndefinedOrEmptyString)">
              <button
                (click)="navigateToFile($event, op.confirmationFeed.confirmationFeedUrl)"
                mat-menu-item
                *ngIf="!(op.confirmationFeed.confirmationFeedUrl | isNullOrUndefinedOrEmptyString)"
              >
                <i class="fa fa-check-square-o padding-right-5" aria-hidden="true"></i>
                Confirmation Feed
              </button>
              <button
                (click)="navigateToFile($event, op.confirmationFeed.confirmationDetailsFeedUrl)"
                mat-menu-item
                *ngIf="!(op.confirmationFeed.confirmationDetailsFeedUrl | isNullOrUndefinedOrEmptyString)"
              >
                <i class="fa fa-check-square-o padding-right-5" aria-hidden="true"></i>
                Confirmation Details Feed
              </button>
            </ng-container>
          </mat-menu>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let op; columns: displayedColumns" style="cursor: pointer"></mat-row>
    </table>
  </div>
</div>
