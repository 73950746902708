<app-product-aging-filters (onFilter)="applyFilters()" (hasFilters)="hasFilters = $event"></app-product-aging-filters>

<div class="container-fluid py-4">
  <h2 class="title-custom">Product Aging</h2>

  <div class="row justify-content-end pt-3">
    <div class="col-md-8">
      <app-stock-buttons exclude="product-aging"></app-stock-buttons>
    </div>

    <div class="col-md-4">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <div class="row justify-content-end pt-3">
    <div class="col-md-12" *ngIf="hasFilters">
      <button
        class="btn-icon ms-2"
        [class.d-none]="!hasFilters || dataSource.filteredData.length === 0"
        (click)="exportCSV()"
        matTooltip="Download data"
      >
        <i class="fa fa-download"></i>
      </button>
    </div>
  </div>

  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" class="table font-Titillium" matSort (matSortChange)="onSort()" multiTemplateDataRows>
      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="sku">Sku</th>
        <td mat-cell *matCellDef="let pa">
          {{ pa.sku }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalResidualQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totalResidualQuantity">Total Qty</th>
        <td mat-cell *matCellDef="let pa">
          {{ pa.totalResidualQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastExitDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastExitDate">Last Exit Date</th>
        <td mat-cell *matCellDef="let pa">{{ pa.lastExit.date }}</td>
      </ng-container>

      <ng-container matColumnDef="stockLocation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="stockLocation">Stock Location</th>
        <td mat-cell *matCellDef="let pa">{{ pa.stockLocationCode }}</td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let pa" class="text-end">
          <button
            class="btn-icon"
            aria-label="expand row"
            (click)="expandedElement = expandedElement === pa ? null : pa; $event.stopPropagation()"
          >
            <i class="fa fa-caret-down fa-xl" *ngIf="expandedElement !== pa"></i>
            <i class="fa fa-caret-up" *ngIf="expandedElement === pa"></i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let pa" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="pa === expandedElement ? 'expanded' : 'collapsed'">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <h6 class="col text-center">Incomes Without Exit</h6>
                  <table mat-table [dataSource]="pa.incomesWithoutExit" class="header-secondary">
                    <ng-container matColumnDef="operationId">
                      <th mat-header-cell *matHeaderCellDef>Operation Id</th>
                      <td mat-cell *matCellDef="let incomesWithoutExit">
                        {{ incomesWithoutExit.operationId }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                      <th mat-header-cell *matHeaderCellDef>quantity</th>
                      <td mat-cell *matCellDef="let incomesWithoutExit">
                        {{ incomesWithoutExit.quantity }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef>data</th>
                      <td mat-cell *matCellDef="let incomesWithoutExit">
                        {{ incomesWithoutExit.date }}
                      </td>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="incomesWithoutExitDisplayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let incomesWithoutExit; columns: incomesWithoutExitDisplayedColumns"></mat-row>
                  </table>
                </div>

                <div class="col">
                  <h6 class="col text-center">Last Exit</h6>
                  <table mat-table [dataSource]="[pa.lastExit]" class="header-secondary">
                    <ng-container matColumnDef="operationId">
                      <th mat-header-cell *matHeaderCellDef>Operation Id</th>
                      <td mat-cell *matCellDef="let lastExit">
                        {{ lastExit.operationId }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                      <th mat-header-cell *matHeaderCellDef>quantity</th>
                      <td mat-cell *matCellDef="let lastExit">
                        {{ lastExit.quantity }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef>data</th>
                      <td mat-cell *matCellDef="let lastExit">
                        {{ lastExit.date }}
                      </td>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="incomesWithoutExitDisplayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let lastExit; columns: incomesWithoutExitDisplayedColumns"></mat-row>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let pa; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === pa"
      ></mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </table>
  </div>
</div>
