<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-listbox>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-listbox>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="container-fluid">
      <div class="row pt-3">
        <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
          <div class="filter-box col-md-12 text-light-secondary margin-top-5">
            <div class="row justify-content-center row-cols-4">
              <ng-container *ngIf="purchaseInvoiceFilters">
                <div class="col">
                  <mat-form-field>
                    <input matInput formControlName="purchaseInvoiceNumber" placeholder="Invoice Number" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <app-brandon-autocomplete
                    label="Supplier"
                    [allData]="suppliers"
                    [externalFormControl]="filterForm.controls.supplier"
                    displayKey="description"
                    filterKey="description"
                    valueKey="id"
                  ></app-brandon-autocomplete>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-md-12 border-top-white text-center">
            <button type="submit" (click)="onSubmitFilter()">Filter</button>
            <button type="button" (click)="resetFilters()">Reset</button>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>
</div>
