import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stock-buttons',
  templateUrl: './stock-buttons.component.html',
  styleUrls: ['./stock-buttons.component.css'],
})
export class StockButtonsComponent {
  @Input() exclude: string;

  constructor(private router: Router) {}

  showButton(route: string) {
    return route !== this.exclude;
  }

  openStockBalance() {
    this.router.navigate(['stock-balance']);
  }

  openStockMovement() {
    this.router.navigate(['stock-movement']);
  }

  openProductAging() {
    this.router.navigate(['product-aging']);
  }
}
