<legend class="small-label">
  {{ title }} messages configuration
  <i
    class="fa fa-question-circle small-help-icon"
    aria-hidden="true"
    [matTooltip]="
      isReceiver
        ? 'Jimbo will be waiting to receive the configured messages from the Supplier (SPOA or PACKING_LIST). Messages must be selected and Active.'
        : 'Activates and configures the messages that can be sent to current Supplier (SPOs). Messages must be selected and Active.'
    "
  ></i>
</legend>

<div class="col-md-12 margin-top-10 margin-bottom-10 aws-sftp-alert" *ngIf="routeType === 'aws_sftp'">
  <ng-container *ngIf="isReceiver"
    ><b>NOTE</b>: Paths for aws_sftp receiver Routes are fixed for each message type: '{{ '{' }}SupplierCode}/Orders/SPOA/',
    'Orders/Invoice/', and 'Orders/PackingList/' respectively.</ng-container
  >
  <ng-container *ngIf="!isReceiver">
    <b>NOTE</b>: Path for aws_sftp sender Route is fixed to '{{ '{' }}SupplierCode}/Orders/'
  </ng-container>
</div>

<div class="col-md-12" *ngFor="let messageType of messageTypes">
  <div class="row message-type-row align-items-center">
    <div class="col-md-2">
      <mat-checkbox [(ngModel)]="messageType.configured" (change)="configurationChanged($event, messageType)"></mat-checkbox>
      {{ messageType.description }}
    </div>

    <ng-container *ngIf="messageType.configured">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <mat-select
                [(ngModel)]="messageType.configuration.fileType"
                placeholder="File type"
                (ngModelChange)="setFileTypeParams(messageType)"
              >
                <mat-option *ngFor="let ft of messageType.fileTypes" [value]="ft.type">{{ ft.type }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6" *ngFor="let param of params">
            <mat-form-field>
              <input matInput [(ngModel)]="messageType.configuration.fileParams[param.key]" [placeholder]="param.label" />
            </mat-form-field>
          </div>

          <ng-container *ngFor="let param of messageType.configuration.params">
            <ng-container *ngIf="param.type === 'POSITION' && messageType.fields">
              <ng-container *ngIf="isReceiver">
                <div class="col-md-12 margin-bottom-20 margin-top-20">
                  <app-receiver-positions-edit
                    name="Field Positions"
                    [_map]="messageType.configuration.fileParams[param.key]"
                    [_allowedFields]="messageType.fields"
                  ></app-receiver-positions-edit>
                </div>
              </ng-container>
              <ng-container *ngIf="!isReceiver">
                <div class="col-md-12 margin-bottom-20 margin-top-20">
                  <app-sender-positions-edit
                    name="Field Positions"
                    [_map]="messageType.configuration.fileParams[param.key]"
                    [_allowedFields]="messageType.fields"
                  ></app-sender-positions-edit>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="param.type !== 'POSITION'">
              <div class="col-md-3">
                <ng-container *ngIf="param.type === 'BOOLEAN'">
                  <mat-checkbox [(ngModel)]="messageType.configuration.fileParams[param.key]">
                    {{ param.label }}
                  </mat-checkbox>
                </ng-container>

                <ng-container *ngIf="param.type === 'STRING' || param.type === 'INT'">
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="messageType.configuration.fileParams[param.key]"
                      [placeholder]="param.label"
                      [type]="param.type === 'STRING' ? 'text' : 'number'"
                    />
                  </mat-form-field>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="col-md-12 mt-3 text-center">
        <mat-slide-toggle
          [(ngModel)]="messageType.configuration.active"
          matTooltip="Click to {{ messageType.configuration.active ? 'disable' : 'activate' }} "
          >{{ messageType.configuration.active ? 'Active' : 'Disabled' }}</mat-slide-toggle
        >
      </div>
    </ng-container>
  </div>
</div>
