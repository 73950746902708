import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EmailTemplateWithSample')
export class EmailTemplateWithSample {
  @JsonProperty('templateName', String, true) templateName: string = undefined;
  @JsonProperty('sampleSubject', String, true) sampleSubject: string = undefined;
  @JsonProperty('sampleBody', String, true) sampleBody: string = undefined;
}

@JsonObject('EmailTemplates')
export class EmailTemplates {
  @JsonProperty('sendingTemplates', [EmailTemplateWithSample], true) sendingTemplates: EmailTemplateWithSample[] = undefined;
  @JsonProperty('confirmTemplates', [EmailTemplateWithSample], true) confirmTemplates: EmailTemplateWithSample[] = undefined;
  @JsonProperty('cancelOrderTemplates', [EmailTemplateWithSample], true) cancelOrderTemplates: EmailTemplateWithSample[] = undefined;
  @JsonProperty('troubleOrderTemplates', [EmailTemplateWithSample], true) troubleOrderTemplates: EmailTemplateWithSample[] = undefined;
}
