<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-listbox>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-listbox>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="container-fluid">
      <div class="row pt-3">
        <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
          <div class="filter-box col-md-12 text-light-secondary margin-top-5">
            <div class="row justify-content-center row-cols-4">
              <ng-container *ngIf="spoFilters">
                <div class="col">
                  <mat-form-field>
                    <input matInput formControlName="orderId" placeholder="Order ID" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field>
                    <mat-select filterKey="name" formControlName="client" id="client" placeholder="Client">
                      <mat-option [value]="null">None</mat-option>
                      <mat-option [value]="client" *ngFor="let client of clients">
                        {{ client }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <app-brandon-autocomplete
                    label="Owner"
                    [allData]="owners"
                    [externalFormControl]="filterForm.controls.owner"
                    displayKey="name"
                    filterKey="name"
                    valueKey="name"
                  ></app-brandon-autocomplete>
                </div>
                <div class="col">
                  <app-brandon-autocomplete
                    label="Supplier"
                    [allData]="suppliers"
                    [(externalFormControl)]="filterForm.controls.supplier"
                    displayKey="description"
                    filterKey="description"
                    valueKey="id"
                  ></app-brandon-autocomplete>
                </div>
                <div class="col">
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="earliestDeliveryDate"
                      [matDatepicker]="earliestDeliveryDate"
                      placeholder="Earliest Delivery Date"
                    />
                    <mat-datepicker-toggle matSuffix [for]="earliestDeliveryDate"></mat-datepicker-toggle>
                    <mat-datepicker #earliestDeliveryDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="latestDeliveryDate"
                      [matDatepicker]="latestDeliveryDate"
                      placeholder="Latest Delivery Date"
                    />
                    <mat-datepicker-toggle matSuffix [for]="latestDeliveryDate"></mat-datepicker-toggle>
                    <mat-datepicker #latestDeliveryDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field>
                    <mat-select filterKey="name" formControlName="status" id="status" placeholder="Status">
                      <mat-option [value]="null">None</mat-option>
                      <mat-option [value]="status" *ngFor="let status of spoStatuses">
                        {{ status }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col padding-top-15">
                  <mat-checkbox formControlName="missingConfirmed">Missing confirmed</mat-checkbox>
                </div>
                <div class="col">
                  <mat-form-field>
                    <input matInput formControlName="ean" placeholder="EAN" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field>
                    <input matInput formControlName="altEan" placeholder="Actual EAN" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field class="date-range-picker">
                    <mat-date-range-input [formGroup]="filterForm" [rangePicker]="resultPicker">
                      <input matStartDate formControlName="orderDateFrom" placeholder="Start date" />
                      <input matEndDate formControlName="orderDateTo" placeholder="End date" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="resultPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #resultPicker> </mat-date-range-picker>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-container *ngIf="spoItemFilters">
                <div class="col">
                  <mat-form-field>
                    <input matInput formControlName="clientApplicationId" placeholder="Client Application ID" />
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-container *ngIf="spoItemFilters">
                <div class="col padding-top-15">
                  <mat-checkbox formControlName="hasOrderedQuantity">Ordered > 0</mat-checkbox>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-md-12 border-top-white text-center">
            <button type="submit" (click)="onSubmitFilter()">Filter</button>
            <button type="button" (click)="resetFilters()">Reset</button>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>
</div>
