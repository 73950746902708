import * as moment from 'moment';

export const isUndefined = (item: any): boolean => item === undefined;

const isNull = (item: any): boolean => item === null;

export const isNil = (item: any): boolean => isNull(item) || isUndefined(item);

/**
 * Permette di verificare se il parametro in input sia vuoto (stringa, array o oggetto)
 *
 * @param item
 * @returns true: se vuoto, false: altrimenti
 */
export const isEmpty = (item: any): boolean => {
  if (typeof item === 'string') {
    return item === '';
  } else if (typeof item === 'object') {
    if (Array.isArray(item)) {
      return item.length === 0;
    } else {
      return Object.keys(item).length === 0;
    }
  }
};

/**
 * Permette di verificare se il parametro in input sia nullo, undefined o vuoto (stringa, array o oggetto)
 *
 * @param item
 * @returns true: se nullo, undefined o vuoto, false: altrimenti
 */
export const isNilty = (item: any): boolean => isNull(item) || isUndefined(item) || isEmpty(item);

/**
 * Permette di verificare l'uguaglianza tra due oggetti
 *
 * @param obj: oggetto sorgente
 * @param obj1: oggetto da confrontare
 * @param {string[]} keyToCheck
 * @param {boolean} exclude
 * @returns {boolean} true: se sono uguali, false: altrimenti
 */

export const isEqual = (obj: any, obj1: any, keyToCheck: string[] = [], exclude = true): boolean => {
  let isequal = true;
  if (typeof obj === typeof obj1) {
    if (typeof obj === 'object' && !isNil(obj) && !moment.isMoment(obj)) {
      if (!Array.isArray(obj)) {
        for (const key of Object.keys(obj)) {
          if (obj1.hasOwnProperty(key)) {
            if (
              ((exclude === true && keyToCheck.indexOf(key) === -1) || (exclude === false && keyToCheck.indexOf(key) > -1)) &&
              isequal === true
            ) {
              isequal = isEqual(obj[key], obj1[key], keyToCheck, exclude);
            }
          } else {
            isequal = false;
          }
        }
      } else {
        if (obj.length === obj1.length) {
          obj.forEach((elem, index) => {
            if (isequal === true) {
              isequal = isEqual(elem, obj1[index], keyToCheck, exclude);
            }
          });
        } else {
          isequal = false;
        }
      }
    } else {
      if (!moment.isMoment(obj)) {
        isequal = obj === obj1;
      } else {
        isequal = moment(obj).isSame(obj1);
      }
    }
  } else {
    isequal = false;
  }
  return isequal;
};
