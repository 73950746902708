<div class="row mat-dialog-title">
  <h4 class="col-md-10 offset-md-1 text-center summary mb-0">
    New {{ (operation.type | isNullOrUndefinedOrEmptyString) ? 'Operation' : operation.type }}
  </h4>
  <div class="col-md-1 text-end">
    <i class="fa fa-times exit-icon" (click)="close()" style="cursor: pointer"></i>
  </div>
</div>

<div mat-dialog-content class="border">
  <mat-vertical-stepper [linear]="true">
    <ng-template matStepperIcon="edit" let-index="index">
      <i class="fa fa-check"></i>
    </ng-template>

    <ng-template matStepperIcon="done" let-index="index">
      <i class="fa fa-check"></i>
    </ng-template>

    <mat-step [completed]="completedSteps[0]" label="Select Operation Type">
      <div class="row padding-top-15">
        <div class="col-md-6 offset-md-2 text-center">
          <mat-form-field class="full-width">
            <mat-select [(ngModel)]="operation.type" placeholder="Type" (ngModelChange)="updatedType()">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let t of operationTypes" [value]="t">{{ t }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-end padding-top-20">
          <button class="btn-custom" matStepperNext [disabled]="operation.type | isNullOrUndefinedOrEmptyString">
            Next
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [completed]="completedSteps[1]" label="Select Partner and Business Line">
      <div class="row padding-top-15">
        <div class="col-md-3 text-center padding-bottom-20">
          <mat-form-field>
            <mat-select [(ngModel)]="logisticPartner" placeholder="Logistic Partner" (ngModelChange)="updatedData()">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let lp of logisticPartners" [value]="lp">{{ lp }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center padding-bottom-20">
          <mat-form-field>
            <mat-select [(ngModel)]="orderType" placeholder="Order Type" (ngModelChange)="updatedData()">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let bl of orderTypes" [value]="bl">{{ bl }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center padding-bottom-20" *ngIf="operation.type === 'INBOUND'">
          <mat-form-field>
            <input
              matInput
              [(ngModel)]="consignmentExpirationDate"
              [matDatepicker]="picker"
              placeholder="Expiration Date"
              (ngModelChange)="updatedData()"
              [disabled]="isConsignment"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center padding-bottom-20" *ngIf="operation.type === 'INBOUND'">
          <mat-checkbox [(ngModel)]="isConsignment" (ngModelChange)="onConsignmentChange($event)">is Consignment</mat-checkbox>
        </div>
      </div>

      <div class="row padding-top-20">
        <div class="col-md-6 text-start">
          <button class="btn-custom" matStepperPrevious>
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            Back
          </button>
        </div>

        <div class="col-md-6 text-end">
          <button
            class="btn-custom"
            matStepperNext
            [disabled]="
              (logisticPartner | isNullOrUndefinedOrEmptyString) ||
              (orderType | isNullOrUndefinedOrEmptyString) ||
              (client | isNullOrUndefinedOrEmptyString)
            "
          >
            Next
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [completed]="completedSteps[2]" label="Select File">
      <div class="row padding-top-15">
        <div class="col-md-8">
          <mat-form-field class="full-width">
            <input matInput [value]="currentFileUpload ? currentFileUpload.name : ''" disabled placeholder="File" />
          </mat-form-field>
        </div>

        <div class="col-md-4 padding-top-6">
          <button class="box-shadow-none btn-custom search-button" (click)="loadFile()">Search</button>
          <input #operationFile type="file" style="display: none" (change)="fileChanged($event)" />
        </div>
      </div>

      <div class="row padding-top-20">
        <div class="col-md-6 text-start">
          <button class="btn-custom" matStepperPrevious>
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            Back
          </button>
        </div>

        <div class="col-md-6 text-end">
          <button class="btn-custom" (click)="confirmFile()" [disabled]="currentFileUpload | isNullOrUndefined">
            Next
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [completed]="completedSteps[3]" label="Summary">
      <div class="row">
        <ng-container *ngIf="lastStep">
          <div class="col-md-12 summary">
            <div class="row">
              <p><b>Operation Type: </b> {{ operation.type }}</p>
            </div>

            <div class="row">
              <p><b>Logistic Partner: </b> {{ operation.logisticPartner }}</p>
            </div>

            <div class="row">
              <p><b>Order Type: </b> {{ operation.orderType }}</p>
            </div>

            <div class="row" *ngIf="operation.type === 'INBOUND'">
              <p>
                <b>Expiration Date: </b>
                {{ operation.consignmentExpirationDate ? operation.consignmentExpirationDate.toDateString() : null }}
              </p>
            </div>

            <div class="row">
              <p><b>Client: </b> {{ operation.client }}</p>
            </div>

            <div class="row">
              <p><b>File: </b> {{ currentFileUpload.name }}</p>
            </div>

            <div class="row padding-top-20">
              <div class="col-md-6 text-start">
                <button class="btn-custom" matStepperPrevious>
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  Back
                </button>
              </div>

              <div class="col-md-6 text-end">
                <button class="btn-custom" (click)="confirmOperation()">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div mat-dialog-actions></div>
