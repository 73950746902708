<mat-toolbar>
  <div class="row col-md-12">
    <div class="col-md-8 col-md-offset-2">
      <button class="btn-custom" (click)="new()">New warehouse</button>
    </div>
  </div>
</mat-toolbar>

<div class="container-fluid margin-bottom-60">
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let warehouse">{{ warehouse.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let warehouse" [matTooltip]="warehouse.name" matTooltipPosition="below">{{ warehouse.name }}</td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Code</th>
      <td mat-cell *matCellDef="let warehouse">{{ warehouse.code }}</td>
    </ng-container>

    <ng-container matColumnDef="logisticPartner">
      <th mat-header-cell *matHeaderCellDef>Logistic Partner</th>
      <td mat-cell *matCellDef="let warehouse">{{ warehouse.logisticPartner }}</td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef>Owner</th>
      <td mat-cell *matCellDef="let warehouse">{{ warehouse.owner }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let warehouse">{{ warehouse.createdAt }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell class="button-column" *matHeaderCellDef></th>
      <td mat-cell class="button-column" *matCellDef="let warehouse">
        <button class="btn-product btn-fixed" (click)="edit(warehouse)">
          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          Edit
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell class="button-column" *matHeaderCellDef></th>
      <td mat-cell class="button-column" *matCellDef="let warehouse">
        <button class="btn-product btn-fixed" (click)="delete(warehouse)">
          <i class="fa fa-trash" aria-hidden="true"></i>
          Delete
        </button>
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="sticky-top"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>
