import { Component, OnInit, Output } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { ProductAgingFilters, productAgingFiltersMap } from '@models/product-aging-model';
import { CommonsService } from '@shared/commons.service';
import { Subject } from 'rxjs';
import { StockBalanceService } from '../../stock-balance.service';
import { StockLocation } from '@models/stock-location-model';

@Component({
  selector: 'app-product-aging-filters',
  templateUrl: './product-aging-filters.component.html',
  styleUrls: ['./product-aging-filters.component.css'],
})
export class ProductAgingFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<void>();
  @Output() hasFilters = new Subject<boolean>();

  public openFilter = false;

  filters: ProductAgingFilters;

  logisticPartners: string[];
  stockLocations: StockLocation[];
  filtersMap = [];

  constructor(private commonService: CommonsService, private service: StockBalanceService) {}

  ngOnInit() {
    this.commonService.getBridgetEnums().subscribe((r) => (this.logisticPartners = r.logisticPartners));

    this.commonService.getStockLocations().subscribe((resp: StockLocation[]) => {
      this.stockLocations = resp;
    });

    if (isNilty(this.service.productAgingFilters)) {
      this.service.productAgingFilters = new ProductAgingFilters();
    }

    this.filters = this.service.productAgingFilters;
  }

  onSubmitFilter() {
    this.updateFiltersMap();

    if (this.filtersMap.length > 0) this.hasFilters.next(true);
    else this.hasFilters.next(false);

    this.service.productAgingFilters.pageIndex = 0;
    this.onFilter.next();
  }

  resetFilters() {
    this.filters = new ProductAgingFilters();
    this.service.productAgingFilters = this.filters;
    this.hasFilters.next(false);
    this.onFilter.next();
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = productAgingFiltersMap(
      this.filters.sku,
      this.filters.logisticPartner,
      this.filters.dateFrom,
      this.filters.dateTo,
      this.filters.stockLocationCode,
      this.filters.operationId
    );
  }
}
