import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { Shipment, ShipmentFilters } from '@models/shipment-model';
import { Observable } from 'rxjs';
import { ShipmentsService } from '../shipments.service';

@Component({
  selector: 'app-shipments-list',
  templateUrl: './shipments-list.component.html',
  styleUrls: ['./shipments-list.component.css'],
})
export class ShipmentsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<Shipment>;
  displayedColumns = ['shipmentId', 'createdAt', 'client', 'status', 'carrier', 'trackingNumber', 'packageCount', 'label'];
  initialFilters: ShipmentFilters;

  resultsNumber: Observable<number>;

  constructor(private shipmentsService: ShipmentsService, private router: Router) {}

  ngOnInit() {
    this.resultsNumber = this.shipmentsService.shipmentsResultsNumber;

    if (isNilty(this.shipmentsService.shipmentsFilters)) {
      this.shipmentsService.shipmentsFilters = new ShipmentFilters();
    }

    this.initialFilters = this.shipmentsService.shipmentsFilters;

    this.paginator.pageSize = this.shipmentsService.shipmentsFilters.pageSize;
    this.paginator.pageIndex = this.shipmentsService.shipmentsFilters.pageIndex;

    this.getSpos();
  }

  getSpos() {
    this.shipmentsService.getShipmentsByFilters().subscribe((resp: Shipment[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  loadPage() {
    this.shipmentsService.shipmentsFilters.pageSize = this.paginator.pageSize;
    this.shipmentsService.shipmentsFilters.pageIndex = this.paginator.pageIndex;
    this.getSpos();
  }

  onSort() {
    this.shipmentsService.shipmentsFilters.sortBy = this.sort.active;
    this.shipmentsService.shipmentsFilters.sortDirection = this.sort.direction;
    this.getSpos();
  }

  applyFilters(filters: ShipmentFilters) {
    this.shipmentsService.shipmentsFilters = filters;
    this.paginator.pageIndex = 0;
    this.getSpos();
  }

  navigateToFile(event: MouseEvent, fileLink: string) {
    event.stopPropagation();
    window.open(fileLink);
  }
}
