import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared.module';
import { CarriersConfigurationsEditComponent } from './carriers-configurations/carriers-configurations-edit/carriers-configurations-edit.component';
import { CarriersConfigurationsListComponent } from './carriers-configurations/carriers-configurations-list/carriers-configurations-list.component';
import { CarriersConfigurationsComponent } from './carriers-configurations/carriers-configurations.component';
import { CarriersConfigurationsService } from './carriers-configurations/carriers-configurations.service';
import { ConfigurationMenuComponent } from './configuration-menu/configuration-menu.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationComponent } from './configuration.component';
import { NameCodeEditModalComponent } from './name-code-edit-modal/name-code-edit-modal.component';
import { AriannaParamsFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/arianna-params-form/arianna-params-form.component';
import { EmailCancelOrderParamsFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/email-cancel-order-params-form/email-cancel-order-params-form.component';
import { EmailParamsFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/email-params-form/email-params-form.component';
import { EmailTemplateModalComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/email-params-form/email-template-modal.component';
import { EmailTroubleOrderParamsFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/email-trouble-order-params-form/email-trouble-order-params-form.component';
import { FtpParamsFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/ftp-params-form/ftp-params-form.component';
import { MessageTypesFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/message-types-form/message-types-form.component';
import { ReceiverPositionsEditComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/message-types-form/receiver-positions-edit/receiver-positions-edit.component';
import { SenderPositionsEditComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/message-types-form/sender-positions-edit/sender-positions-edit.component';
import { PubeasyParamsFormComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/pubeasy-params-form/pubeasy-params-form.component';
import { RouteConfigurationEditComponent } from './suppliers-configurations/suppliers-configurations-edit/route-configuration-edit/route-configuration-edit.component';
import { SuppliersConfigurationsEditComponent } from './suppliers-configurations/suppliers-configurations-edit/suppliers-configurations-edit.component';
import { SuppliersConfigurationsListComponent } from './suppliers-configurations/suppliers-configurations-list/suppliers-configurations-list.component';
import { SuppliersConfigurationsComponent } from './suppliers-configurations/suppliers-configurations.component';
import { SuppliersConfigurationsService } from './suppliers-configurations/suppliers-configurations.service';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { WarehouseEditModalComponent } from './warehouses/warehouse-edit-modal/warehouse-edit-modal.component';
import { WarehousesComponent } from './warehouses/warehouses.component';

@NgModule({
  declarations: [
    ConfigurationComponent,
    ConfigurationMenuComponent,
    NameCodeEditModalComponent,
    SuppliersComponent,
    WarehousesComponent,
    WarehouseEditModalComponent,
    SuppliersConfigurationsComponent,
    SuppliersConfigurationsEditComponent,
    SuppliersConfigurationsListComponent,
    EmailParamsFormComponent,
    EmailCancelOrderParamsFormComponent,
    EmailTroubleOrderParamsFormComponent,
    FtpParamsFormComponent,
    MessageTypesFormComponent,
    SenderPositionsEditComponent,
    ReceiverPositionsEditComponent,
    RouteConfigurationEditComponent,
    PubeasyParamsFormComponent,
    AriannaParamsFormComponent,
    EmailTemplateModalComponent,
    CarriersConfigurationsComponent,
    CarriersConfigurationsEditComponent,
    CarriersConfigurationsListComponent,
  ],
  imports: [SharedModule, ConfigurationRoutingModule],
  providers: [SuppliersConfigurationsService, CarriersConfigurationsService],
})
export class ConfigurationModule {}
