import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { Operation } from './operation-model';

@JsonObject('OperationRow')
export class OperationRow extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('messages', String, true) messages: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('data', Any, true) data: any = undefined;
}

@JsonObject('OperationDetailsOutput')
export class OperationDetailsOutput {
  @JsonProperty('operation', Operation, true) operation: Operation = undefined;
  @JsonProperty('dataRows', [OperationRow], true) dataRows: OperationRow[] = undefined;
}

@JsonObject('OperationRowFilters')
export class OperationRowFilters extends PaginationAndSorting {}
