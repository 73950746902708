import { JsonObject, JsonProperty } from 'json2typescript';
import { Field } from './field-model';
import { FileType } from './file-types-model';
import { MessageConfiguration } from './message-configuration-model';

@JsonObject('MessageType')
export class MessageType {
  @JsonProperty('typeCode', String, true) typeCode: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('fileTypes', [FileType], true) fileTypes: FileType[] = undefined;

  configured: boolean;
  configuration: MessageConfiguration;
  fields: Field[];
}

@JsonObject('MessageTypesOutput')
export class MessageTypesOutput {
  @JsonProperty('senderMessages', [MessageType], true) senderMessages: MessageType[] = undefined;
  @JsonProperty('receiverMessages', [MessageType], true) receiverMessages: MessageType[] = undefined;
}
