import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('Item')
export class Item extends BaseObject {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('sku', String, false) sku: string = undefined;
  @JsonProperty('skuAlt', String, false) skuAlt: string = undefined;
  @JsonProperty('productCode', String, false) productCode: string = undefined;
  @JsonProperty('articleDesc', String, false) articleDesc: string = undefined;
  @JsonProperty('barcode', String, false) barcode: string = undefined;
  @JsonProperty('unit', String, false) unit: string = undefined;
  @JsonProperty('size', String, false) size: string = undefined;
  @JsonProperty('colorCode', String, false) colorCode: string = undefined;
  @JsonProperty('colorDesc', String, false) colorDesc: string = undefined;
}

@JsonObject('ItemFilters')
export class ItemFilters extends PaginationAndSorting {
  @JsonProperty('sku', String, true) sku: string = undefined;
}
