<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-listbox>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-listbox>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="container-fluid">
      <div class="row pt-3">
        <div class="filter-box col-md-12 text-light-secondary margin-top-5">
          <div class="row justify-content-center row-cols-4">
            <div class="col">
              <mat-form-field>
                <textarea
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  class="width-100"
                  matInput
                  [(ngModel)]="filters.sku"
                  placeholder="SKU"
                ></textarea>
              </mat-form-field>
            </div>

            <div class="col">
              <app-brandon-autocomplete
                label="Stock Locations"
                [allData]="stockLocations"
                [(value)]="filters.stockLocationCode"
                filterKey="code"
                displayKey="code"
                valueKey="code"
                [isMulti]="false"
              ></app-brandon-autocomplete>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput [(ngModel)]="filters.operationId" placeholder="Operation Id" />
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput [(ngModel)]="filters.dateFrom" [matDatepicker]="dateFrom" placeholder="From" />
                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateFrom></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput [(ngModel)]="filters.dateTo" [matDatepicker]="dateTo" placeholder="To" />
                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                <mat-datepicker #dateTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white text-center">
          <button type="submit" (click)="onSubmitFilter()">Filter</button>
          <button type="button" (click)="resetFilters()">Reset</button>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
