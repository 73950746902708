import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { SposService } from '../spos.service';
import { Spo, SpoFilters } from '@models/spo-model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImportSpoaModalComponent } from '../../../modal/import-spoa-modal/import-spoa-modal.component';

@Component({
  selector: 'app-spos-list',
  templateUrl: './spos-list.component.html',
  styleUrls: ['./spos-list.component.css'],
})
export class SposListComponent implements OnInit {
  @ViewChild('getFile', { static: false }) getFile: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<Spo>;
  displayedColumns = [
    'orderId',
    'client',
    'owner',
    'supplier',
    'status',
    'sendingRouteType',
    'clientOrderId',
    'supplierOrderId',
    'orderDate',
    'earliestDeliveryDate',
    'latestDeliveryDate',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'requestedValue',
    'confirmedValue',
    'orderedValue',
  ];
  initialFilters: SpoFilters;

  resultsNumber: Observable<number>;

  constructor(private sposService: SposService, private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    this.resultsNumber = this.sposService.spoResultsNumber;

    if (isNilty(this.sposService.sposFilters)) {
      this.sposService.sposFilters = new SpoFilters();
    }

    this.initialFilters = this.sposService.sposFilters;

    this.paginator.pageSize = this.sposService.sposFilters.pageSize;
    this.paginator.pageIndex = this.sposService.sposFilters.pageIndex;

    this.getSpos();
  }

  getSpos() {
    this.sposService.getSposByFilters().subscribe((resp: Spo[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  loadPage() {
    this.sposService.sposFilters.pageSize = this.paginator.pageSize;
    this.sposService.sposFilters.pageIndex = this.paginator.pageIndex;
    this.getSpos();
  }

  onSort() {
    this.sposService.sposFilters.sortBy = this.sort.active;
    this.sposService.sposFilters.sortDirection = this.sort.direction;
    this.getSpos();
  }

  applyFilters(filters: SpoFilters) {
    this.sposService.sposFilters = filters;
    this.paginator.pageIndex = 0;
    this.getSpos();
  }

  openSpo(spo: Spo) {
    this.router.navigate(['spo-detail'], { queryParams: { orderId: spo.orderId } });
  }

  openSpoItems() {
    this.router.navigate(['spo-items']);
  }

  importSpoa() {
    this.dialog.open(ImportSpoaModalComponent, { disableClose: true });
  }
}
